<template>
    <div class="page-layout blank contacts-manage">
        <div class="pb-15 pr-2">
            <div class="d-flex justify-content-between align-items-center">
                <div class="breadcrumbnew">
                    <span>Contacts</span>
                    <a>View Contact</a>
                </div>
                <div class="d-flex">
                    <button v-if="!not_shared" class="btn btn-new-primary btn-wide px-6 ml-4" @click="addToMyContacts">Add to My Contacts</button>
                    <button class="btn btn-new-primary btn-wide px-6 ml-4" v-if="not_shared" @click="$modal.show('shareContact')">Share</button>
                    <button class="btn btn-new-green btn-wide px-6 ml-4" @click="editContact">Edit</button>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center" v-if="callInProgress">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" style="margin: 0 auto;" />
            </div>
            <div v-else-if="!callInProgress && contactData.company && contactData.first_name">
                <div class="d-flex">
                    <div class="pointer" :class="toShow == 'profile' ? 'blue_header' : 'grey_header'" @click="toShow = 'profile'">
                        <span>Profile</span>
                    </div>
                    <div class="pointer" :class="toShow == 'profile' ? 'blue_header_triangle' : 'grey_header_triangle'" @click="toShow = 'profile'"></div>
                    <div class="pointer" :class="toShow == 'address' ? 'blue_header' : 'grey_header'" @click="toShow = 'address'">
                        <span>Address</span>
                    </div>
                    <div class="pointer" :class="toShow == 'address' ? 'blue_header_triangle' : 'grey_header_triangle'" @click="toShow = 'address'"></div>
                    <div class="pointer" :class="toShow == 'social media' ? 'blue_header' : 'grey_header'" @click="toShow = 'social media'">
                        <span>Social Media</span>
                    </div>
                    <div class="pointer" :class="toShow == 'social media' ? 'blue_header_triangle' : 'grey_header_triangle'" @click="toShow = 'social media'"></div>
                    <div class="pointer" :class="toShow == 'referral' ? 'blue_header' : 'grey_header'" @click="toShow = 'referral'">
                        <span>Referral</span>
                    </div>
                    <div class="pointer" :class="toShow == 'referral' ? 'blue_header_triangle' : 'grey_header_triangle'" @click="toShow = 'referral'"></div>
                    <div v-if="not_shared" class="pointer" :class="toShow == 'visibility' ? 'blue_header' : 'grey_header'" @click="toShow = 'visibility'">
                        <span>Visibility</span>
                    </div>
                    <div v-if="not_shared" class="pointer" :class="toShow == 'visibility' ? 'blue_header_triangle' : 'grey_header_triangle'" @click="toShow = 'visibility'"></div>
                    
                    <div class="pointer" :class="toShow == 'classification' ? 'blue_header' : 'grey_header'" @click="toShow = 'classification'">
                        <span>Classification</span>
                    </div>
                    <div class="pointer" :class="toShow == 'classification' ? 'blue_header_triangle' : 'grey_header_triangle'" @click="toShow = 'classification'"></div>
                </div>
                <div v-if="toShow == 'profile'" class="main-div">
                    <!-- <div class="row">
                        <div class="col-12 pr-0"> -->
                            <div class="header-outer">
                                <span class="fw-500">Basic Information</span>
                                <span class="pointer" @click="showBasicInformation">
                                    <i v-if="basicInformationTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                                    <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                                </span>
                            </div>
                            <div class="inner-div" v-if="basicInformationTab">
                                <p class="text-secondary fs-14 fw-600 mt-4">Profile</p>
                                <img :src="contactData.pic_type == 'profile_pic' ? contactData.profile_pic : contactData.avatar_pic" style="width: 10em; height: 10em; border-radius: 5px">
                                <div class="d-flex mt-4">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Title</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.title || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">First Name</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%" >{{ contactData.first_name.first_name || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Middle Name</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.middle_name || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Last Name</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.last_name || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Alias ( Avatar )</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.alias || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Company Name</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.company.company_name || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Job Title</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.job_title || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Campaign Type</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.campaign_details.campaign_type || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Campaign Name</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.campaign_details.campaign_name || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Relation</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.relation || "-" }}</span>
                                </div>
                                <div class="d-flex mt-8">
                                    <span class="text-secondary fs-18 fw-600" style="width: 30%">Tags</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ contactData.tags || "-" }}</span>
                                </div>
                            </div>
                            <div class="header-outer">
                                <span class="fw-500">Contact</span>
                                <span class="pointer" @click="showContact">
                                    <i v-if="contactTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                                    <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                                </span>
                            </div>
                            <div class="inner-div" v-if="contactTab">
                                <div class="d-flex mt-4" v-for="(email,index) in contactData.email" :key="'email'+index">
                                    <span class="text-secondary fs-18 fw-600 text-capitalize" style="width: 30%">{{ email.email_type.replace("_", " ") }}</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ email.email_id || "-" }}</span>
                                </div>
                                <div class="d-flex mt-4" v-for="(phone,index) in contactData.phone" :key="'phone'+index">
                                    <span class="text-secondary fs-18 fw-600 text-capitalize" style="width: 30%">{{ phone.phone_type.replace("_", " ") }}</span>
                                    <span class="fs-18 fw-500 pb-2" style="border-bottom: 1px solid #707070; width: 70%">{{ phone.phone_number || "-" }}</span>
                                </div>
                            </div>
                            <div class="header-outer">
                                <span class="fw-500">Notes</span>
                                <span class="pointer" @click="showNotes">
                                    <i v-if="notesTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                                    <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                                </span>
                            </div>
                            <div class="inner-div" v-if="notesTab">
                                <textarea-autosize class="digi-textarea white-background bordered mt-5" v-model="contactData.notes" style="height: 15em; overflow: auto;" disabled />
                            </div>
                        <!-- </div>
                    </div> -->
                </div>
                <div v-if="toShow == 'address'" class="main-div">
                    <div class="header-outer">
                        <span class="fw-500">Contact Information</span>
                        <span class="pointer" @click="showAddressInformation">
                            <i v-if="addressInformationTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                        </span>
                    </div>
                    <div class="inner-div pt-6" v-if="addressInformationTab">
                        <div class="d-flex flex-wrap">
                            <div v-if="contactData.address.length == 0" class="text-center w-100">
                                <span class="fs-14" style="color: #414141">No Data</span>
                            </div>
                            <div v-for="(address,index) in contactData.address" :key="index">
                                <div class="address" v-if="address.country !=''">
                                    <p  class="d-flex justify-content-between" style="color: #0475f3"><span class="text-capitalize">{{ address.address_type.split("_")[0] }} {{ address.address_type.split("_")[1] }}</span> 
                                    <span v-if="address.address_description" class="primary-tag" style="background-color: #11b903">{{ address.address_description }}</span></p>
                                    <p >{{ address.street }}, {{ address.street2 }}, {{ address.township }}, <br> {{ address.city }}, {{ address.state }} <br> {{ address.country }}, {{ address.zip_code }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="toShow == 'social media'" class="main-div">
                    <div class="header-outer">
                        <span class="fw-500">Social Media Links</span>
                        <span class="pointer" @click="showSocialMedia">
                            <i v-if="socialMediaTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                        </span>
                    </div>
                    <div class="inner-div pt-10" v-if="socialMediaTab">
                        <div class="d-flex flex-wrap form-check form-check-inline">
                            <div v-if="contactData.social_media.length == 0" class="text-center w-100">
                                <span class="fs-14" style="color: #414141">No Data</span>
                            </div>
                            <div class="social-media" v-for="(social, index) in contactData.social_media" :key="index">
                                <div style="color: #00448b; font-size: 25px; position: absolute; margin-left: 5.4em; cursor: pointer" @click="viewSocial(social.logo,index)">
                                    <span v-if="social.account_id == '' || social.account_url == ''">
                                    +
                                    </span>
                                    <span v-else>
                                        <i class="icon icon-check fs-18 text-success"></i>
                                    </span>
                                </div>
                                <div class="d-flex justify-content-center mt-10">
                                    <img :src="social.logo" style="width: 3em; height: 3em;">
                                </div>
                                <div class="mt-2 fs-14 fw-500" style="text-align: center; text-transform: capitalize">{{ social.account_type }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="toShow == 'referral'" class="main-div">
                    <div class="header-outer">
                        <span class="fw-500">Referral Information</span>
                        <span class="pointer" @click="showReferral">
                            <i v-if="referralTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                        </span>
                    </div>
                    <div class="inner-div pt-10" v-if="referralTab">
                        <div class="row">
                            <div class="col-lg-4">
                                <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Lead Referral Type</p>
                                <div class="inputContainer">{{ contactData.referral_details.contact_referral_type || "-" }}</div>
                            </div>
                            <div class="col-lg-4">
                                <div class="d-flex">
                                    <div class="mr-4" style="width: 70%">
                                        <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Referral Date and Time</p>
                                        <div class="inputContainer" v-if="contactData.referral_details.referral_time">{{ moment(contactData.referral_details.referral_time).format("DD/MM/YYYY")}}</div>
                                        <div class="inputContainer" v-else>-</div>
                                    </div>
                                    <div class="ml-4" style="width: 30%">
                                        <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Time</p>
                                        <div class="inputContainer" v-if="contactData.referral_details.referral_time">{{ moment(contactData.referral_details.referral_time).format("HH:MM")}}</div>
                                        <div class="inputContainer" v-else>-</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Referral Location</p>
                                <div>
                                    <div class="inputContainer">{{ contactData.referral_details.referral_location || "-" }}</div>
                                    <i class="crmnew-marker text-secondary fs-14" style="position: absolute; top: 60px;right:7px"></i>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Referred by</p>
                                <div class="inputContainer">{{ contactData.referral_details.referred_by.full_name || "-" }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="toShow == 'visibility'" class="main-div">
                    <div class="header-outer">
                        <span class="fw-500">Shared to</span>
                        <span class="pointer" @click="showVisibility">
                            <i v-if="visibilityTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                        </span>
                    </div>
                    <div class="inner-div pt-10" v-if="visibilityTab">
                        <div v-if="contactData.visibility" class="d-flex flex-wrap">
                            <div class="visibility d-flex align-items-center mr-6 mb-4" v-for="(visibility,index) in contactData.visibility" :key="index">
                                <img class="ml-4" :src="visibility.profile_pic ? visibility.profile_pic : visibility.avatar_pic" style="width: 3.5em; height: 3.5em; border-radius: 50%">
                                <div class="d-flex flex-column ml-2">
                                    <span class="fs-14" style="color: #414141;white-space: pre-line !important;">{{ visibility.full_name }}</span>
                                    <span class="fs-12" style="color: #949494">{{ visibility.email || "-" }}</span>
                                </div>
                                <el-dropdown trigger="click" style="width: auto !important; position: absolute; margin-left: 18.5em; margin-bottom: 3em;">
                                    <i class="icon icon-dots-vertical" style="color: #949494;"></i>
                                    <el-dropdown-menu slot="dropdown">
                                        <!-- <el-dropdown-item style="color: #4a4a4a !important; padding: 0px 10px; font-size: 12px; font-weight: 500;">More</el-dropdown-item> -->
                                        <el-dropdown-item style="color: #4a4a4a !important; padding: 0px 10px; font-size: 12px; font-weight: 500;" @click.native="editSharing(visibility._id)">Edit Sharing</el-dropdown-item>
                                        <el-dropdown-item style="color: #4a4a4a !important; padding: 0px 10px; font-size: 12px; font-weight: 500;" @click.native="removeSharingSelected(visibility)">Remove Sharing</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <span class="fs-14" style="color: #414141">No Data</span>
                        </div>
                    </div>
                </div>
                <div v-if="toShow == 'classification'" class="main-div">
                    <div class="header-outer">
                        <span class="fw-500">Contact Classification</span>
                        <span class="pointer" @click="showClassifications">
                            <i v-if="classificationsTab" class="icon-chevron-double-up text-white" style="font-size: 20px"></i>
                            <i v-else class="icon-chevron-double-down text-white" style="font-size: 20px"></i>
                        </span>
                    </div>
                    <div class="inner-div pt-10" v-if="classificationsTab">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Lead Status</p>
                                <div class="inputContainer">{{ contactData.classification.status || "-" }}</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12">
                                <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Classification</p>
                                <div class="inputContainer">{{ contactData.classification.classification || "-" }}</div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-sm-12" v-if="contactData.classification.sales_person">
                                <p class="text-secondary fs-14 fw-500 mt-4 ml-4">Sales Person</p>
                                <div class="inputContainer">{{contactData.classification.sales_person.name || "-" }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="toShow == 'classification'" class="d-flex justify-content-center align-items-center mt-6">
                    <button class="btn btn-des-small" style="color: #fff; background-color: #d51650;" @click="closeView()">Close</button>
                </div>
            </div>
        </div>
        <share-contact-modal @contactSharedSuccess="contactSharedSuccess" modal_name="shareContact" :id="contactData._id" :sharing_id="sharing_id" :full_name="contactData.first_name ? contactData.first_name.first_name : '' + ' ' + contactData.middle_name + ' ' + contactData.last_name" @linkcopied="linkcopied"></share-contact-modal>
        <remove-visibility modal_name="removeVisibility" :name="sharingSelected.full_name"></remove-visibility>
        <view-social-media-link v-if="view_social_media_link" @hideSocialMedia="hideSocialMedia" modal_name="socialMediaLink" :socialMediaType="socialMediaType" :url="url" :uid="uid"></view-social-media-link>
        <link-copied modal_name="linkCopied"></link-copied>
        <delete-contact-visibility modal_name="contactvisibility"  @deleteSharing="deleteSharing" @cancelDelete="sharingSelected = ''" :name="sharingSelected.full_name"></delete-contact-visibility>
    </div>
</template>

<script>
import moment from "moment";
import { HalfCircleSpinner } from "epic-spinners";
const RemoveVisibility = () => import('./alertModals/RemoveVisibility');
const ViewSocialMediaLink = () => import('./alertModals/ViewSocialMediaLink');
const DeleteContactVisibility = () => import('./alertModals/DeleteContactVisibility');
const LinkCopied = () => import('./alertModals/LinkCopied');
const ShareContactModal = () => import('./ShareContactModal');
export default {
    data() {
        return {
            callInProgress: false,
            toShow: "profile",
            basicInformationTab: true,
            statusTab:true,
            contactTab: true,
            notesTab: true,
            addressInformationTab: true,
            socialMediaTab: true,
            referralTab: true,
            visibilityTab: true,
            classificationsTab:true,
            contactData: {},
            count: 0,
            limit: 10,
            search_key: "",
            sharing_id: "",
            sharingSelected: "",
            socialMediaType: "",
            socialMediaIndex: "",
            url: "",
            uid: "",
            not_shared: false,
            view_social_media_link: false
        }
    },
    components: {
        RemoveVisibility,
        HalfCircleSpinner,
        ViewSocialMediaLink,
        LinkCopied,
        DeleteContactVisibility,
        ShareContactModal
    },
    methods: {
        closeView(){
            this.$router.push("/listcontacts");
        },
        async addToMyContacts() {
            try {
                let param = {
                    ...this.contactData
                }
                param['add_to_my_contacts'] = true
                param['contact_id'] = param._id
                let res = await this.$http.post("/contacts/add", param);
                if (res.data.status_id == 1) {
                    this.$router.push("/listcontacts");
                }
            }
            catch(err) {
            }
        },
        moment(date) {
            return moment(date);
        },
        showBasicInformation() {
            if (this.basicInformationTab) {
                this.basicInformationTab = false;
            } else {
                this.basicInformationTab = true;
            }
        },
        showContact() {
            if (this.contactTab) {
                this.contactTab = false;
            } else {
                this.contactTab = true;
            }
        },
        showStatus() {
            if (this.statusTab) {
                this.statusTab = false;
            } else {
                this.statusTab = true;
            }
        },
        showNotes() {
            if (this.notesTab) {
                this.notesTab = false;
            } else {
                this.notesTab = true;
            }
        },
        showAddressInformation() {
            if (this.addressInformationTab) {
                this.addressInformationTab = false;
            } else {
                this.addressInformationTab = true;
            }
        },
        showSocialMedia() {
            if (this.socialMediaTab) {
                this.socialMediaTab = false;
            } else {
                this.socialMediaTab = true;
            }
        },
        showReferral() {
            if (this.referralTab) {
                this.referralTab = false;
            } else {
                this.referralTab = true;
            }
        },
        showVisibility() {
            if (this.visibilityTab) {
                this.visibilityTab = false;
            } else {
                this.visibilityTab = true;
            }
        },
        showClassifications(){
            if (this.classificationsTab) {
                this.classificationsTab = false;
            } else {
                this.classificationsTab = true;
            }
        },
        editSharing(id) {
            this.sharing_id = id;
            this.$modal.show("shareContact");
        },
        editContact() {
            if (localStorage.getItem("view_contact_type") == "not_shared") {
                localStorage.setItem("edit_contact_type", "not_shared");
            } else {
                localStorage.setItem("edit_contact_type", "shared");
            }
            this.$router.push('/editcontact/' + this.$route.params.id);
        },
        removeSharingSelected(visibility) {
            this.sharingSelected = visibility;
            this.removeSharing();
        },
        viewSocial(type,index) {
            this.view_social_media_link = true
            setTimeout(() => {
                this.$modal.show("socialMediaLink");
            }, 500);
            this.socialMediaType = type;
            this.socialMediaIndex = index;
            if (this.contactData.social_media[index].account_url) {
                this.url = this.contactData.social_media[index].account_url;
                this.uid = this.contactData.social_media[index].account_id;
            } else {
                this.url = "";
                this.uid = "";
            }
        },
        hideSocialMedia() {
            this.view_social_media_link = false
        },
        linkcopied() {
            this.$modal.hide("shareContact");
            this.$modal.show("linkCopied");
            setTimeout(() => {
                this.$modal.hide("linkCopied");
            }, 2000);
        },
        removeSharing() {
                this.$modal.show("contactvisibility");
        },
        async deleteSharing() {
            let res = await this.$http.get("/contacts/sharing/remove_sharing?id=" + this.sharingSelected._id);
            if (res.data.status_id == 1) {
                this.contactData.visibility = this.contactData.visibility.filter(x => x._id != this.sharingSelected._id);
                // setTimeout(() => {
                    this.$modal.show("removeVisibility");
                // }, 2000);
                setTimeout(() => {
                    this.$modal.hide("removeVisibility");
                }, 2000);
            }
        },
        async getContactDetails() {
            this.callInProgress = true;
            let url = "";
            if (localStorage.getItem("view_contact_type") == "not_shared") {
                url = "contacts/get?id=" + this.$route.params.id;
                this.not_shared = true;
            } else {
                url = "contacts/shared_contact_details?id=" + this.$route.params.id;
                this.not_shared = false;
            }
            let res = await this.$http.get(url);
            if (res.data.status_id == 1) {
                this.contactData = res.data.response;
                if (!this.contactData.profile_pic && !this.contactData.avatar_pic) {
                    this.contactData.profile_pic = "/static/images/default-user-icon.svg";
                }
                this.contactData.social_media = this.contactData.social_media.filter(x => {
                    if (x.account_url != "" || x.account_id != "") {
                        return x;
                    }
                });
            }
            this.callInProgress = false;
        },
        contactSharedSuccess() {
            this.getContactDetails();
            this.not_shared = true;
        }
    },
    created() {
        this.getContactDetails();
    },
    destroyed() {
        localStorage.removeItem("view_contact_type");
    }
}
</script>

<style scoped>
    .main-div {
        background: #fff;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .header-outer {
        width: 100%;
        background: #00448b;
        color: #fff;
        font-size: 14px;    
        padding-top: 2px;
        padding-bottom: 2px;
        margin-bottom: 10px;
        padding-right: 20px;
        padding-left: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .inner-div {
        padding-top: 10px;
        padding-bottom: 40px;
        padding-left: 80px;
        padding-right: 80px;
    }
    .address {
        background: #ebf8ff;
        padding: 20px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 6px;
        width: 16.5em;
        margin-right: 4em;
        line-height: 16px;
    }
    .primary-tag {
        border-radius: 10px;
        font-size: 10px;
        font-weight: 500;
        padding: 2px 10px;
        color: #fff;
        height: max-content;
    }
    .social-media {
        background: #def0f9;
        border-radius: 5px;
        width: 10em;
        height: 10em;
        margin-right: 25px;
        margin-bottom: 60px;
    }
    .visibility {
        background: #def0f9;
        border-radius: 5px;
        width: 22em;
        height: 6em;
    }
    .inputContainer {
        color: #303031;
        font-size: 12px;
        padding: 10px 20px 10px 20px;
        border: 1px solid #3f3f3f;
        border-radius: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .qualified-lead{
        background-color: #efefef;
        padding: 5px;
        border-radius: 4px;
        color: #0475f3;
    }
    .pending-lead{
        background-color: rgba(213, 22, 80, 0.16);
        padding: 5px;
        border-radius: 4px;
        color: #d51650;
    }
    .nonquali-lead{
        background-color: #efefef;
        padding: 5px;
        border-radius: 4px;
        color: #3f3f3f;
    }
    .active-lead{
        background-color: rgba(17, 185, 3, 0.16);
        padding: 5px;
        border-radius: 4px;
        color: #11b903;
    }
</style>