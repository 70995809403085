<template>
  <!-- User Profile Address Edit Info Tab Content View From Account Profile -->
  <div>
    <div class="tabs-content-secondblock mb-7 right-tab">
      <div>
        <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
            <div class="breadcrumbactive ml-2">
                <div>
                    <span>Profile</span>
                    <div>Address</div>
                </div>
            </div>
        </div>
    </div>
      <div class="address-info bg-white" style="margin-right: 5px; min-height: 460px; border-radius: 0px 6px 6px 0px;margin-left: 10px;">
        <div class="d-flex justify-content-between align-items-center mb-3" style="background-color: #00448b;border-radius: 0px 6px 0 0">
          <h6 class="subHeaderTitle ml-6 my-2 text-white">
            <i class="crmnew-marker iconsize-16 pt-1 pr-2" style="font-size: 14px !important; color: #fff;width: 25px;height: 25px;"></i>
            <span class="profile-text fs-14 fw-500">Address</span>
          </h6>
          <!-- <div v-if="$route.params.id == loggedInUser.user_id" class="d-flex align-items-center">
              <div v-if="!edit_enable" style="margin-right: 10px;">
                <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                    <button  class="btn-icon pointer ml-2 mr-2" @click="editFullAddress" style="padding: 3px 8px !important;background: rgb(255, 255, 255);border-radius: 3px !important;">
                      <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;">
                      <span class="ml-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                    </button>
                </el-tooltip>
              </div>
              <div v-if="edit_enable" style="margin-right: 10px;border-radius: 3px;">
                <el-tooltip class="item" effect="dark" content="Cancel" placement="top">
                    <button v-if="edit_enable" class="btn btn-danger pointer ml-2 mr-2 text-capitalize py-0 px-2" style="height: 30px;line-height: 1;" @click="hideEditFullAddress">
                      <span class="ml-1" style="color: #fff;font-size: 14px;font-weight: 600;">Cancel</span>
                    </button>
                </el-tooltip>
              </div>
          </div> -->
        </div>
        <div class="row pb-50" v-if="ajaxCallinprogress">
          <div class="col-lg-12 py-5 px-3 mt-10">
            <div class="flex-container">
              <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
          </div>
        </div>
      <div v-else class="px-6 pb-3">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 d-flex mt-2" v-for="(address_field, index) in address" :key="index">
          <div class="new-address-card py-3 px-4 position-relative w-100 d-flex flex-column">
            <!-- <el-checkbox @change="addressChecked($event, address_field)" class="position-absolute" style="top:-3px; left:-1px" v-model="address_field.is_checked"></el-checkbox> -->
            <div>
              <p class="new-blue fs-12 font-weight-bold text-truncate" :title="address_field.address_type" style="max-width: 210px;text-transform: capitalize;">{{address_field.address_type}}</p>
              <span v-if="$route.params.id == loggedInUser.user_id" class="position-absolute" style="right: 5px; top: 5px;" >
                <i class="icon icon-pencil new-blue pointer fs-16 pointer" @click="showEditAddressPopup(address_field,index)"></i>
                <i class="icon icon-delete new-blue pointer fs-16 pointer ml-2" @click="showAddressDelete(address_field, index)"></i>
              </span>
            </div>
            <hr>
            <p class="fs-12">{{address_field.township}} {{address_field.street}} {{address_field.city}} {{address_field.state}} {{address_field.country}} {{address_field.zip_code}}</p>
          </div>
        </div>
        <div v-if="$route.params.id == loggedInUser.user_id" class="col-lg-4 col-md-6 col-sm-12 d-flex pointer mt-2" @click="showAddAddressPopup">
          <div class="new-address-card py-3 px-4 position-relative w-100 d-flex flex-column align-items-center justify-content-center" style="min-height: 120px;">
            <i class="icon icon-plus text--black d-flex align-items-center justify-content-center" style="line-height: 4rem;font-size: 4rem;padding: 18px;border: 1.5px dashed #000;border-radius: 2px;"></i>
            <p class="new-blue mt-5" style="font-weight: 550;">Add New Address</p>
          </div>
        </div>
      </div>
      <div class="row mt-2" v-if="is_add_edit_address">
        
      </div>
      </div>
    </div>
    </div>
    <delete-address v-if="show_delete_address" modal_name="deleteAddress" :address_type="address_field_data.address_type" :address_id="address_field_data.id ? address_field_data.id : ''" :index="address_index" @addressDelete="addressDelete" @cancelDelete="cancelDelete"></delete-address>
    <add-address-warn-popup v-if="is_add_new_address" :mode="mode" modal_name="address-popup" @hideSelf="hideSelf" ></add-address-warn-popup>
    <add-edit-address  v-if="show_add_edit_address" :add_edit_mode="add_edit_mode" :add_edit_address_data="add_edit_address" @addressAddEditSuccess="addressAddEditSuccess" modal_name="add-edit-address-popup" @hideAddEditAddressPopup="hideAddEditAddressPopup"></add-edit-address>
    <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
    <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
  </div>
</template>
<script>
import { User } from "../mixins/user";
import countries from "../json/countries.json";
import { HalfCircleSpinner } from "epic-spinners";
import { EventBus } from "../eventBus/nav-bar-event.js";
import DeleteAddress from "./DeleteAddress";
import axios from "axios";
import profile from '../mixins/profile';
import globals from '../globals';
import AddAddressWarnPopup from './AddAddressWarnPopup';
import AddEditAddress from './AddEditAddress';
import { SweetModal } from 'sweet-modal-vue';
export default {
  data() {
    return {
        warning_msg: '',
        success_msg: '',
        maxlengthaccountzipcode: 30,
        maxlengthaccounttownship: 30,
        maxlengthaccountstreet: 30,
        permanentAddress: false,
        activatePermanentAddress: false,
        countries: countries,
        states: [],
        ajaxCallinprogress: false,
        requestResponse: false,
        same_as_permanent_address: false,
        same_as_permanent_address_index: 0,
        userBasicInfo: {},
        address: [{
            street: "",
            city: "",
            township: "",
            state: "",
            country: "",
            address_type: "Current Address",
            zip_code: "",
            location_vmodel: "",
            same_as_permanent_address: false,
            is_checked: false
        }],
        add_edit_address: {
            street: "",
            city: "",
            township: "",
            state: "",
            country: "",
            address_type: "Current Address",
            zip_code: "",
            location_vmodel: "",
            same_as_permanent_address: false,
            is_checked: false
        },
        boolDuplicateAddress: [],
        addressTypes: [],
        addressIndex: 0,
        isState:false,
        isCountry:false,
        edit_country: false,
        edit_state: false,
        edit_city: false,
        edit_township: false,
        edit_street: false,
        edit_zip_code: false,
        edit_country_index: 0,
        edit_state_index: 0,
        edit_city_index: 0,
        edit_township_index: 0,
        edit_street_index: 0,
        edit_zip_code_index: 0,
        edit_full_address: false,
        edit_full_address_index: 0,
        select_country: false,
        country_check_index: 0,
        skip_address:0,
        limit_address:10,
        which_type: '',
        dropdown_loading: false,
        isVisible: false,
        scrollable: false,
        count_address: 0,
        is_add_edit_address: false,
        edit_enable: false,
        save_api_call_in_progress: false,
        address_data_id: [],
        show_delete_address: false,
        address_field_data: null,
        address_index: 0,
        is_add_new_address: false,
        mode: 'add',
        show_add_edit_address: false,
        add_edit_mode: 'add',
        stored_add_edit_address_data: null,
        stored_current_index: 0
    };
  },
  mixins: [User, profile],
  methods: {
    hideAddEditAddressPopup() {
        this.show_add_edit_address = false
        if(this.add_edit_mode == 'add') {
            this.add_edit_address = {
                street: "",
                city: "",
                township: "",
                state: "",
                country: "",
                address_type: "Current Address",
                zip_code: "",
                location_vmodel: "",
                same_as_permanent_address: false,
                is_checked: false
            }
        } else {
            this.add_edit_address = this.stored_add_edit_address_data
            this.address[this.stored_current_index] = this.stored_add_edit_address_data
        }
        setTimeout(() => {
            this.$modal.hide('add-edit-address-popup');
        }, 500);
    },
    addressAddEditSuccess() {
        this.show_add_edit_address = false
        setTimeout(() => {
            this.$modal.hide('add-edit-address-popup');
            this.GetUserDetails(this.$route.params.id);
        }, 500);
    },
    hideSelf() {
        this.is_add_new_address = false;
    },
    addressChecked(event, data) {
      if(event) {
          data.is_checked = true
          this.address_data_id.push(data.id);
          this.address_data_id = [...new Set(this.address_data_id)]
      } else {
          data.is_checked = false
          let dat_index = this.address_data_id.findIndex((data) => data === data.id)
          this.address_data_id.splice(dat_index, 1)
          this.address_data_id = [...new Set(this.address_data_id)]
      }
      this.$forceUpdate();
    },
    showAddAddressPopup() {
        this.add_edit_address = {
            street: "",
            city: "",
            township: "",
            state: "",
            country: "",
            address_type: "Current Address",
            zip_code: "",
            location_vmodel: "",
            same_as_permanent_address: false,
            is_checked: false
        }
        this.add_edit_mode = 'add';
        this.show_add_edit_address = true
        setTimeout(() => {
            this.$modal.show('add-edit-address-popup');
        }, 500);
    },
    hideAddEditAddress() {
      this.is_add_edit_address = false
      this.add_edit_address = {
          street: "",
          city: "",
          township: "",
          state: "",
          country: "",
          address_type: "Current Address",
          zip_code: "",
          location_vmodel: "",
          same_as_permanent_address: false,
          is_checked: false
      }
    },
    showEditAddressPopup(address, index) {
      this.stored_add_edit_address_data = JSON.parse(JSON.stringify(address))
      this.stored_current_index = index
      this.add_edit_address = address
      this.add_edit_mode = 'edit';
      this.show_add_edit_address = true
      setTimeout(() => {
          this.$modal.show('add-edit-address-popup');
      }, 500);
    },
    countryClose(data) {
      this.add_edit_address.country = data;
      this.$forceUpdate();
    },
    stateClose(data) {
      this.add_edit_address.state = data;
      this.$forceUpdate();
    },
    addAddress() {
      this.address.push({
          street: "",
          city: "",
          township: "",
          state: "",
          country: "",
          address_type: "Current Address",
          zip_code: "",
          location_vmodel: "",
          same_as_permanent_address: false,
          is_checked: false
      });
    },
    showAddressDelete(data, index) {
      this.address_field_data = data;
      this.address_index = index
      this.show_delete_address = true
      setTimeout(() => {
        this.$modal.show("deleteAddress");
      }, 500);
    },
    editFullAddress() {
      this.edit_enable = true
        // this.edit_full_address = true;
        // this.edit_country = false;
        // this.edit_state = false;
        // this.edit_city = false;
        // this.edit_township = false;
        // this.edit_street = false;
        // this.edit_zip_code = false;
        // this.edit_full_address_index = index;
    },
    hideEditFullAddress() {
      this.edit_enable = false
    },
    async addressDelete(data) {
        // this.boolDuplicateAddress = this.boolDuplicateAddress.filter(x => x != data.index);
        if(data.id.length) {
            try {
                let id = data.id;
                let response = await this.deleteAddressById(id);
                if(response.status_id == 1) {
                  this.success_msg = response.message;
                  this.$refs.success_modal.open();
                  setTimeout(() => {
                      this.$refs.success_modal.close();
                    this.GetUserDetails(this.$route.params.id);
                  }, 3000);
                }
            }
            catch(err) {
                // console.log(err);
                this.warning_msg = err;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                }, 3000);
            }
        } 
        else {
            this.address.splice(data.index,1);
        }
        this.show_delete_address = false
        setTimeout(() => {
          this.$modal.hide("deleteAddress");
        }, 500);
    },
    cancelDelete() {
      this.show_delete_address = false
      setTimeout(() => {
        this.$modal.hide("deleteAddress");
      }, 500);
    },
    resetAddress(index) {
      if (index === 0) {
          this.address[index].street = "",
          this.address[index].city = "",
          this.address[index].township = "",
          this.address[index].state = "",
          this.address[index].country = "",
          this.address[index].zip_code = "",
          this.address[index].address_type = "Permanent Address (Billing Address)",
          this.address[index].location_vmodel = "",
          this.address[index].same_as_permanent_address = false
      } else {
        // this.address[index] = {
        //   street: "",
        //   city: "",
        //   township: "",
        //   state: "",
        //   country: "",
        //   zip_code: "",
        //   address_type: "Current Address",
        //   location_vmodel: "",
        //   same_as_permanent_address: false
        // };
          this.address[index].street = "",
          this.address[index].city = "",
          this.address[index].township = "",
          this.address[index].state = "",
          this.address[index].country = "",
          this.address[index].zip_code = "",
          this.address[index].address_type = "Current Address",
          this.address[index].location_vmodel = "",
          this.address[index].same_as_permanent_address = false
      }
      if (this.boolDuplicateAddress.includes(index) && index != 0) {
        this.boolDuplicateAddress = this.boolDuplicateAddress.filter(x => x !== index);
      }
      this.$forceUpdate();
    },
    setPlace(place) {
      let currentPlace = place;
      let address = place.formatted_address.split(", ");
      this.add_edit_address.street =
        address[address.length - 3] || "";
      this.add_edit_address.township =
        address[address.length - 4] || "";
      this.add_edit_address.city = currentPlace.vicinity;
      let statezip = address[address.length - 2]
        ? address[address.length - 2].split(" ")
        : ["", ""];
      if (statezip.length == 1) {
        if (statezip[0].match(/^[0-9]+$/)) {
          this.add_edit_address.zip_code = statezip[0];
        } else {
          this.add_edit_address.state = statezip[0];
        }
      } else {
        this.add_edit_address.zip_code = statezip[0].match(/^[0-9]+$/)
          ? statezip[0]
          : statezip[1].match(/^[0-9]+$/)
          ? statezip[1]
          : "";
        this.add_edit_address.state = statezip[0].match(/^[a-zA-Z]+$/)
          ? statezip[0]
          : statezip[1].match(/^[a-zA-Z]+$/)
          ? statezip[1]
          : "";
      }
      if (address[address.length - 1].includes("-")) {
        let countrySelected = address[address.length - 1].split(" - ");
        countrySelected.forEach(x => {
          if (this.countries.includes(x)) {
            this.add_edit_address.country = x;
          } else {
            this.add_edit_address.country = "";
          }
        });
      } else {
        this.add_edit_address.country = address[address.length - 1];
      }
      // this.add_edit_address.address.latitude = currentPlace.geometry.location.lat() || "";
      // this.add_edit_address.address.longitude = currentPlace.geometry.location.lng() || "";
      this.add_edit_address.location_vmodel = place.formatted_address;
      this.$forceUpdate();
    },
    getLocation() {
      // this.addressIndex = index;
      if (navigator.geolocation) {
        // timeout at 60000 milliseconds (60 seconds)
        var options = {
          enableHighAccuracy: false,
          timeout: 30000, // milliseconds (30 seconds)
          maximumAge: 600000
        }; // milliseconds (10 minutes)
        navigator.geolocation.getCurrentPosition(
          this.showLocation,
          this.errorHandler,
          options
        );
      } else {
        alert("Sorry, browser does not support geolocation!");
      }
    },
    showLocation(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
      let calllatlong = axios.create();
      delete calllatlong.defaults.headers.common['Authorization'];
      calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
      .then(response => {
        let address = response.data.results[0].formatted_address.split(", ");
        this.add_edit_address.street =
          address[address.length - 6] || "";
        this.add_edit_address.township =
          address[address.length - 4] || "";
        this.add_edit_address.city = address[address.length - 3] || "";
        let statezip = address[address.length - 2]
          ? address[address.length - 2].split(" ")
          : ["", ""];
        this.add_edit_address.zip_code = statezip[0].match(/^[0-9]+$/)
          ? statezip[0]
          : statezip[1].match(/^[0-9]+$/)
          ? statezip[1]
          : "";
        this.add_edit_address.state = statezip[0].match(/^[a-zA-Z]+$/)
          ? statezip[0]
          : statezip[1].match(/^[a-zA-Z]+$/)
          ? statezip[1]
          : "";
        if (address[address.length - 1].includes("-")) {
          let countrySelected = address[address.length - 1].split(" - ");
          countrySelected.forEach(x => {
            if (this.countries.includes(x)) {
              this.add_edit_address.country = x;
            } else {
              this.add_edit_address.country = "";
            }
          });
        } else {
          this.add_edit_address.country = address[address.length - 1];
        }
        this.add_edit_address.location_vmodel = response.data.results[0].formatted_address;
        this.$forceUpdate();
      })
      .catch(e => {
        // this.errors.push(e)
      });
    },
    errorHandler(err) {
      if (err.code == 1) {
        // alert("Error: Access is denied!");
      } else if (err.code == 2) {
        // alert("Error: Position is unavailable!");
      }
    },
    duplicatePermanentAddress(event, index) {
      if(event.target.checked) {
        this.address[index].same_as_permanent_address = true;
      } else {
        this.address[index].same_as_permanent_address = false;
      }
      if (this.boolDuplicateAddress.includes(index)) {
        this.boolDuplicateAddress = this.boolDuplicateAddress.filter(x => x !== index);
        this.address[index] = {
          street: "",
          city: "",
          township: "",
          state: "",
          country: "",
          zip_code: "",
          address_type: "Current Address",
          location_vmodel: ""
        };
      } else {
        this.boolDuplicateAddress.push(index);
        this.address[index].city = this.address[0].city;
        this.address[index].country = this.address[0].country;
        this.address[index].location_vmodel = this.address[0].location_vmodel;
        this.address[index].state = this.address[0].state;
        this.address[index].street = this.address[0].street;
        this.address[index].township = this.address[0].township;
        this.address[index].zip_code = this.address[0].zip_code;
      }
    },
    updateContactAddress() {
      this.address.forEach(x => {
        delete x.same_as_permanent_address;
        if (!x.city && !x.country && !x.state && !x.street && !x.township && !x.zip_code) {
          this.address = this.address.filter(i => i != x);
        } else {
          delete x["location_vmodel"];
        }
      });
      this.saveEditUser(this.address);
      this.$emit("updateUserData");
    },
    async GetUserDetails(id) {
      this.ajaxCallinprogress = true;
        let response = await this.getAddressApi(id)
              this.edit_full_address = false;
              this.edit_country = false;
              this.edit_state = false;
              this.edit_city = false;
              this.edit_township = false;
              this.edit_street = false;
              this.edit_zip_code = false;
              this.select_country = false;
            if (response.status_id == 1) {
            localStorage.setItem('address_data', JSON.stringify(response.response));
            this.userBasicInfo = response.response;
            this.address = this.userBasicInfo;
            this.ajaxCallinprogress = false;
        }
    },
    updateState(country) {
        this.edit_state = true;
        this.isState = true;
        this.states = [];
        if (country) {
            let params = {
            country_required: false,
            state_required: true,
            country_name: country,
            };
            this.$http.post(globals.CRM_API +"/location/get_location", params).then(res => {
            if (res.data.status_id == 1 && "all_states" in res.data.response) {
                res.data.response.all_states.forEach(x => {
                this.states.push(x);
                });
                this.isState = false
            }
            });
        }
    },
    async dropdownsData(event, type) {
        if(event === true) {
            this.which_type = type;
            this.dropdown_loading = true;
            try {
                let params = {
                    type: type,
                    skip: this.skip_address,
                    limit: this.limit_address
                };
                let response = await this.getDropdownsData(params);
                if(response.status_id == 1) {
                    if(type == 'address') {
                        this.addressTypes = response.response;
                    }
                    this.count_address = response.response.length;
                }
                this.dropdown_loading = false;
                this.count = response.response.length;
            }
            catch(err) {
                // console.log(err);
            }
        }
    },
    async reachedEndOfListDropdown(reached) {
        this.isVisible = reached;
        if (reached) {
            if (this.scrollable == false) {
                this.dropdown_loading = true;
                let params = {
                    limit: 10,
                    type: this.which_type,
                    skip: this.count_address
                };
                let response = await this.getDropdownsData(params);
                this.temp_dropdown_options = response.response.length;;
                if (response.skip == this.count_address) {
                    if (this.temp_dropdown_options > 0) {
                        let tempFiles = response.response;
                        if(this.which_type == 'address') {
                            tempFiles.forEach(el => {
                                this.addressTypes.push(el);
                            });
                        }
                        this.count_address += response.response.length;
                        this.dropdown_loading = false
                    } else {
                        this.scrollable = true
                        this.dropdown_loading = false
                    }
                }
                this.dropdown_loading = false
            }
        }
    },
  },
  components: {
    HalfCircleSpinner,
    DeleteAddress,
    globals,
    AddAddressWarnPopup,
    AddEditAddress,
    SweetModal
  },
  mounted() {
    EventBus.$on("addressInformation", () => {
      this.updateContactAddress();
    });
    EventBus.$on("hideuser", () => {
      this.hideEdit();
    });
  },
  created() {
    if (this.$route.params.id) {
      this.GetUserDetails(this.$route.params.id);
    } else {
      this.GetUserDetails(this.loggedInUser.id);
    }
    const dict = {
        custom: {
            country:{
                required: () => "Country is Required",
            },
        }
    };
    this.$validator.localize("en", dict);
  },
  destroyed() {
    EventBus.$off("addressInformation");
    EventBus.$off("hideuser");
  },
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser;
    },
    account() {
      return this.$store.getters.account;
    },
  },
};
</script>
<style>
.socialMedia.dropdown-toggle::after {
  margin-left: 5px;
}

@-moz-document url-prefix() {
  .inputMedia {
    padding-right: 26px !important;
  }
}
.mediaDropdown.dropdown-menu {
  margin: 0rem 0rem 0 !important;
}
.contact-details-tab-view.el-tabs.el-tabs--top
  .el-tabs__content
  .el-tab-pane
  .tabs-content-firstblock {
  border-radius: 5px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cdd2da;
  background-color: #ffffff;
  margin: 5px 10px 15px;
}
.contact-details-tab-view.el-tabs.el-tabs--top
  .el-tabs__content
  .el-tab-pane
  .tabs-content-secondblock {
  border-radius: 5px;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #cdd2da;
  background-color: #ffffff;
  margin: 5px 10px 15px;
}
textarea.textarea-control {
  height: auto;
}

textarea.textarea-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #4e5764;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cdd2da;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}
.input-group.digi-input-group {
  border: 1px solid #cdd2da !important;
}
.input-group.digi-input-group > .form-control {
  position: relative !important;
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  width: 1% !important;
  margin-bottom: 0 !important;
  height: 22px !important;
  padding: 6px 12px 6px 2px !important;
  color: #4e5764;
  text-decoration: underline;
  border-bottom: 1px solid transparent !important;
}
.input-group.digi-input-group .input-group-addon {
  padding-left: 0rem !important;
  padding-right: 0.2rem !important;
  border-bottom: 1px solid transparent !important;
  padding-top: 2px;
  cursor: pointer;
}
.qrcode-block-view-account {
  border-radius: 6px;
  width: 104px;
  height: 105px;
  position: relative;
  display: inline-block;
  display: block;
  border: 1px solid #0475f3;
  background: #d6e2ef;
}
.qrcode-content-view-account {
  text-align: center;
  margin: 6px auto;
  background: #d6e2ef !important;
}
.qr-logo-view-account {
  position: absolute;
  margin-top: 40px;
  margin-left: 35px;
  width: 30px;
}
.removeBlocks {
  right: 20px;
  margin-right: 2px;
}
.removeBlocksPhone {
  right: 18px;
  margin-right: 2px;
}
.removeBlocksPhone.removedAddPhone {
  right: 0px;
  margin-right: 2px;
}
.pointerEventsNone {
	pointer-events: none !important;
}
.new-address-card {
  padding: 0 12.3px 11px 0;
  border-radius: 4px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #0d84f2;
  background-color: #ffff;
}
/* .new-add-edit-address-card{
  padding: 15.5px 6.6px 12.5px 12px;
  border-radius: 6px;
  box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.5px #3195f2;
  background-color: #fff;
} */
@media only screen and (min-width: 0px) and (max-width: 350px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .address-info{
        background-color: #fff !important;
        margin-left: -10px !important;
    }
    .address-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -8px !important;
    }
    .infoHeader{
        width: 300px !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .profile-content-left-text-address {
        width: 100%;
        min-width: 100%;
        position: relative; 
        font-weight: 600;
    }
}
@media only screen and (min-width:351px) and (max-width: 400px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .address-info{
        background-color: #fff !important;
        margin-left: -10px !important;
    }
    .address-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -8px !important;
    }
    .infoHeader{
        width: 354px !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
@media only screen and (min-width:401px) and (max-width: 500px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .address-info{
        background-color: #fff !important;
        margin-left: -10px !important;
    }
    .address-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -8px !important;
    }
    .infoHeader{
        width: 393px !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
@media only screen and (min-width:501px) and (max-width: 768px){
    .iconsize{
        font-size: 12px !important;
    }
    .profile-text{
        font-size: 14px !important;
    }
    .address-info{
        background-color: #fff !important;
        margin-left: -10px !important;
    }
    .address-info{
        width: 100% !important;
    }
    .content-left{
        margin-left: -8px !important;
    }
    .infoHeader{
        width: 100% !important;
        margin-left: 1px !important;
    }
    .right-tab{
        margin-bottom: 150px !important;
    }
    .subHeaderTitle{
        font-size: 14px !important;
    }
}
</style>

