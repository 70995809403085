<template>
    <div>
    <div class="row" v-if="ajax_call_in_progress">
        <div class="col-lg-12 py-5 px-3 mt-10">
            <div class="flex-container">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
        </div>
    </div>
    <div v-if="!ajax_call_in_progress" class="footercomponent w-100" style="right: 0; left: 0; bottom: 0; z-index: 2;bottom: 0;">
        <div class="page-footer d-flex flex-column justify-content-between px-6 pt-4 pb-0" style="background-color: #00448b;height: auto !important;">
            <div class="helpdesk-footer d-none d-md-block" style="z-index: 99;">
                <div class="py-2">
                    <div class="toolbar row no-gutters align-items-center px-4 px-sm-6">
                        <div class="col">
                            <div class="row no-gutters align-items-center">
                                <div class="">
                                    <span class="text-footer pointer" @click="openHelpdesk()">
                                        <img src="/static/images/help-desk.svg"
                                            class="help-desk-image" />HelpDesk
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row no-gutters align-items-center footer-content-justify flex-nowrap">
                <div class="app-version-footer-text-desktop text-center">
                    <!-- <span v-if="prod" class="h6 text-footer-down">App Version (P) : 2.0.4b3</span> -->
                    <span class="h6 text-footer-down">App Version (T) : 1.1.0a33</span>
                </div>
                <div class="copyright-footer-text-desktop text-center">
                    <span class="h6 text-footer-down">© Copyright 2014 - {{ new Date().getFullYear() }}
                        <a class="footertext" href="https://www.digicollect.com/" target="_blank">DigiCollect</a>
                        - All Rights Reserved</span>
                </div>
                <div class="d-flex footer-help-icons">
                    <router-link to="/" style="position: relative; bottom: 35px;">
                        <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/home.svg">
                    </router-link>
                    <a class="ml-5 footer-icons pointer" style="position: relative; bottom: 35px;">
                        <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/search.svg">
                    </a>
                    <a class="ml-5 footer-icons pointer" style="position: relative; bottom: 35px;">
                        <img src="https://cdn.digicollect.com/cdn/scmnew/images/scm/chat.svg">
                    </a>
                </div>
            </div>
            <div class="app-version-footer-text-mob text-center">
                <!-- <span v-if="prod" class="h6 text-footer-down">App Version (P) : 2.0.4b3</span> -->
                <span class="h6 text-footer-down">App Version (T) : 1.1.0a33</span>
            </div>
            <div class="copyright-footer-text-mob text-center">
                <span class="h6 text-footer-down">© Copyright 2014 - {{ new Date().getFullYear() }}
                    <a class="footertext" href="https://www.digicollect.com/" target="_blank">DigiCollect</a>
                    - All Rights Reserved</span>
            </div>
        </div>
    </div></div>
</template>
<script>
    // import globals from "./globals";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    export default {
        data() {
            return {
                ajax_call_in_progress: false,
                prod: false,
            }
        },
        components: {
            HalfCircleSpinner},
        methods:{
            openHelpdesk(){
                if(location.origin == 'http://0.0.0.0:8080' || 'http://testadmin.digicollect.com/'){
                    window.open('https://testhelpdesk.digicollect.com/','_blank');
                }else if(location.origin == 'https://admin.digicollect.com/'){
                    window.open('https://helpdesk.digicollect.com/','_blank');
                }
            },
            loader(){
                setTimeout(() => {
                this.ajax_call_in_progress = true;
                }, 10000);
                this.ajax_call_in_progress = false;
            },
        },
        mounted() {
            // if (globals.CRM_URL == "https://crm.digicollect.com") {
            //     this.prod = true;
            // }
            // this.loader();
        }
    }
</script>
<style>
    .Line-7-Copy {
        width: 2px;
        height: 24px;
        border: solid 1px #e0e3e6;
    }

    .footer-help-icons {
        z-index: 1;
    }

    span.text-footer-down {
        font-size: 12px;
        text-align: center !important;
        color: #ffffff !important;
        font-weight: 600;
    }

    .footertext {
        color: #ffffff !important;
        text-decoration: none !important;
    }

    .page-footer {
        min-height: 58px;
    }

    .no-gutters .textcenter-footer {
        text-align: center;
    }

    .bg-secondary-light {
        background-image: linear-gradient(to right,
                #0d84f2,
                #0d84f2 51%,
                #69b3fc,
                #9fcbff) !important;
    }

    .bottomfooter {
        background-color: #ffffff !important;
        height: 30px;
        bottom: 58px;
    }

    .helpdesk-footer {
        height: 38px;
        border-radius: 15px;
        background-color: #ffffff;
        border: solid 2px #005dae;
        margin: -35px auto 0;
    }

    .text-footer {
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.42;
        letter-spacing: normal;
        color: #4e5764;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .help-desk-image {
        width: 17.7px;
        height: 17.7px;
        -o-object-fit: contain;
        object-fit: contain;
        margin-left: 4px;
        margin-right: 8px;
    }

    .col-20,
    .col-sm-20,
    .col-md-20,
    .col-lg-20,
    .col-xl-20 {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-20 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .copyright-footer-text-mob,
    .app-version-footer-text-mob {
        display: block;
        padding-bottom: 10px;
    }

    .copyright-footer-text-desktop,
    .app-version-footer-text-desktop {
        display: none;
    }

    .footer-content-justify {
        justify-content: space-between;
    }

    @media (min-width: 450px) {
        .app-version-footer-text-mob {
            display: none;
        }

        .app-version-footer-text-desktop {
            display: block;
        }
    }

    @media (max-width: 450px) {
        .footer-content-justify {
            justify-content: center;
        }
    }

    @media (min-width: 576px) {
        .col-sm-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (max-width: 930px) {
        .page-footer {
            min-height: 58px !important;
            /* height: 58px; */

        }
    }

    @media (min-width: 768px) {
        .col-md-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
        .copyright-footer-text-mob {
            display: none;
        }
        .footercomponent{
            position: fixed;
        }

        .copyright-footer-text-desktop {
            display: block;
            padding-left: 20px;
        }
    }

    @media (min-width: 992px) {
        .col-lg-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }

    @media (min-width: 1200px) {
        .col-xl-20 {
            -webkit-box-flex: 0;
            -webkit-flex: 0 0 20%;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
    @media (max-width: 767px){
        .footercomponent{
            position: absolute;
        }
    }
</style>