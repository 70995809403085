<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        :width="1000"
        :height="650"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;">
                <div class="v-modal-header">
                    <div></div>
                    <div>
                        <span class="v-modal-dialog-title v-modal-title">SHARE CONTACT</span>
                    </div>
                    <div>
                        <span class="icon icon-close mr-2 text-white pointer close-left" @click="hideSelf"></span>
                    </div>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <div class="d-flex flex-column justify-content-center align-items-center">
                            <span class="fs-14 my-6">Share the contact of <span class="fw-600">{{ full_name }}</span></span>
                            <div class="d-flex mt-4 mb-10">
                                <div class="d-flex">
                                    <span :class="toShow === 'set_details' ? 'ongoingCircle' : 'finishedCircle'" class="mx-10">
                                        1
                                        <span class="finishedCircleText">
                                            Select the details
                                        </span>
                                    </span>
                                    <span :class="toShow === 'set_details' ? 'unfinishedDash' : 'finishedDash'">
                                        ----------
                                    </span>
                                    <span :class="toShow === 'set_details' ? 'unfinishedCircle' : 'ongoingCircle'" class="mx-10">
                                        2
                                        <span :class="toShow === 'set_details' ? 'unfinishedCircleText' : 'finishedCircleText'">
                                            Set the Permissions
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="toShow == 'set_details'">
                            <div class="d-flex mx-12">
                                <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                    <input type="checkbox" class="custom-control-input" @change="selectAll" />
                                    <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                    <span class="form-check-description fw-600"></span>
                                </label>
                                <span class="ml-2 fw-500 fs-12">Select All</span>
                            </div>
                            <div class="d-flex mx-10 mt-8 overflow-auto">
                                <div class="w-100 mr-8">
                                    <div class="heading p-2">
                                        <div class="d-flex">
                                            <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                <input type="checkbox" class="custom-control-input" @change="selectAllContacts" :checked="list_contacts.length == contactsArray.length" />
                                                <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                                <span class="form-check-description fw-600"></span>
                                            </label>
                                            <span class="ml-2 fw-500 fs-12">Contacts</span>
                                        </div>
                                        <i v-if="contacts" class="icon-chevron-down pointer" @click="showHideContacts"></i>
                                        <i v-else class="icon-chevron-up pointer" @click="showHideContacts"></i>
                                    </div>
                                    <div v-if="contacts" class="d-flex flex-wrap mx-2">
                                        <div class="d-flex options mt-4" v-for="(contact,index) in list_contacts" :key="index">
                                            <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                <input type="checkbox" class="custom-control-input" v-model="contactsArray" :value="contact" />
                                                <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                                <span class="form-check-description fw-600"></span>
                                            </label>
                                            <span class="ml-2 fw-500 fs-12 text-capitalize">{{ contact.split("_")[0] }} {{ contact.split("_")[1] }}</span>
                                        </div>
                                    </div>
                                    <div class="heading p-2 mt-4">
                                        <div class="d-flex">
                                            <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                <input type="checkbox" class="custom-control-input" @change="selectAllAddresses" :checked="addresses.length == addressArray.length" />
                                                <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                                <span class="form-check-description fw-600"></span>
                                            </label>
                                            <span class="ml-2 fw-500 fs-12">Address</span>
                                        </div>
                                        <i v-if="address" class="icon-chevron-down pointer" @click="showHideAddress"></i>
                                        <i v-else class="icon-chevron-up pointer" @click="showHideAddress"></i>
                                    </div>
                                    <div v-if="address" class="d-flex flex-wrap mx-2">
                                        <div class="d-flex options mt-4" v-for="(address,index) in addresses" :key="index">
                                            <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                <input type="checkbox" class="custom-control-input" v-model="addressArray" :value="address" />
                                                <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                                <span class="form-check-description fw-600"></span>
                                            </label>
                                            <span class="ml-2 fw-500 fs-12 text-capitalize">{{ address.split("_")[0] }} {{ address.split("_")[1] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100 ml-8">
                                    <div class="heading p-2">
                                        <div class="d-flex">
                                            <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                <input type="checkbox" class="custom-control-input" @change="selectAllSocials" :checked="socials.length == socialMediaArray.length" />
                                                <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                                <span class="form-check-description fw-600"></span>
                                            </label>
                                            <span class="ml-2 fw-500 fs-12">Social Media</span>
                                        </div>
                                        <i v-if="social" class="icon-chevron-down pointer" @click="showHideSocialMedia"></i>
                                        <i v-else class="icon-chevron-up pointer" @click="showHideSocialMedia"></i>
                                    </div>
                                    <div v-if="social" class="d-flex flex-wrap mx-2">
                                        <div class="d-flex options mt-4" v-for="(social,index) in socials" :key="index">
                                            <label class="custom-control custom-checkbox digi-checkbox" style="width: 10px !important;padding-right: 0px !important;min-width: 10px;">
                                                <input type="checkbox" class="custom-control-input" v-model="socialMediaArray" :value="social" />
                                                <span class="custom-control-indicator" style="font-size: 20px; top: -10px !important;"></span>
                                                <span class="form-check-description fw-600"></span>
                                            </label>
                                            <span class="ml-2 fw-500 fs-12 text-capitalize">{{ social }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="toShow == 'set_permission'">
                            <div class="d-flex justify-content-center align-items-center flex-column mx-20">
                                <span class="fs-14 my-4" style="color: #545555">Set the permission to</span>
                                <div class="grey-bg py-6 px-10 mt-4">
                                    <span class="fs-14 fw-500" style="color: #545555">Get Link</span>
                                    <div class="d-flex align-items-center mt-4">
                                        <multiselect class="diginew-multiselect no-default-icon bg-white" name="userlist" v-validate="'required'" @close="dropdownClosed" :options="teams_list" label="name" :custom-label="customLabelTeams" v-model="teamSelected" @search-change="getTeamsList" track-by="_id" :searchable="true" :close-on-select="true" :loading="is_loading_team" :show-labels="false" :taggable="false" :multiple="true" placeholder="Add people or groups">
                                            <template slot="option" slot-scope="props">
                                                <div class="d-flex align-items-center">
                                                    <div class="option__email d-flex flex-column ml-2">
                                                        <span class="option__title">{{ props.option.name }}</span>
                                                        <span class="option__small" style="display: none;">{{ props.option.email}}</span>
                                                    </div>
                                                </div>
                                            </template>
                                            <template slot="afterList">
                                                <div v-observe-visibility="getMoreTeams" />
                                            </template>
                                            <template slot="noOptions">
                                                <div class="text-secondary text-center fw-600">No Teams Found</div>
                                            </template>
                                            <template slot="noResult">
                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                            </template>
                                        </multiselect>
                                        <div>
                                            <button v-if="shared" class="btn btn-new-primary btn-wide px-1 ml-2" style="height: 42px !important" @click="copyLink">Copy Link</button>
                                            <button v-else class="btn btn-new-disabled btn-wide px-1 ml-2" style="height: 42px !important; background: #ababab !important; color: #fff !important; cursor: not-allowed !important">Copy Link</button>
                                        </div>
                                    </div>
                                    <span class="invalid-feedback-form text-danger" style="text-transform: none !important;" v-show="errors.has('userlist')">
                                      This Field is Required
                                    </span>
                                    <el-dropdown trigger="click">
                                        <div class="d-flex align-items-center bg-white py-2 px-4 mt-4" style="border-radius: 5px">
                                            <div class="user d-flex align-items-center justify-content-center p-2">
                                                <i class="icon-account-multiple text-dark fs-18"></i>
                                            </div>
                                            <div class="d-flex justify-content-between flex-column ml-2">
                                                <div>
                                                    <span class="fs-12 fw-500 d-flex" style="color: #545555">{{ permission_type.name }} <img class="ml-2" src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-black-full.svg" width="10px"> </span>
                                                    <el-dropdown-menu slot="dropdown">
                                                        <el-dropdown-item v-for="(type, index) in permission_types" :key="index">
                                                            <div class="d-flex justify-content-between flex-column mt-2" @click="permission_type = type">
                                                                <span class="fs-12 fw-500" style="color: #545555">{{ type.name }}</span>
                                                                <span class="fs-12" style="color: rgba(103, 103, 103, 0.84)">{{ type.description }}</span>
                                                            </div>
                                                        </el-dropdown-item>
                                                    </el-dropdown-menu>
                                                </div>
                                                <span class="fs-12" style="color: rgba(103, 103, 103, 0.84)">{{ permission_type.description }}</span>
                                            </div>
                                        </div>
                                    </el-dropdown>
                                </div>
                                <div class="grey-bg d-flex align-items-center px-10 py-8 mt-4">
                                    <span class="fs-14 fw-500" style="color: #545555">Share with people or groups</span>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center align-items-center mt-8 mb-6">
                            <button v-if="toShow == 'set_details' && !shared" class="btn btn-new-primary-bordered btn-wide px-6" @click="hideSelf">Cancel</button>
                            <button v-if="toShow == 'set_permission' && !shared" class="btn btn-new-primary-bordered btn-wide px-4" @click="toShow = 'set_details'">Previous</button>
                            <button v-if="toShow == 'set_details' && !shared" class="btn btn-new-primary btn-wide px-8 ml-4" @click="toShow = 'set_permission'">Next</button>
                            <button v-if="toShow == 'set_permission' && !shared" class="btn btn-new-primary btn-wide px-8 ml-4" @click="shareContact">Share</button>
                            <button v-if="shared" class="btn btn-new-primary btn-wide px-6" @click="hideSelf">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import globals from "./globals";
export default {
    data() {
        return {
            toShow: "set_details",
            contacts: true,
            address: true,
            social: true,
            list_contacts: ["primary_email", "business_phone", "mobile_number", "alternate_email"],
            addresses: ["mailing_address", "work_address", "home_address", "billing_address"],
            socials: ["facebook", "whatsapp", "twitter", "wechat", "reddit", "snapchat", "pinterest", "github", "linkedin", "hike", "skype", "trello", "hangout", "signal"],
            contactsArray: [],
            addressArray: [],
            socialMediaArray: [],
            teams_list: [],
            teamSelected: [],
            permission_type: {
                name: "Only View",
                description: "Anyone with this link can only View the Contact",
            },
            permission_types: [
                {
                    name: "Only View",
                    description: "Anyone with this link can only View the Contact",
                },
                {
                    name: "View and Edit",
                    description: "Anyone with this link can Edit and  View the Contact ",
                },
            ],
            search_key: "",
            limit: 10,
            skip: 0,
            shared: false,
            skip_count: 0,
            is_loading_team: false
        }
    },
    props: ["modal_name", "id", "full_name", "sharing_id"],
    methods: {
        customLabelTeams(option) {
             return `${option.email} - ${option.name}`
        },
        hideSelf() {
            this.toShow = "set_details";
            this.contacts = true;
            this.address = true;
            this.social = true;
            this.contactsArray = [];
            this.addressArray = [];
            this.socialMediaArray = [];
            this.teams_list = [];
            this.teamSelected = [];
            this.permission_type = {
                name: "Only View",
                description: "Anyone with this link can only View the Contact",
            };
            this.search_key = "";
            this.limit = 10;
            this.skip = 0;
            this.shared = false
           this.$modal.hide(this.modal_name);
           this.$emit('contactSharedSuccess');
        },
        showHideContacts() {
            if (this.contacts) {
                this.contacts = false;
            } else {
                this.contacts = true;
            }
        },
        showHideAddress() {
            if (this.address) {
                this.address = false;
            } else {
                this.address = true;
            }
        },
        showHideSocialMedia() {
            if (this.social) {
                this.social = false;
            } else {
                this.social = true;
            }
        },
        selectAllContacts() {
            if (this.contactsArray.length == this.list_contacts.length) {
                this.contactsArray = [];
            } else {
                this.list_contacts.forEach(x => {
                    if (!this.contactsArray.includes(x)) {
                        this.contactsArray.push(x);
                    }
                });
            }
        },
        selectAllAddresses() {
            if (this.addressArray.length == this.addresses.length) {
                this.addressArray = [];
            } else {
                this.addresses.forEach(x => {
                    if (!this.addressArray.includes(x)) {
                        this.addressArray.push(x);
                    }
                });
            }
        },
        selectAllSocials() {
            if (this.socialMediaArray.length == this.socials.length) {
                this.socialMediaArray = [];
            } else {
                this.socials.forEach(x => {
                    if (!this.socialMediaArray.includes(x)) {
                        this.socialMediaArray.push(x);
                    }
                });
            }
        },
        selectAll() {
            if (this.contactsArray.length + this.addressArray.length + this.socialMediaArray.length == this.list_contacts.length + this.addresses.length + this.socials.length) {
                this.contactsArray = [];
                this.addressArray = [];
                this.socialMediaArray = [];
            } else {
                this.list_contacts.forEach(x => {
                    if (!this.contactsArray.includes(x)) {
                        this.contactsArray.push(x);
                    }
                });
                this.addresses.forEach(x => {
                    if (!this.addressArray.includes(x)) {
                        this.addressArray.push(x);
                    }
                });
                this.socials.forEach(x => {
                    if (!this.socialMediaArray.includes(x)) {
                        this.socialMediaArray.push(x);
                    }
                });
            }
        },
        copyLink() {
            let tempInput = document.createElement("input");
            tempInput.value = globals.CRM_URL + "/contacts/sharing/linked_contact?id=" + this.id;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
            this.$emit("linkcopied");
        },
        dropdownClosed() {
            this.search_key = "";
            this.skip_count = 0;
        },
        async getTeamsList(key) {
            this.is_loading_team = true;
            if (key) {
                this.search_key = key;
            }
            let params = {
                skip: 0,
                limit: 10,
                search_key: this.search_key ? this.search_key : '',
            };
            let res = await this.$http.post("/teams/get_all_teams_and_users", params);
            if (res.data.status_id == 1) {
                this.teams_list = res.data.response;
                this.teams_list = this.teams_list.filter((thing, index) => {
                    const payload = JSON.stringify(thing);
                    return index === this.teams_list.findIndex(obj => {
                        return JSON.stringify(obj) === payload;
                    });
                });
                this.is_loading_team = false;
                this.skip_count = res.data.response.length
            }
        },
        async getMoreTeams(reached) {
            if (reached) {
                this.is_loading_team = true;
                let params = {
                    skip: this.skip_count,
                    limit: 10,
                    search_key: this.search_key,
                };
                let res = await this.$http.post("/teams/get_all_teams_and_users", params);
                if (res.data.skip == this.skip_count) {
                    let tempFiles = res.data.response;
                    tempFiles.forEach(el => {
                        this.teams_list.push(el);
                    });
                    this.teams_list = this.teams_list.filter((thing, index) => {
                        const payload = JSON.stringify(thing);
                        return index === this.teams_list.findIndex(obj => {
                            return JSON.stringify(obj) === payload;
                        });
                    });
                    this.skip_count += res.data.response.length;
                    this.is_loading_team = false;
                }
            }
        },
        shareContact() {
            this.$validator.validateAll().then(result => {
                if (result) {
                    this.shareContactConfirm();
                }
            });
        },
        async shareContactConfirm() {
            let teams = [];
            let users = [];
            this.teamSelected.forEach(x => {
                if (x.hasOwnProperty("teams")) {
                    teams.push(x._id);
                } else {
                    users.push(x._id);
                }
            });
            let params = {
                accessibility: this.permission_type.name == "Only View" ? "read" : "write",
                contact: this.contactsArray,
                address: this.addressArray,
                social_media: this.socialMediaArray,
                contact_id: this.$route.params.id,
                teams: teams,
                users: users,
            };
            let res = await this.$http.post("/contacts/sharing/add", params);
            if (res.data.status_id == 1) {
                this.shared = true;
            }
        },
        async getSharingData() {
            let res = await this.$http.get("/contacts/sharing/get_by_id?id="+this.sharing_id);
            if (res.data.status_id == 1) {
                this.addressArray = res.data.response[0].address;
                this.contactsArray = res.data.response[0].contact;
                this.socialMediaArray = res.data.response[0].social_media;
                this.teamSelected = res.data.response[0].user_details;
            }
        }
    },
    watch: {
        sharing_id: function() {
            if (this.sharing_id) {
                this.getSharingData();
            }
        }
    }
}
</script>

<style scoped>
    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }
    .ongoingCircle {
        background: #ff9a06;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 500;
    }
    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }
    .finishedCircleText {
        position: absolute;
        font-size: 12px;
        color: #000000;
        margin-top: 5em;
    }
    .unfinishedCircleText {
        position: absolute;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 5em;
        width: 10em;
    }
    .unfinishedDash {
        color: #3f3f3f;
        font-size: 16px;
    }
    .finishedDash {
        color: #128807;
        font-size: 16px;
    }
    .heading {
        background: #f5f5f5;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .heading span,
    .heading i {
        color: #0475f3;
    }
    .options {
        flex: 0 50%;
    }
    .grey-bg {
        background: #f2f2f2;
        border-radius: 5px;
        width: 100%;
    }
    .user {
        background: #f5f5f5;
        border-radius: 50%;
    }
</style>