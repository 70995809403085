<template>
    <div class="cr-backimg" style="background: url('https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/login_new.svg') left center no-repeat; background-size: cover; height: 100%; background-color: #fff; overflow: auto;transition:0.5s ease all">
        <div class="d-flex flex-column" style="width: 100%; min-height: 95%; height: 100%;">
            <div class="top">
                <span class="dc dc-new" style="font-size: 25px; font-weight: 600;">DigiCollect</span>
                <span class="text-default-blue crm-right-logo" style="font-size: 25px;"><img src="/static/images/admin_icon_blue.svg" alt="crm" style="width: 40px; height: 40px;"> DigiAdmin</span>
            </div>
            <div class="middle d-flex px-20" style="padding-top: 4%;">
                <div class="middle-left"></div>
                <div v-if="show_success_message" class="middle-right d-flex flex-column py-10" style="text-align: center;">
                    <div class="mb-10">
                        <img style="width: 190px;" src="/static/images/parrot.svg" alt="parrot" />
                    </div>
                    <p class="text-default-heading text-center mb-5" style="font-size: 30px; font-weight: 700;">Success</p>
                    <p class="text-default-heading text-center mb-10" style="font-size: 18px;">Your Account has been Verified Successfully</p>
                    <span class="text-center">
                        <button class="btn btn-new-primary mb-5" @click="goToLogin">Login</button>
                    </span>
                </div>
                <div v-if="show_failure_message" class="middle-right d-flex flex-column py-10" style="text-align: center;">
                    <div class="mb-10">
                        <img style="width: 190px;" src="https://cdn.digicollect.com/cdn/dc_helpdesk_new/images/dog.svg" alt="parrot" />
                    </div>
                    <p class="text-default-heading text-danger mb-10" style="font-size: 18px;">Verification Link is not Valid or Expired</p>
                    <span class="text-center">
                        <button class="btn btn-new-primary mb-5" @click="goToLogin">Login</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import globals from './globals';
import auth from './mixins/auth';
export default {
    mixins:[auth],
    data() {
        return {
            user_id: '',
            secret: '',
            link_for: '',
            show_success_message: false,
            show_failure_message: false
        }
    },
    components : {
    },
    methods: {
        goToLogin() {
            this.$cookie.delete("Token");
            this.$cookie.delete('Token', {
                domain: 'digicollect.com'
            });
            this.$cookie.delete('Token', {
                domain: 'testadmin.digicollect.com'
            });
            window.location.href = globals.ADMIN_URL;
        },
        async verifyUser() {
            let params = {
                user_id: this.user_id,
                secret_key: this.secret,
                link_for: this.link_for,
            }
            await this.verifyUserFromLink(params).then((response)=>{
                if(response.status_id == 1 && response.reason == 'Invalid Verification Link!') {
                    this.show_failure_message = true;
                    this.show_success_message = false;
                } else {
                    this.show_failure_message = false;
                    this.show_success_message = true;
                }
            }).catch((err)=>{
                this.show_failure_message = true;
                this.show_success_message = false;
            });
        }
    },
    mounted() {
        if(this.$cookie.get("Token") && sessionStorage.getItem('session_id')) {
            this.$router.push("/");
        } 
        this.user_id = this.$route.query.user_id;
        this.secret = this.$route.query.secret;
        this.link_for = this.$route.query.link_for;
        this.verifyUser();
    },
    created() {
        
    },
}
</script>

<style scoped>
    .image-holder {
        width: 500px;
        background: #fff;
        height: 500px;
        border-radius: 50%;
        bottom: -90px;
        position: absolute;
        left: -60px;
    }
    .group-image {
        width: 350px;
        height: 350px;
        right: 60px;
        position: absolute;
        top: 60px;
    }
    .top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-left: 60px;
        padding-right: 60px;
    }
    .middle-left {
        width: 50%;
    }
    .middle-right {
        width: 50%;
    }
    .star {
        font-size: 12px;
        color: #F44336;
    }
    @media (max-width: 1180px) {
        .middle {
            margin-top: 30px;
        }
        .middle-left {
            display: none;
        }
        .middle-right {
            width: 100%;
            max-height: 100%;
            justify-content: center;
        }
        .cr-backimg {
            background: #FFF !important;
        }
        .dc-new {
            display: block;
        }
        .top {
            justify-content: center;
            flex-direction: column;
        }
    }
    @media screen and (min-width: 1181px) {
        .crm-right-logo {
            margin-left: auto;
        }
        .dc-new {
            display: none;
        }
    }
    @media (max-width: 740px) {
        .middle {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .middle-right {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }
    #password{
        transition: 0.5s ease all;
    }
</style>