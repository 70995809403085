<template>
    <div>
        <modal
        class="new-modal-default"
        :name="modal_name"
        transition="nice-modal-fade"
        :delay="100"
        :width="400"
        :height="350"
        :pivot-y="0.5"
        :adaptive="true"
        :scrollable="true"
        style="pointer-events:inherit !important" 
        v-bind:click-to-close="false"
        >
            <div class="v-modal-content" style="background-color: #ffffff !important;">
                <div class="v-modal-body">
                    <div class="v-modal-layout p-6">
                        <div class="text-center">
                            <div class="mb-5">
                                <img style="width: 80.3px;height: 90.6px" src="/static/images/parrot.svg" alt="parrot" />
                            </div>
                            <h1 class="fs-15 mb-6" style="font-weight:500;color: #303031;">Protect your account with 2-Step Verification</h1>
                            <div class="mb-4">
                                <span>step 1 verification- Enter Password <br>step 2 verification- Enter OTP</span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center">
                                <button type="button" class="btn btn-new-outline-primary btn-smm" style="min-width: 132px; text-transform: none !important;line-height: 20px !important; border-radius: 4px !important;" @click="askMeLater">Ask me Later</button>
                                <button type="button" class="btn btn-new-secondary ml-6" style="min-width: 132px; text-transform: none !important; border-radius: 4px !important;" :disabled='btn_disabled' @click="enableTwoFactor">I Accept</button>
                            </div>
                            <div class="mt-2" v-if="show_success_text">
                                <span class="text-secondary fs-14">{{show_success_text}}</span>
                            </div>
                            <div class="mt-2" v-if="show_error_text">
                                <span class="text-danger fs-14">{{show_error_text}}</span>
                            </div>
                            <div class="mt-6">
                                <h2 class="fs-12 text-new-blue">*Configurations can be updated in settings</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import globals from '../globals';
export default {
    props: ["modal_name"],
    data() {
        return {
            show_success_text: '',
            show_error_text: '',
            btn_disabled: false
        }
    },
    methods: {
        async enableTwoFactor() {
            this.btn_disabled = true;
            let params = {
                login_id: this.loggedInUser.login_id,
                is_two_step_verification: true
            }
            let response = await this.$http.post(globals.AUTH_SERVICE + "/registration/set_user_settings", params);
            if(response.data.status_id == 1) {
                this.show_success_text = "2-Step Verification Activated Successfully.";
                this.show_error_text = "";
                setTimeout(() => {
                    this.$modal.hide(this.modal_name);
                    this.$emit('hideTwoStepVerification');
                }, 2000);
            }
            else {
                this.show_success_text = "";
                this.show_error_text = "Please try again";
                this.btn_disabled = false;
            }
        },
        askMeLater() {
            this.$modal.hide(this.modal_name);
            this.$emit('hideTwoStepVerification');
        }
    },
    created() {
        
    },
    computed: {
      loggedInUser() {
        return this.$store.getters.loggedInUser;
      },
    },
}
</script>

<style scoped>
    
</style>