<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div>
        <div class="tabs-content-secondblock">
            <div class="row">
                <div class="col-lg-12">
                    <div class="pt-4 pl-4 pr-3" style="text-align:right;">
                        <img class="pointer" width="25" @click="goBack()" src="/static/images/back_page_arrow.svg">
                    </div>
                    <div class="ml-2 mr-1 mb-3">
                        <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row">
                        <!-- <div class="row"> -->
                            <div class="col-lg-12">
                                <div class="infoHeader px-3 pb-4 mt-6">
                                    <div class="create-company-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white">Branch {{this.$route.query.branch_number}}</span>
                                        <span class="pointer" @click="expandTabs('branch_info')">
                                            <i class="text-white" :class="{'icon-chevron-double-down':branch_info_expand, 'icon-chevron-double-up':!branch_info_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="branch_info_expand" style="padding: 0 10px;">
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Assign as Headquarter </div>
                                                <div class="w-100 ml-2" style="position: relative;">
                                                    <el-switch
                                                        v-model="assign_as_headquarter"
                                                        @change="setAsHeadquarter"
                                                        :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                        active-color="#00448b"
                                                        inactive-color="#dcdfe6">
                                                    </el-switch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Branch Name</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_branch_name" >
                                                        <div class="pointer" @click="resetValue('branch_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('branch_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='branch_name' class="input-field-underline" maxlength="100" @focus="editUser('branch_name')" style="padding-top: 6px !important;" :readonly="!edit_branch_name" v-model="edit_user_data.branch_name">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_branch_name" class="text-right">
                                                        <span class="input-field-length">{{ (edit_user_data.branch_name).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Branch Code</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_branch_code" >
                                                        <div class="pointer" @click="resetValue('branch_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('branch_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='branch_code' class="input-field-underline" maxlength="100" @focus="editUser('branch_code')" style="padding-top: 6px !important;" :readonly="!edit_branch_code" v-model="edit_user_data.branch_code">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_branch_code" class="text-right">
                                                        <span class="input-field-length">{{ (edit_user_data.branch_code).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">
                                                    Date of Incorporation
                                                </div>
                                                <div class="w-100" >
                                                    <div style="position: relative; width: 230px;">
                                                        <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="z-index: 2;right: -40px;bottom: 10px;" v-if="edit_date_of_incorporation" >
                                                            <div class="pointer" @click="resetValue('date_of_incorporation')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('date_of_incorporation')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div>
                                                        <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                                content="Click to edit"
                                                                placement="top"> -->
                                                        <date-picker :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="profile-date-picker calendar-edit-per input-field-underline pt-0 pb-0 pr-0" @focus="editUser('date_of_incorporation')" v-model="edit_user_data.date_of_incorporation" value-type="format" lang="en" :popup-style="{ top: '100%', left: 0}" :append-to-body="false" 
                                                        :clearable="false"
                                                        :editable="false"
                                                        :not-after="new Date()" type="date" format="DD/MM/YYYY"></date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Number of Employees</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_no_of_employees" >
                                                        <div class="pointer" @click="resetValue('no_of_employees')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('no_of_employees')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                        <input name='no_of_employees' maxlength="8" v-validate="'numeric'" class="input-field-underline" @focus="editUser('no_of_employees')" style="padding-top: 6px !important;" :readonly="!edit_no_of_employees" v-model.number="edit_user_data.no_of_employees">
                                                    <!-- </el-tooltip> -->
                                                    <span class="invalid-feedback-form text-danger" v-show="errors.has('no_of_employees')">
                                                        {{errors.first('no_of_employees')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Time Zone</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_time_zone" >
                                                        <div class="pointer" @click="resetValue('time_zone')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('time_zone')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_time_zone" class="mr-12">
                                                        <multiselect class="diginew-multiselect diginew-multiselect-underline" name="time_zone" v-validate="'required'" label="label" :show-labels="false" :searchable="true" :options="time_zone_options" v-model="edit_user_data.time_zone" placeholder="Select Time Zone">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                            <input v-if="!edit_time_zone" class="input-field-underline" @click="editUser('time_zone')" style="padding-top: 6px !important;" v-model="edit_user_data.time_zone.label">
                                                        <!-- <div class="input-field-underline" @click="editUser('time_zone')">
                                                            {{edit_user_data.time_zone.label}}
                                                        </div> -->
                                                    <!-- </el-tooltip> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-company-header d-flex align-items-center justify-content-between mt-4">
                                        <span class="fs-14 text-white">Communication Information</span>
                                        <span class="pointer" @click="expandTabs('communication_info')">
                                            <i class="text-white" :class="{'icon-chevron-double-down':communication_info_expand, 'icon-chevron-double-up':!communication_info_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="communication_info_expand" style="padding: 0 10px;">
                                        <div class="mt-4">
                                            <div class="d-flex align-items-center new-contactView pb-2">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Branch E-mail</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_branch_email" >
                                                        <div class="pointer" @click="resetValue('branch_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('branch_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='branch_email' v-validate="'email'" maxlength="100" class="input-field-underline" @focus="editUser('branch_email')" style="padding-top: 6px !important;" :readonly="!edit_branch_email" v-model="edit_user_data.branch_email">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center position-absolute" >
                                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_email')">
                                                            Enter valid email address
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(alt_email, alt_email_index) in edit_user_data.alternate_email" :key="alt_email_index" class="mt-4">
                                            <div class="d-flex align-items-center new-contactView pb-2">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate Email {{alt_email_index+1}}</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_email && (alt_email_index === alt_email_index_selected)" >
                                                        <div class="pointer" @click="resetValue('alternate_email', alt_email_index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('alternate_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <!-- <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input :name="'alternate_email'+alt_email_index" v-validate="'email'" maxlength="100" data-vv-as="Alternate Email" class="input-field-underline" @focus="editUser('alternate_email', alt_email_index)" style="padding-top: 6px !important;" :readonly="!edit_alternate_email" v-model="edit_user_data.alternate_email[alt_email_index]">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center position-absolute" >
                                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('alternate_email'+alt_email_index)">
                                                            {{errors.first('alternate_email'+alt_email_index)}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <div class="d-flex align-items-center new-contactView pb-2">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Branch Number</div>
                                                <div v-if="edit_branch_number" style="width: 128px !important;">
                                                    <multiselect v-model="edit_user_data.branch_number_type"
                                                        class="diginew-multiselect diginew-multiselect-underline pointer" style="width: 128px !important;" id="ajax"
                                                        :options="['Home','Work','Mobile','Fax','Others','Whatsapp','Messenger','Telegram','Line']"
                                                        :multiple="false" :searchable="true" :internal-search="true"
                                                        :disabled="false"
                                                        :show-labels="false"
                                                        :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                        :max-height="200" :show-no-results="true" :hide-selected="false">
                                                        <template slot="option" slot-scope="{ option }">
                                                            <div class="d-flex flex-row">
                                                                <span class="ml-2">
                                                                    <span style="font-size: 12px">{{ option }}</span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_branch_number" >
                                                        <div class="pointer" @click="resetValue('branch_number')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('branch_number')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_branch_number" >
                                                        <vue-tel-input
                                                            name="branch_number"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                            placeholder="Enter your Phone Number"
                                                            :defaultCountry="edit_user_data.branch_number.country_code"
                                                            @onInput="branchPhone($event)"
                                                            v-validate="{min:10}"
                                                            v-model="edit_user_data.branch_number.phone_number">
                                                        </vue-tel-input>
                                                    </div>
                                                    <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                            <input v-if="!edit_branch_number" class="input-field-underline" @click="editUser('branch_number')" style="padding-top: 6px !important;" v-model="edit_user_data.branch_number.phone_number">
                                                        <!-- <div class="input-field-underline" @click="editUser('branch_number')">
                                                            {{edit_user_data.branch_number.phone_number}}
                                                        </div> -->
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center position-absolute" >
                                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('branch_number')">
                                                            {{errors.first('branch_number')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-for="(alt_phone, alt_phone_index) in edit_user_data.alternate_phone" :key="alt_phone_index+'alt_phone'" class="mt-4">
                                            <div class="d-flex align-items-center new-contactView pb-2">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate Phone Number {{alt_phone_index+1}}</div>
                                                <div v-if="edit_alternate_phone && (edit_alternate_phone_index == alt_phone_index)" style="width: 128px !important;">
                                                    <multiselect v-model="alt_phone.phone_type"
                                                        class="diginew-multiselect diginew-multiselect-underline pointer" style="width: 128px !important;" id="ajax"
                                                        :options="['Home','Work','Mobile','Fax','Others','Whatsapp','Messenger','Telegram','Line']"
                                                        :multiple="false" :searchable="true" :internal-search="true"
                                                        :disabled="false"
                                                        :show-labels="false"
                                                        :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                        :max-height="200" :show-no-results="true" :hide-selected="false">
                                                        <template slot="option" slot-scope="{ option }">
                                                            <div class="d-flex flex-row">
                                                                <span class="ml-2">
                                                                    <span style="font-size: 12px">{{ option }}</span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_phone && (edit_alternate_phone_index == alt_phone_index)" >
                                                        <div class="pointer" @click="resetValue('alternate_phone', alt_phone_index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('alternate_phone', alt_phone_index)">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_alternate_phone && (edit_alternate_phone_index == alt_phone_index)" >
                                                        <vue-tel-input
                                                            :name="'alternate_phone'+alt_phone_index"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                            placeholder="Enter your alternate Phone Number"
                                                            @onInput="branchAltPhone($event, alt_phone_index)"
                                                            :defaultCountry="alt_phone.country_code"
                                                            data-vv-as="Alternate Phone"
                                                            v-validate="{min:10}"
                                                            v-model="alt_phone.phone_number">
                                                        </vue-tel-input>
                                                    </div>
                                                    <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                            <input v-if="!edit_alternate_phone" class="input-field-underline" @click="editUser('alternate_phone', alt_phone_index)" style="padding-top: 6px !important;" v-model="alt_phone.phone_number">
                                                        <!-- <div class="input-field-underline" @click="editUser('alternate_phone', alt_phone_index)">
                                                            {{alt_phone.phone_number}}
                                                        </div> -->
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center position-absolute" >
                                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('alternate_phone'+alt_phone_index)">
                                                            {{errors.first('alternate_phone'+alt_phone_index)}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Website</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_website" >
                                                        <div class="pointer" @click="resetValue('website')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('website')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='website' v-validate="'url'" maxlength="100" class="input-field-underline" @focus="editUser('website')" style="padding-top: 6px !important;" :readonly="!edit_website" v-model="edit_user_data.website">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center" >
                                                        <span class="invalid-feedback-form text-danger" v-show="errors.has('website')">
                                                            Enter valid url
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-company-header d-flex align-items-center justify-content-between mt-4">
                                        <span class="fs-14 text-white">Branch Address</span>
                                        <div  class="d-flex align-items-center">
                                            <div v-if="edit_full_address == true" class="d-flex justify-content-end align-items-center mr-4">
                                                <!-- <div class="pointer" @click="resetValue('full_address_add_update')">
                                                    <img width="20" style="width: 20px;" src="/static/images/cross_new.svg">
                                                </div>
                                                <div class="ml-2 pointer" @click="updateInfo('full_address_add_update')">
                                                    <img width="20" style="width: 20px;" src="/static/images/check_new.svg">
                                                </div> -->
                                            </div>
                                            <div v-else>
                                                <el-tooltip v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="item" effect="dark" content="Edit" placement="top">
                                                    <button class="btn-icon pointer mr-4" @click="editFullAddress" style="padding:0px!important;">
                                                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/edit-white.svg" height="15px;">
                                                    </button>
                                                </el-tooltip>
                                            </div>
                                            <span class="pointer" @click="expandTabs('branch_address')">
                                                <i class="text-white" :class="{'icon-chevron-double-down':branch_address_expand, 'icon-chevron-double-up':!branch_address_expand}" style="font-size: 20px;"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="branch_address_expand" class="mt-8" style="padding: 0 10px;">
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Location </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-between align-items-center mt-1 px-3">
                                                        <div class="gMap" style="position:relative;width:100%;">
                                                            <gmap-autocomplete
                                                                class="inputFieldNoBorder mt-0"
                                                                id="autocompleteInput"
                                                                name="get_location"
                                                                v-validate="'required'"
                                                                placeholder="Search Location"
                                                                :disabled="!edit_full_address"
                                                                @place_changed="setPlace($event)"
                                                                :value="address.location_vmodel"
                                                                v-model="address.location_vmodel"
                                                                style="padding: 8px 32px 8px 18px !important; height: 40px;"
                                                            ></gmap-autocomplete>
                                                            <img
                                                                src="/static/images/marker_pin_new.svg"
                                                                style="position: absolute;right: 12px;bottom: 10px;"
                                                            />
                                                        </div>
                                                        <div class="gMap ml-4" :class="{'pointer-events-none':!edit_full_address}" style="cursor: pointer;" @click="getLocation()">
                                                            <img src="/static/images/gps_new.svg" style="height: 40px;" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Building No.</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_building_no" >
                                                        <div class="pointer" @click="resetValue('building_no')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('building_no')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='building_no' class="input-field-underline" @focus="editUser('building_no')" maxlength="100" style="padding-top: 6px !important;" :readonly="!edit_building_no" v-model="address.building_no">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_building_no" class="text-right">
                                                        <span class="input-field-length">{{ (address.building_no).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Street 1</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_street1" >
                                                        <div class="pointer" @click="resetValue('street1')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('street1')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='street1' class="input-field-underline" @focus="editUser('street1')" style="padding-top: 6px !important;" maxlength="50" :readonly="!edit_street1" v-model="address.street1">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_street1" class="text-right">
                                                        <span class="input-field-length">{{ (address.street1).length }}/50</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Street 2</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_street2" >
                                                        <div class="pointer" @click="resetValue('street2')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('street2')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='street2' class="input-field-underline" @focus="editUser('street2')" maxlength="50" style="padding-top: 6px !important;" :readonly="!edit_street2" v-model="address.street2">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_street2" class="text-right">
                                                        <span class="input-field-length">{{ (address.street2).length }}/50</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">City </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" v-if="edit_city" >
                                                        <div class="pointer" @click="resetValue('city')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('city')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='city' class="input-field-underline" @focus="editUser('city')" maxlength="100" style="padding-top: 6px !important;" :readonly="!edit_city" v-model="address.city">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_city" class="text-right">
                                                        <span class="input-field-length">{{ (address.city).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Town </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_town" >
                                                        <div class="pointer" @click="resetValue('town')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('town')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='town' class="input-field-underline" maxlength="100" @focus="editUser('town')" style="padding-top: 6px !important;" :readonly="!edit_town" v-model="address.town">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_town" class="text-right">
                                                        <span class="input-field-length">{{ (address.town).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">State </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_state" >
                                                        <div class="pointer" @click="resetValue('state')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('state')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_state" style="margin-right: 50px !important;">
                                                        <multiselect
                                                            :max-height="200"
                                                            name="state"
                                                            class="diginew-multiselect diginew-multiselect-underline mt-1"
                                                            v-model="address.state"
                                                            @open="updateState(address.country)"
                                                            :options="states"
                                                            :disabled="false"
                                                            @close="stateClose(address.state)"
                                                            :searchable="true"
                                                            :close-on-select="true"
                                                            :show-labels="false"
                                                            openDirection="top"
                                                            :loading="isState"
                                                            data-vv-scope="userData"
                                                            :placeholder="address.country ? 'Select State' : 'Select Country to view States'"
                                                        >
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                            <input v-if="!edit_state" name='state' class="input-field-underline" @focus="editUser('state')" style="padding-top: 6px !important;" :readonly="!edit_state" v-model="address.state">
                                                        <!-- <div @click="editUser('state')" class="input-field-underline">
                                                            {{address.state}}
                                                        </div> -->
                                                    <!-- </el-tooltip> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Country </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_country" >
                                                        <div class="pointer" @click="resetValue('country')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('country')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_country" style="margin-right: 50px !important;">
                                                        <multiselect
                                                            :max-height="200"
                                                            name="country"
                                                            class="diginew-multiselect diginew-multiselect-underline mt-1"
                                                            v-model="address.country"
                                                            @input="address.state = '', address.city = '',address.street1 = '',address.town = '',address.zipcode = '',address.location_vmodel = ''"
                                                            :options="countries"
                                                            @close="countryClose(address.country)"
                                                            :disabled="false"
                                                            openDirection="top"
                                                            :searchable="true"
                                                            :close-on-select="true"
                                                            v-validate="'required'"
                                                            :show-labels="false"
                                                            data-vv-scope="Country"
                                                            placeholder="Select Country"
                                                        >
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                            <input v-if="!edit_country" name='country' class="input-field-underline" @focus="editUser('country')" style="padding-top: 6px !important;" :readonly="!edit_country" v-model="address.country">
                                                        <!-- <div @click="editUser('country')" class="input-field-underline">
                                                            {{address.country}}
                                                        </div> -->
                                                    <!-- </el-tooltip> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-5">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Zip Code </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_zipcode" >
                                                        <div class="pointer" @click="resetValue('zipcode')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('zipcode')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                    <input name='zipcode' class="input-field-underline" @focus="editUser('zipcode')" maxlength="100" style="padding-top: 6px !important;" :readonly="!edit_zipcode" v-model="address.zipcode">
                                                    <!-- </el-tooltip> -->
                                                    <div v-if="edit_zipcode" class="text-right">
                                                        <span class="input-field-length">{{ (address.zipcode).length }}/100</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center align-items-center my-6">
                        <button class="btn btn-outline-secondary" @click="cancel" style="width: 120px;border-radius: 5px;">Cancel</button>
                        <button class="btn btn-new-success ml-5" @click="updateData" style="width: 120px;border-radius: 5px;">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
    import moment from "moment";
    import {
        HalfCircleSpinner
    } from "epic-spinners";
    import DatePicker from 'vue2-datepicker'
    import countries from "../../json/countries.json";
    import timezone from '../../json/timezone.json';
    import Element from 'element-ui'
    import axios from 'axios';
    import { SweetModal } from 'sweet-modal-vue';
    import companies from '../../mixins/companies';
import globals from '../../globals';
    export default {
        props: [],
        components:{
            DatePicker,
            Element,
            HalfCircleSpinner,
            SweetModal
        },
        data() {
            return {
                assign_as_headquarter: false,
                warning_msg: '',
                success_msg: '',
                edit_user_data: {
                    branch_name: '',
                    no_of_employees: 0,
                    branch_code: '',
                    date_of_incorporation: '',
                    time_zone: '',
                    job_description: '',
                    branch_email: '',
                    alternate_email: [],
                    branch_number: {},
                    alternate_phone: [],
                    website: '',
                    branch_number_type: '',
                    alternate_phone_number_type: ''
                },
                countries: countries,
                address: {
                    building_no: '',
                    street1: '',
                    street2: '',
                    city: '',
                    town: '',
                    state: '',
                    country: '',
                    zipcode: '',
                    location_vmodel: ""
                },
                isState: false,
                states: [],
                alt_email_index_selected: 0,
                edit_branch_name: false,
                edit_branch_code: false,
                edit_date_of_incorporation: false,
                edit_building_no: false,
                edit_no_of_employees: false,
                edit_full_address: false,
                edit_time_zone: false,
                edit_street1: false,
                edit_street2: false,
                edit_city: false,
                edit_town: false,
                edit_state: false,
                edit_country: false,
                edit_zipcode: false,
                edit_city: false,
                edit_branch_email: false,
                edit_branch_number: false,
                edit_alternate_phone: false,
                edit_alternate_phone_index: 0,
                edit_alternate_email: false,
                edit_website: false,
                ajax_call_in_progress: false,
                no_of_employees_options: [],
                branch_info_expand: true,
                branch_address_expand: true,
                communication_info_expand: true,
                branch_data: [],
                time_zone_options: timezone
            };
        },
        mixins: [companies],
        methods: {
            cancel(index){
                let branch_data = JSON.parse(localStorage.getItem('branch_info'));
                    this.edit_full_address = false;
                    this.edit_country = false;
                    this.edit_state = false;
                    this.edit_city = false;
                    this.edit_building_no = false;
                    this.edit_town = false;
                    this.edit_street1 = false;
                    this.edit_street2 = false;
                    this.edit_zipcode = false;
                    this.address.country = branch_data == undefined || null ? '' : branch_data.country
                    this.address.state = branch_data == undefined || null ? '' : branch_data.state
                    this.address.building_no = branch_data == undefined || null ? '' : branch_data.building_no
                    this.address.city = branch_data == undefined || null ? '' : branch_data.city
                    this.address.town = branch_data == undefined || null ? '' : branch_data.township
                    this.address.street1 = branch_data == undefined || null ? '' : branch_data.street1
                    this.address.street2 = branch_data == undefined || null ? '' : branch_data.street2;
                    this.address.zipcode = branch_data == undefined || null ? '' : branch_data.zip_code
                
                        this.edit_branch_name = false;
                        this.edit_user_data.branch_name = branch_data.name == undefined || null ? '' : branch_data.name;
                    
                        this.edit_branch_code = false;
                        this.edit_user_data.branch_code = branch_data.code == undefined || null ? '' : branch_data.code
                   
                        this.edit_date_of_incorporation = false;
                        this.edit_user_data.date_of_incorporation = branch_data.date_of_incorporation == undefined || null ? '' : branch_data.date_of_incorporation.split('-')[2]+'/'+branch_data.date_of_incorporation.split('-')[1]+'/'+branch_data.date_of_incorporation.split('-')[0];
                    
                        this.edit_no_of_employees = false;
                        this.edit_user_data.no_of_employees = branch_data.no_of_employees == undefined || null ? '' : branch_data.no_of_employees;
                   
                        this.edit_building_no = false;
                        this.address.building_no = branch_data.building_no == undefined || null ? '' :  branch_data.building_no;
                   
                        this.edit_street1 = false;
                        this.address.street1 = branch_data.street1 == undefined || null ? '' : branch_data.street1;
                   
                        this.edit_street1 = false;
                        this.address.street2 = branch_data.street2 == undefined || null ? '' : branch_data.street2;
                   
                        this.edit_city = false;
                        this.address.city = branch_data.email == undefined || null ? '' : branch_data.city;
                    
                        this.edit_branch_email = false;
                        this.edit_user_data.branch_email = branch_data.email == undefined || null ? '' : branch_data.email;
                   
                        this.edit_branch_number = false;
                        this.edit_user_data.branch_number = branch_data.phone_number == undefined || null ? '' : branch_data.phone_number;
                   
                        // this.edit_alternate_phone = false;
                        // this.edit_user_data.alternate_phone[index].phone_number = branch_data.alternate_phone[index] == undefined || null ? '' : branch_data.alternate_phone[index].phone_number;
                    
                            this.edit_alternate_email = false;
                            this.edit_user_data.alternate_email[index] = branch_data.alternate_email[index] == undefined || null ? '' : branch_data.alternate_email[index];
                      
                        this.edit_website = false;
                        this.edit_user_data.website = branch_data.website == undefined || null ? '' : branch_data.website;
                    
                        this.edit_state = false;
                        this.address.state = branch_data.state == undefined || null ? '' : branch_data.state;
                   
                        this.edit_town = false;
                        this.address.town = branch_data.township == undefined || null ? '' : branch_data.township;
                   
                        this.edit_country = false;
                        this.address.country = branch_data.country == undefined || null ? '' : branch_data.country;
                   
                        this.edit_zipcode = false;
                        this.address.zipcode = branch_data.zip_code == undefined || null ? '' : branch_data.zip_code;
                    
                        this.edit_time_zone = false;
                        this.edit_user_data.time_zone.label = branch_data.time_zone == undefined || null ? '' : branch_data.time_zone;
                    
                
            },
            async updateData(index) {
                try {
                        let params = {
                        };
                        
                            params.name = this.edit_user_data.branch_name;
                        
                            params.code = this.edit_user_data.branch_code;
                        
                            params.date_of_incorporation = this.edit_user_data.date_of_incorporation ? this.edit_user_data.date_of_incorporation.split("/")[2]+"-"+this.edit_user_data.date_of_incorporation.split("/")[1]+"-"+this.edit_user_data.date_of_incorporation.split("/")[0] : ""
                        
                            params.no_of_employees = this.edit_user_data.no_of_employees
                       
                            params.building_no = this.address.building_no
                       
                            params.street1 = this.address.street1
                        
                            params.street2 = this.address.street2
                        
                            params.city = this.address.city
                        
                            params.email = this.edit_user_data.branch_email
                       
                            params.phone_number = {
                                id: this.branch_data.phone_number.id,
                                phone_type: this.edit_user_data.branch_number_type,
                                phone_number: this.edit_user_data.branch_number.phone_number,
                                country_code: this.edit_user_data.branch_number.country_code,
                                dial_code: this.edit_user_data.branch_number.dial_code,
                            }
                            var phoneNumberDetails = []
                            this.edit_user_data.alternate_phone.forEach((el, index)=>{
                                phoneNumberDetails = {
                                        id: this.branch_data.alternate_phone[index].id,
                                        phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                                        phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                                        country_code: this.edit_user_data.alternate_phone[index].country_code,
                                        dial_code: this.edit_user_data.alternate_phone[index].dial_code,
                                    }
                                    this.edit_user_data.alternate_phone[index] = phoneNumberDetails
                                })
                            params.alternate_phone = this.edit_user_data.alternate_phone
                            // params.alternate_phone = [
                            //     {
                            //         id: this.branch_data.alternate_phone[index].id,
                            //         phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                            //         phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                            //         country_code: this.edit_user_data.alternate_phone[index].country_code,
                            //         dial_code: this.edit_user_data.alternate_phone[index].dial_code,
                            //     }
                            // ]
                       
                            params.alternate_email = this.edit_user_data.alternate_email;
                       
                            params.website = this.edit_user_data.website;
                        
                            params.state = this.address.state;
                       
                            params.township = this.address.town;
                        
                            params.country = this.address.country;
                        
                            params.zip_code = this.address.zipcode;
                       
                            params.time_zone = this.edit_user_data.time_zone.label;
                       
                            params.is_headquarter = this.assign_as_headquarter;
                        
                    
                        let response = await this.updateBranchById(params, this.$route.query.branch);
                        if(response.status_id == 1) {
                            this.success_msg = response.message
                            this.$refs.success_modal.open();
                            setTimeout(() => {
                                this.$refs.success_modal.close();
                                this.getBranchDataById();
                            }, 2000);
                        }
                    }
                    catch(err) {
                        this.warning_msg = err;
                        this.$refs.warning_modal.open();
                    }
            },
            moment(date) {
                return moment(date);
            },
            goBack() {
                window.history.back();
            },
            branchPhone({number,isValid,country}) {
                this.edit_user_data.branch_number.phone_number = number.national;
                this.edit_user_data.branch_number.dial_code = country.dialCode;
                this.edit_user_data.branch_number.country_code = country.iso2;
            },
            branchAltPhone({number,isValid,country}, phoneindex) {
                this.edit_user_data.alternate_phone[phoneindex].phone_number = number.national;
                this.edit_user_data.alternate_phone[phoneindex].dial_code = country.dialCode;
                this.edit_user_data.alternate_phone[phoneindex].country_code = country.iso2;
            },
            expandTabs(type) {
                if(type == 'branch_info') {
                    this.branch_info_expand = !this.branch_info_expand;
                } else if(type == 'communication_info') {
                    this.communication_info_expand = !this.communication_info_expand;
                } else if(type == 'branch_address') {
                    this.branch_address_expand = !this.branch_address_expand;
                }
            },
            editFullAddress() {
                this.edit_full_address = true;
                this.edit_country = false;
                this.edit_state = false;
                this.edit_building_no = false;
                this.edit_city = false;
                this.edit_town = false;
                this.edit_street1 = false;
                this.edit_street2 = false;
                this.edit_zipcode = false;
            },
            editUser(obj, index){
                if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                    if(obj == 'branch_name'){
                        this.edit_branch_name = true;
                        this.edit_time_zone = false;
                        this.edit_no_of_employees = false;
                        this.edit_branch_code = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'branch_code'){
                        this.edit_branch_name = false;
                        this.edit_branch_code = true;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_time_zone = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'no_of_employees'){
                        this.edit_branch_name = false;
                        this.edit_no_of_employees = true;
                        this.edit_branch_code = false;
                        this.edit_building_no = false;
                        this.edit_time_zone = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'date_of_incorporation'){
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_time_zone = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = true;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if (obj == 'building_no') {
                        this.edit_building_no = true;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if (obj == 'street1') {
                        this.edit_street1 = true;
                        this.edit_building_no = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if (obj == 'street2') {
                        this.edit_street2 = true;
                        this.edit_street1 = false;
                        this.edit_building_no = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'city') {
                        this.edit_city = true;
                        this.edit_street1 = false;
                        this.edit_building_no = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'time_zone'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_time_zone = true;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'branch_email'){
                        this.edit_branch_email = true;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_time_zone = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'alternate_email'){
                        this.alt_email_index_selected = index;
                        this.edit_alternate_email = true;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_time_zone = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_number = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'branch_number'){
                        this.edit_branch_number = true;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_time_zone = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'alternate_phone'){
                        this.edit_alternate_phone_index = index;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = true;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_time_zone = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_alternate_email = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'alternate_email'){
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_alternate_email = true;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_time_zone = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'website'){
                        this.edit_website = true;
                        this.edit_time_zone = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_alternate_email = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'state'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_alternate_email = false;
                        this.edit_building_no = false;
                        this.edit_time_zone = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_state = true;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'town'){
                        this.edit_website = false;
                        this.edit_alternate_email = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_state = false;
                        this.edit_time_zone = false;
                        this.edit_town = true;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'country'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_alternate_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_time_zone = false;
                        this.edit_country = true;
                        this.edit_zipcode = false;
                        this.edit_street2 = false;
                    }
                    else if(obj == 'zipcode'){
                        this.edit_website = false;
                        this.edit_branch_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_branch_email = false;
                        this.edit_branch_name = false;
                        this.edit_branch_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_alternate_email = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorporation = false;
                        this.edit_time_zone = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = true;
                        this.edit_street2 = false;
                    }
                }
            },
            resetValue(obj, index){
                let branch_data = JSON.parse(localStorage.getItem('branch_info'));
                if(obj == "full_address_add_update") {
                    this.edit_full_address = false;
                    this.edit_country = false;
                    this.edit_state = false;
                    this.edit_city = false;
                    this.edit_building_no = false;
                    this.edit_town = false;
                    this.edit_street1 = false;
                    this.edit_street2 = false;
                    this.edit_zipcode = false;
                    this.address.country = branch_data == undefined || null ? '' : branch_data.country
                    this.address.state = branch_data == undefined || null ? '' : branch_data.state
                    this.address.building_no = branch_data == undefined || null ? '' : branch_data.building_no
                    this.address.city = branch_data == undefined || null ? '' : branch_data.city
                    this.address.town = branch_data == undefined || null ? '' : branch_data.township
                    this.address.street1 = branch_data == undefined || null ? '' : branch_data.street1
                    this.address.street2 = branch_data == undefined || null ? '' : branch_data.street2;
                    this.address.zipcode = branch_data == undefined || null ? '' : branch_data.zip_code
                } else {
                    if(obj == 'branch_name'){
                        this.edit_branch_name = false;
                        this.edit_user_data.branch_name = branch_data.name == undefined || null ? '' : branch_data.name;
                    }
                    else if(obj == 'branch_code'){
                        this.edit_branch_code = false;
                        this.edit_user_data.branch_code = branch_data.code == undefined || null ? '' : branch_data.code
                    }
                    else if(obj == 'date_of_incorporation'){
                        this.edit_date_of_incorporation = false;
                        this.edit_user_data.date_of_incorporation = branch_data.date_of_incorporation == undefined || null ? '' : branch_data.date_of_incorporation.split('-')[2]+'/'+branch_data.date_of_incorporation.split('-')[1]+'/'+branch_data.date_of_incorporation.split('-')[0];
                    }
                    else if (obj == 'no_of_employees') {
                        this.edit_no_of_employees = false;
                        this.edit_user_data.no_of_employees = branch_data.no_of_employees == undefined || null ? '' : branch_data.no_of_employees;
                    }
                    else if (obj == 'building_no') {
                        this.edit_building_no = false;
                        this.address.building_no = branch_data.building_no == undefined || null ? '' :  branch_data.building_no;
                    }
                    else if(obj == 'street1') {
                        this.edit_street1 = false;
                        this.address.street1 = branch_data.street1 == undefined || null ? '' : branch_data.street1;
                    }
                    else if(obj == 'street2') {
                        this.edit_street1 = false;
                        this.address.street2 = branch_data.street2 == undefined || null ? '' : branch_data.street2;
                    }
                    else if (obj == 'city') {
                        this.edit_city = false;
                        this.address.city = branch_data.email == undefined || null ? '' : branch_data.city;
                    }
                    else if(obj == 'branch_email'){
                        this.edit_branch_email = false;
                        this.edit_user_data.branch_email = branch_data.email == undefined || null ? '' : branch_data.email;
                    }
                    else if(obj == 'branch_number'){
                        this.edit_branch_number = false;
                        this.edit_user_data.branch_number = branch_data.phone_number == undefined || null ? '' : branch_data.phone_number;
                    }
                    else if(obj == 'alternate_phone'){
                        this.edit_alternate_phone = false;
                        this.edit_user_data.alternate_phone[index].phone_number = branch_data.alternate_phone[index] == undefined || null ? '' : branch_data.alternate_phone[index].phone_number;
                    }
                    else if(obj == 'alternate_email'){
                            this.edit_alternate_email = false;
                            this.edit_user_data.alternate_email[index] = branch_data.alternate_email[index] == undefined || null ? '' : branch_data.alternate_email[index];
                        }
                    else if(obj == 'website'){
                        this.edit_website = false;
                        this.edit_user_data.website = branch_data.website == undefined || null ? '' : branch_data.website;
                    }
                    else if(obj == 'state'){
                        this.edit_state = false;
                        this.address.state = branch_data.state == undefined || null ? '' : branch_data.state;
                    }
                    else if(obj == 'town'){
                        this.edit_town = false;
                        this.address.town = branch_data.township == undefined || null ? '' : branch_data.township;
                    }
                    else if(obj == 'country'){
                        this.edit_country = false;
                        this.address.country = branch_data.country == undefined || null ? '' : branch_data.country;
                    }
                    else if(obj == 'zipcode'){
                        this.edit_zipcode = false;
                        this.address.zipcode = branch_data.zip_code == undefined || null ? '' : branch_data.zip_code;
                    }
                    else if(obj == 'time_zone'){
                        this.edit_time_zone = false;
                        this.edit_user_data.time_zone.label = branch_data.time_zone == undefined || null ? '' : branch_data.time_zone;
                    }
                }
            },
            countryClose(data) {
                this.address.country = data;
                this.$forceUpdate();
            },
            stateClose(data) {
                this.address.state = data;
                this.$forceUpdate();
            },
            updateState(country) {
                // this.edit_state = true;
                this.isState = true;
                this.states = [];
                if (country) {
                    let params = {
                        country_required: false,
                        state_required: true,
                        country_name: country,
                    };
                    axios.post(globals.CRM_API +"/location/get_location", params).then(res => {
                    if (res.data.status_id == 1 && "all_states" in res.data.response) {
                        res.data.response.all_states.forEach(x => {
                        this.states.push(x);
                        });
                        this.isState = false
                    }
                    });
                }
            },
            setPlace(place) {
                let currentPlace = place;
                let address = place.formatted_address.split(", ");
                this.address.street1 =
                    address[address.length - 3] || "";
                this.address.town =
                    address[address.length - 4] || "";
                this.address.city = currentPlace.vicinity;
                let statezip = address[address.length - 2]
                    ? address[address.length - 2].split(" ")
                    : ["", ""];
                if (statezip.length == 1) {
                    if (statezip[0].match(/^[0-9]+$/)) {
                    this.address.zipcode = statezip[0];
                    } else {
                    this.address.state = statezip[0];
                    }
                } else {
                    this.address.zipcode = statezip[0].match(/^[0-9]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[0-9]+$/)
                    ? statezip[1]
                    : "";
                    this.address.state = statezip[0].match(/^[a-zA-Z]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[a-zA-Z]+$/)
                    ? statezip[1]
                    : "";
                }
                if (address[address.length - 1].includes("-")) {
                    let countrySelected = address[address.length - 1].split(" - ");
                    countrySelected.forEach(x => {
                    if (this.countries.includes(x)) {
                        this.address.country = x;
                    } else {
                        this.address.country = "";
                    }
                    });
                } else {
                    this.address.country = address[address.length - 1];
                }
                // this.address[index].address.latitude = currentPlace.geometry.location.lat() || "";
                // this.address[index].address.longitude = currentPlace.geometry.location.lng() || "";
                this.address.location_vmodel = place.formatted_address;
                this.$forceUpdate();
            },
            getLocation() {
                if (navigator.geolocation) {
                    // timeout at 60000 milliseconds (60 seconds)
                    var options = {
                    enableHighAccuracy: false,
                    timeout: 30000, // milliseconds (30 seconds)
                    maximumAge: 600000
                    }; // milliseconds (10 minutes)
                    navigator.geolocation.getCurrentPosition(
                    this.showLocation,
                    this.errorHandler,
                    options
                    );
                } else {
                    alert("Sorry, browser does not support geolocation!");
                }
            },
            showLocation(position) {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
                let calllatlong = axios.create();
                delete calllatlong.defaults.headers.common['Authorization'];
                calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
                .then(response => {
                    let address = response.data.results[0].formatted_address.split(", ");
                    this.address.street1 =
                    address[address.length - 6] || "";
                    this.address.town =
                    address[address.length - 4] || "";
                    this.address.city = address[address.length - 3] || "";
                    let statezip = address[address.length - 2]
                    ? address[address.length - 2].split(" ")
                    : ["", ""];
                    this.address.zipcode = statezip[0].match(/^[0-9]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[0-9]+$/)
                    ? statezip[1]
                    : "";
                    this.address.state = statezip[0].match(/^[a-zA-Z]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[a-zA-Z]+$/)
                    ? statezip[1]
                    : "";
                    if (address[address.length - 1].includes("-")) {
                    let countrySelected = address[address.length - 1].split(" - ");
                    countrySelected.forEach(x => {
                        if (this.countries.includes(x)) {
                        this.address.country = x;
                        } else {
                        this.address.country = "";
                        }
                    });
                    } else {
                    this.address.country = address[address.length - 1];
                    }
                    this.address.location_vmodel = response.data.results[0].formatted_address;
                    this.$forceUpdate();
                })
                .catch(e => {
                    // this.errors.push(e)
                });
            },
            errorHandler(err) {
                if (err.code == 1) {
                    // alert("Error: Access is denied!");
                } else if (err.code == 2) {
                    // alert("Error: Position is unavailable!");
                }
            },
            async getBranchDataById() {
                this.edit_website = false;
                this.edit_branch_number = false;
                this.edit_alternate_phone = false;
                this.edit_branch_email = false;
                this.edit_branch_name = false;
                this.edit_branch_code = false;
                this.edit_no_of_employees = false;
                this.edit_alternate_email = false;
                this.edit_building_no = false;
                this.edit_full_address = false; 
                this.edit_street1 = false;
                this.edit_street2 = false;
                this.edit_city = false;
                this.edit_date_of_incorporation = false;
                this.edit_time_zone = false;
                this.edit_state = false;
                this.edit_town = false;
                this.edit_country = false;
                this.edit_zipcode = false;
                this.ajax_call_in_progress = true;
                
                try {
                    let response = await this.getBranchById(this.$route.query.branch);
                    if(response.status_id == 1) {
                        localStorage.setItem('branch_info', JSON.stringify(response.response));
                        // this.getListViewData();
                        let data = response.response
                        this.branch_data = response.response;
                        this.edit_user_data.branch_name = data.name;
                        this.edit_user_data.no_of_employees = data.no_of_employees;
                        this.edit_user_data.branch_code = data.code;
                        this.edit_user_data.date_of_incorporation = data.date_of_incorporation ? data.date_of_incorporation.split('-')[2]+'/'+data.date_of_incorporation.split('-')[1]+'/'+data.date_of_incorporation.split('-')[0] : '';
                        this.edit_user_data.time_zone = {
                            label: data.time_zone,
                            value: ''
                        };
                        this.edit_user_data.branch_email = data.email;
                        this.edit_user_data.alternate_email = data.alternate_email;
                        this.edit_user_data.branch_number = data.phone_number;
                        this.edit_user_data.alternate_phone = data.alternate_phone;
                        this.edit_user_data.website = data.website;
                        this.edit_user_data.branch_number_type = data.phone_number.phone_type;
                        this.address.building_no = data.building_no;
                        this.address.street1 = data.street1;
                        this.address.street2 = data.street2;
                        this.address.city = data.city;
                        this.address.town = data.township;
                        this.address.state = data.state;
                        this.address.country = data.country;
                        this.address.zipcode = data.zip_code;
                        this.assign_as_headquarter = data.is_headquarter;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                }
            },
            setAsHeadquarter() {
                setTimeout(() => {
                    this.updateInfo('assign_as_headquarter');
                }, 500);
            },
            async updateInfo(obj, index) {
                try {
                    let params = {
                    };
                    if(obj == "full_address_add_update") {
                        params.country = this.address.country
                        params.state = this.address.state
                        params.building_no = this.address.building_no
                        params.city = this.address.city
                        params.township = this.address.town
                        params.street1 = this.address.street1
                        params.street2 = this.address.street2;
                        params.zip_code = this.address.zipcode
                    } else {
                        if(obj == 'branch_name'){
                            params.name = this.edit_user_data.branch_name;
                        }
                        else if(obj == 'branch_code'){
                            params.code = this.edit_user_data.branch_code;
                        }
                        else if(obj == 'date_of_incorporation'){
                            params.date_of_incorporation = this.edit_user_data.date_of_incorporation ? this.edit_user_data.date_of_incorporation.split("/")[2]+"-"+this.edit_user_data.date_of_incorporation.split("/")[1]+"-"+this.edit_user_data.date_of_incorporation.split("/")[0] : ""
                        }
                        else if (obj == 'no_of_employees') {
                            params.no_of_employees = this.edit_user_data.no_of_employees
                        }
                        else if (obj == 'building_no') {
                            params.building_no = this.address.building_no
                        }
                        else if(obj == 'street1') {
                            params.street1 = this.address.street1
                        }
                        else if(obj == 'street2') {
                            params.street2 = this.address.street2
                        }
                        else if (obj == 'city') {
                            params.city = this.address.city
                        }
                        else if(obj == 'branch_email'){
                            params.email = this.edit_user_data.branch_email
                        }
                        else if(obj == 'branch_number'){
                            params.phone_number = {
                                id: this.branch_data.phone_number.id,
                                phone_type: this.edit_user_data.branch_number_type,
                                phone_number: this.edit_user_data.branch_number.phone_number,
                                country_code: this.edit_user_data.branch_number.country_code,
                                dial_code: this.edit_user_data.branch_number.dial_code,
                            }
                        }
                        else if(obj == 'alternate_phone'){
                            params.alternate_phone = [
                                {
                                    id: this.branch_data.alternate_phone[index].id,
                                    phone_type: this.edit_user_data.alternate_phone[index].phone_type,
                                    phone_number: this.edit_user_data.alternate_phone[index].phone_number,
                                    country_code: this.edit_user_data.alternate_phone[index].country_code,
                                    dial_code: this.edit_user_data.alternate_phone[index].dial_code,
                                }
                            ]
                        }
                        else if(obj == 'alternate_email'){
                            params.alternate_email = this.edit_user_data.alternate_email;
                        }
                        else if(obj == 'website'){
                            params.website = this.edit_user_data.website;
                        }
                        else if(obj == 'state'){
                            params.state = this.address.state;
                        }
                        else if(obj == 'town'){
                            params.township = this.address.town;
                        }
                        else if(obj == 'country'){
                            params.country = this.address.country;
                        }
                        else if(obj == 'zipcode'){
                            params.zip_code = this.address.zipcode;
                        }
                        else if(obj == 'time_zone'){
                            params.time_zone = this.edit_user_data.time_zone.label;
                        } else if (obj == 'assign_as_headquarter') {
                            params.is_headquarter = this.assign_as_headquarter;
                        }
                    }
                    let response = await this.updateBranchById(params, this.$route.query.branch);
                    if(response.status_id == 1) {
                        this.success_msg = response.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getBranchDataById();
                        }, 2000);
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            }

        },
        props: [],
        mounted() {
            this.getBranchDataById();
        },
        created() {
            const dict = {
                custom: {
                    branch_number:{
                        min:() => "Enter valid phone number.",
                    },
                    no_of_employees:{
                        numeric:() => "Please enter number.",
                    },
                }
            }
            this.$validator.localize('en', dict)
        },
        watch: {
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
        beforeDestroy() {
            localStorage.removeItem('branch_info');
        },
    };
</script>
<style scoped>
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
</style>