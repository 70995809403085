<template>
    <div class="page-layout blank mb-20">
        <div class="pb-10 h-100" style="height:100% !important">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToProductView">Product</span>
                        <a>View</a>
                    </div>
                </div>
                <!-- <div>
                    <div class="d-flex align-items-center pl-3">
                        <button type="button" class="btn btn-smm btn-new-secondary"
                            style="border: 0 !important;border-radius: 6px !important;">
                            <img src="/static/images/filter.svg" />
                            Filter
                        </button>
                    </div>
                </div> -->
            </div>
            <div class="d-flex align-items-center mb-3">
                <div class="d-flex align-items-center w-100" style="position: relative;">
                    <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                    <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                    <input type="text" class="search-input-field" v-model="search_key" @input="getEmployeesForProduct" placeholder="Search by Name, Employee ID, E-mail" />
                </div>
                <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'admin'" class="d-flex justify-content-center ml-2"
                    style="background: rgb(255, 255, 255); border-radius: 20px; width: 250px; height: 2.9em;">
                    <div @click="addAccess" class="d-flex justify-content-center align-items-center pointer pr-8" style="padding: 0 10px;">
                        <i class="icon icon-person-plus text-secondary fs-16 mr-1"></i>
                        Add Access
                    </div>
                </div>

                <!-- <div class="d-flex justify-content-center ml-2"
                    style="background: rgb(255, 255, 255); border-radius: 20px; width: 150px; height: 2.9em;">
                    <div class="d-flex justify-content-center align-items-center pointer pointer-events-none"
                        style="padding: 0 10px; border-right: 0.5px solid rgb(204, 227, 252); opacity: 0.5;">
                        <img src="/static/images/grid_new.svg" width="18">
                    </div>
                    <div class="d-flex justify-content-center align-items-center pointer"
                        style="padding: 0 10px; border-left: 0.5px solid rgb(204, 227, 252);">
                        <img src="/static/images/filter_bars_new.svg" width="18">
                    </div>
                </div> -->
            </div>
            <div class="product-block" style="background-color:#fff;border-top-right-radius:15px;border-bottom-right-radius:15px;border-top-left-radius:15px;border-bottom-left-radius:15px;">
                <div class="col-lg-12 py-5 px-3">
                    <div class="d-flex product-block-container" style="border-radius: 15px;">
                        <!-- <div class="left-sec">
                            <div class="" style="height:100% !important;">
                                <div class="cover-card">
                                    <div class="cover-pic row ml-0 mr-0">
                                        <div class="d-flex align-items-center justify-content-center ml-auto mr-auto"
                                            style="margin: 60px 0">
                                            <div>
                                                <img style="border-radius: 50%;" :src="comp_logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/company-logo.png'"
                                                    width="50px" height="50px" />
                                            </div>
                                            <div class="ml-5">
                                                <p class="fs-14 text-white fw-600 mb-0">{{comp_name}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="cover-card-block">
                                        <div class="logo-pic px-5">
                                            <div v-if="products_data[0]" class="d-flex align-items-center justify-content-center" style="position: relative; background-color: #ecf1f9; width: 100px; height: 100px; border-radius: 50%; padding: 15px;">
                                                    <img :src="products_data[0].logo_blue || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/company-logo.png'" width="50px" height="50px"
                                                    alt="name" class="profileImg">
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="products_data[0]" class="fw-600 text-center pt-2">
                                        <span class="text-white">{{products_data[0].product_name || "-"}}</span>
                                    </div>
                                    <div v-if="products_data[0]" class="about-block fw-600 py-3 px-5">
                                        <div class="w-100">
                                            <span class="text-white">About</span>
                                            <div class="access-tabs d-flex flex-column">
                                                <span style="text-align:left">{{products_data[0].about || "-"}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="create-company-container w-100">
                            <div class="mb-4">
                                <div class="row" v-if="ajax_call_in_progress">
                                    <div class="col-lg-12 py-5 px-3 mt-3">
                                        <div class="flex-container">
                                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="list_data.length == 0 && !ajax_call_in_progress" class="row flex-wrap align-items-center mt-10">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <p class="text-dark fs-14 text-center">No Data Found</p>
                                    </div>
                                </div>
                                <div v-else class="row" style="flex-wrap:wrap;">
                                    <div v-for="(employee, employee_index) in list_data" :key="employee_index+'employee_index'" class="col-lg-3 col-md-3 col-sm-6 mt-4 d-flex justify-content-center">
                                        <div class="w-100 h-100" style="box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);border-bottom-left-radius: 5px;border-bottom-right-radius:5px">
                                            <div class="px-2" style="background: url('/static/images/Rectangle 19382@3x.png') no-repeat;background-size:100% 100%;height:300px;">
                                                <div class="dotted_line">
                                                    <a href="javascript:;" style="border-right:4px dotted #fff; opacity: 0; visibility: hidden;"></a>
                                                </div>
                                                <div class="d-flex flex-column w-100 user_details">
                                                    <div class="ml-auto mr-auto">
                                                        <img 
                                                        :class="{'profile_pic_super_admin': employee.user_access == 'super admin' || 'Super Admin', 'profile_pic_admin': employee.user_access == 'admin' || 'Admin', 'profile_pic_employee': employee.user_access == 'employee' || 'Employee'}"
                                                        :src="employee.profile_pic || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png'"
                                                            width="135" height="135" alt="">
                                                    </div>
                                                    <span class="text-white text-center fw-600 fs-16 text-capitalize" style="margin-top:2.2rem">{{employee.full_name}}</span>
                                                    <button style="margin:0.5rem 15px 0px;background-color: #00448b !important;" class="text-capitalize fs-14 fw-500"
                                                    :class="{'profile_button_super_admin': employee.user_access == 'super admin' || 'Super Admin', 'profile_button_admin': employee.user_access == 'admin' || 'Admin', 'profile_button_employee': employee.user_access == 'employee' || 'Employee'}"
                                                    >{{employee.user_access.replace("_"," ")}}</button>
                                                </div>
                                            </div>
                                            <div class="px-3">
                                                <div class="e-tab">
                                                    <span class="py-2 fs-12">Email</span>
                                                    <span class="py-2 fs-12 email-ellipses">{{employee.email}}</span>
                                                </div>
                                                <div class="e-tab" style="border-top:1px solid #000;">
                                                    <span class="py-2 fs-12">Tel</span>
                                                    <span class="py-2 fs-12">{{employee.phone_number.phone_number || "-"}}</span>
                                                </div>
                                                <div v-if="loggedInUser.user_role == 'owner'">
                                                    <div v-if="employee.user_access == 'super admin' || employee.user_access == 'super_admin' || employee.user_access == 'admin' || employee.user_access == 'employee'" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="rm-access-btn fs-14 fw-500" @click="removeAccess(employee.employee_id)">Remove Access</button>
                                                    </div>
                                                </div>
                                                <div v-else-if="loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin'">
                                                    <div v-if="employee.user_access == 'admin' || employee.user_access == 'employee'" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="rm-access-btn fs-14 fw-500" @click="removeAccess(employee.employee_id)">Remove Access</button>
                                                    </div>
                                                    <div v-else-if="loggedInUser.user_role == 'admin' && (loggedInUser.company_id == $route.params.company_id)" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="rm-access-btn fs-14 fw-500" @click="removeAccess(employee.employee_id)">Remove Access</button>
                                                    </div>
                                                </div>
                                                <div v-if="loggedInUser.user_role == 'admin'">
                                                    <div v-if="employee.user_access == 'employee'" class="text-center" style="padding:20px 25px 25px 25px">
                                                        <button class="rm-access-btn fs-14 fw-500" @click="removeAccess(employee.employee_id)">Remove Access</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end align-items-center bg-white py-2 px-4"
                        style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px" v-if="!ajax_call_in_progress">
                        <multiselect class="diginew-multiselect"
                            :options="pageLimit" :close-on-select="true" v-model.number="limit"
                            @search-change="getEmployeesForProduct"
                            @close="getEmployeesForProduct"
                            open-direction="top" :show-labels="false" placeholder=""
                            style="width: 4.2em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                        <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg"
                            style="margin-left: -20px;">
                        <span class="page-info pr-2 pl-6 fw-500"
                            style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                        <button @click="firstPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                            <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                                <i class="icon-chevron-double-left"></i>
                            </el-tooltip>
                        </button>
                        <button @click="previousPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                                <i class="icon icon-chevron-left"></i>
                            </el-tooltip>
                        </button>
                        <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} -
                            {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                        <button @click="nextPage()" :disabled="skip_temp >= total" :class="{'not-allowed' : skip_temp >= total }"
                            class="pagination-list mt-1 mr-2 pointer">
                            <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                                <i class="icon icon-chevron-right"></i>
                            </el-tooltip>
                        </button>
                        <button @click="lastPage()" class="mt-1 pointer pagination-list" :class="{'not-allowed' : temp_total == total}" :disabled="temp_total == total">
                            <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                                <i class="icon-chevron-double-right"></i>
                            </el-tooltip>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <add-access v-if="add_access" @hideAddAccess="hideAddAccess" @saveAddSuccess="saveAddSuccess" modal_name="add-access"></add-access>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
    import companies from '../mixins/companies';
    import product from '../mixins/product';
    import AddAccess from './AddAccess.vue';
    import {HalfCircleSpinner} from 'epic-spinners';
    import axios from 'axios';
    import { SweetModal } from 'sweet-modal-vue';
    import globals from '../globals';
    export default {
        mixins: [companies, product],
        components: {
            AddAccess,
            HalfCircleSpinner,
            SweetModal
        },
        data() {
            return {
                filter_employee: '',
                limit: 10,
                products_data: [],
                add_access: false,
                skip: 0,
                search_key: '',
                count: 0,
                start: 0,
                end: 0,
                total: 0,
                skip_temp:0,
                temp_total:0,
                list_data: [],
                ajax_call_in_progress: false,
                comp_name: '',
                comp_logo: '',
                pageLimit: [],
                warning_msg: '',
                success_msg: '',
            }
        },
        methods: {
            clearSearch(){
                this.search_key = ""
                this.getEmployeesForProduct();
            },
            async getProductsList(comp_id) {
                try {
                    let params = {
                        company_id: comp_id,
                        search_key: '',
                        skip: 0,
                        limit: 10
                    };
                    let response = await this.getAllProducts(params);
                    if(response.status_id == 1) {
                        let prod_data = response;
                        console.log("hicjhbehjc")
                        console.log(prod_data)
                        this.products_data = prod_data.subscribed_products.filter(word => word.id === this.$route.params.id);
                        console.log("hbebc")
                        console.log(this.products_data )
                    }
                }
                catch(err) {
                }
            },
            goToProductView() {
                this.$router.push('/product/view/cmpid/' + this.$route.params.company_id);
            },
            addAccess() {
                this.add_access = true;
                setTimeout(() => {
                    this.$modal.show('add-access');
                }, 500)
            },
            hideAddAccess() {
                this.add_access = false;
                setTimeout(() => {
                    this.$modal.show('add-access');
                }, 500)
            },
            async saveAddSuccess() {
                this.add_access = false;
                setTimeout(() => {
                    this.$modal.hide('add-access');
                }, 500)
                this.getEmployeesForProduct();
            },
            previousPage() {
                this.temp_total = 0
                this.skip_temp = 0
                if (this.skip == 0) {
                    return;
                }
                this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
                this.getEmployeesData();
            },
            nextPage() {
                this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp;
                this.getEmployeesData();
            },
            firstPage(){
                this.temp_total = 0
                this.skip = 0
                this.limit = this.limit
                this.list_view_data = [] 
                this.getEmployeesData();
            },
            lastPage() {
                this.skip_temp = 0
                this.skip = 0
                this.temp_total = this.total
                let no_of_pages = Math.ceil(this.total/this.limit)
                this.skip_temp = (no_of_pages * this.limit) - this.limit
                if (this.skip_temp >= this.total) {
                    return;
                }
                this.skip = this.skip_temp
                this.getEmployeesData();
            },
            async getEmployeesForProduct() {
                this.ajax_call_in_progress = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        product_id: this.$route.params.id,
                        company_id: this.$route.params.company_id
                    };
                    await axios.post(globals.AUTH_SERVICE + `/company/employees`, params).
                    then((response) => {
                        if(response.data.status_id == 1) {
                            this.list_data = response.data.response;
                            console.log("ehujfoiefdjoe")
                            console.log( this.list_data)
                            this.total = response.data.count;
                            this.ajax_call_in_progress = false;
                        }
                    }).catch((err) => {
                        this.ajax_call_in_progress = false;
                    });
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            },
            async removeAccess(id) {
                let params = {
                    employee_id: id,
                    product_id: this.$route.params.id
                }
                await this.$http.post(globals.AUTH_SERVICE + `/employees/remove/product`, params).then((response) => {
                    if(response.data.status_id == 1) {
                        this.success_msg = response.data.message
                        this.$refs.success_modal.open();
                        setTimeout(() => {
                            this.$refs.success_modal.close();
                            this.getEmployeesForProduct();
                        }, 2000);
                    }
                }).catch((err) => {
                    this.warning_msg = err.response.data.reason;
                    this.$refs.warning_modal.open();
                });
            },
            async getCompData(id) {
                try {
                    let response = await this.getCompanyById(id);
                    if (response.status_id == 1) {
                        this.comp_name = response.response.name;
                        this.comp_logo = response.response.logo;
                    }
                }
                catch(err) {
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
        },
        mounted() {
            this.getCompData(this.$route.params.company_id);
            this.getProductsList(this.$route.params.company_id);
            this.getEmployeesForProduct();
            for (let i = 1; i <= 50; i++) {
                i += 9
                this.pageLimit.push(i);
            }
        },
        computed: {
            loggedInUser() {
				return this.$store.state.loggedInUser;
			},
            getSkipCount(){
                if(this.total == 0) {
                    return 0
                } else {
                    let skip_count = this.skip>this.total?this.total:this.skip+1;
                    return skip_count;
                }
            },
        }
    };
</script>

<style scoped>
    .product-access-container {
        background-color: #fff;
        padding: 15.5px 0 0px 0;
        border-radius: 6px;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }

    .profile_button {
        cursor: pointer;
        background-color: #ff268a;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
    }

    .w-280px {
        max-width: 265px;
        min-width: 140px;
    }

    .add_access_input {
        border-bottom: 1px solid grey;
    }

    .add_access_input:focus {
        border-bottom: 1px solid #0d84f2;
    }

    .list-group-item {
        border-bottom: 1px solid #cce3fc
    }

    .pl-5px {
        padding-left: 5px;
    }

    .pr-5px {
        padding-left: 5px;
    }
    
    .list-item-content{
        padding-left:10px;
    }

    .dropdown-profiles{
        left:-30px !important;  
    }

    .profile_button_super_admin {
        cursor: pointer;
        background-color: #ff268a;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline:none;
    }

    .profile_button_admin {
        cursor: pointer;
        background-color: #0d84f2;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline:none;
    }

    .profile_button_employee {
        cursor: pointer;
        background-color: #50bcff;
        padding: 10px;
        border-radius: 5px;
        color: #fff;
        outline:none;
    }

    .profile_pic_super_admin{
        max-width:100%;
		height: 135px !important;
        border-radius: 50%;
		border: 5px solid transparent;
    	outline: 2px solid orange;
    }

    .profile_pic_admin{
        max-width:100%;
		height: 135px !important;
        border-radius: 50%;
		border: 5px solid transparent;
    	outline: 2px solid #1a9347;
    }

    .profile_pic_employee{
        max-width:100%;
		height: 135px !important;
        border-radius: 50%;
		border: 5px solid transparent;
    	outline: 2px solid #1a9347;
    }

    .rm-access-btn {
        cursor: pointer;
        padding: 10px;
        background-color: #e82828;
        color: #fff;
        width: 100%;
        border-radius: 5px;
        outline:none;
    }

    .dotted_line{
        position:relative;
        float:right;
        top:15px;
        right:10px;
    }

    .user_details{
        position:relative;
        top:15px;
    }

    .e-tab {
        display: flex;
        justify-content: space-between;
    }

    @media screen and (max-width:1200px) {
        .e-tab {
            flex-direction: column;
        }
    }

    @media screen and (max-width:991.5px) {
        .e-tab {
            flex-direction: row;
        }
    }
    .email-ellipses {
        margin-left: 20px;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
</style>