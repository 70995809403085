<template>
    <div class="page-layout blank mb-20">
        <div class="pb-10 h-100" style="height:100% !important">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToCompanyList">Company</span>
                        <span>Product</span>
                        <a>View</a>
                    </div>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center mb-3">
                <div class="d-flex align-items-center w-100" style="position: relative;">
                    <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                    <input v-model="search_key" @input="searchProds" type="text" class="search-input-field" placeholder="Search by Product name" />
                </div>
                <div class="d-flex justify-content-center ml-2"
                    style="background: rgb(255, 255, 255); border-radius: 20px; width: 150px; height: 2.9em;">
                    <div class="d-flex justify-content-center align-items-center pointer pointer-events-none"
                        style="padding: 0 10px; border-right: 0.5px solid rgb(204, 227, 252); opacity: 0.5;">
                        <img src="/static/images/grid_new.svg" width="18">
                    </div>
                    <div class="d-flex justify-content-center align-items-center pointer"
                        style="padding: 0 10px; border-left: 0.5px solid rgb(204, 227, 252);">
                        <img src="/static/images/filter_bars_new.svg" width="18">
                    </div>
                </div>
            </div> -->
            <div class="product-block" style="border-top-right-radius:6px;border-bottom-right-radius:6px;border-top-left-radius:6px;border-bottom-left-radius:6px;background-color: #fff;">
                <div class="d-flex product-block-container pb-3 w-100" style="border-radius: 15px;">
                    <!-- <div class="left-sec">
                        <div class="" style="height:100% !important;">
                            <div class="cover-card">
                                <div class="cover-pic row ml-0 mr-0 bg-white" style="height: auto;">
                                    <img class="cover-card-img-top w-full ml-auto mr-auto" style="height: 180px; width: 100%;" alt="Cover Picture" :src="comp_data.cover_image || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/coverpic3.jpg'">
                                </div>
                                <div class="cover-card-block">
                                    <div class="change-pic px-5">
                                        <div style="position: relative;">
                                            <img style="border-radius: 50%;" :src="comp_data.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/company-logo.png'" width="100px" height="100px"
                                                alt="name" class="profileImg"> -->
                                            <!-- <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="edit-image pointer">
                                                <img style="border-radius: 50%;" src="/static/images/editgreynew.svg">
                                            </div> -->
                                        <!-- </div>
                                    </div>
                                </div>
                            </div>
                            <div class="px-4 py-2 mt-10">
                                <div class="pt-8">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <div>
                                            <img style="border-radius: 50%;" :src="comp_data.logo || 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/company-logo.png'"
                                                width="50px" height="50px" />
                                        </div>
                                        <div class="ml-5">
                                            <p v-if="loggedInUser.account_type == 'business'" class="fs-14 text-white fw-600">Business Profile</p>
                                            <p v-else class="fs-14 text-white fw-600">Personal Profile</p>
                                            <p class="fs-14 text-white fw-600 mb-0">{{comp_data.name}}</p>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="pt-8">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <button class="btn btn-new-follow">Following</button>
                                        <button class="btn btn-new-connected ml-2">Connected</button>
                                    </div>
                                </div>
                                <div class="pt-8">
                                    <div class="d-flex align-items-center justify-content-center connections-followers">
                                        <div class="px-4 text-center">
                                            <p class="fs-14 fw-600 text-white mb-1">24 k</p>
                                            <p class="fs-14 fw-500 text-white mb-0">Connections</p>
                                        </div>
                                        <div class="px-4 text-center"
                                            style="border-left: 1px solid #206ad3; border-right: 1px solid #206ad3;">
                                            <p class="fs-14 fw-600 text-white mb-1">120</p>
                                            <p class="fs-14 fw-500 text-white mb-0">Followers</p>
                                        </div>
                                        <div class="px-4 text-center">
                                            <p class="fs-14 fw-600 text-white mb-1">20 k</p>
                                            <p class="fs-14 fw-500 text-white mb-0">Following</p>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="pt-8">
                                    <div class="row flex-wrap align-items-center">
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="pointer" @click="goToParticularPage('company_info')">
                                                <div class="text-center">
                                                    <img style="width: 80px;" width="80" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/user_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Info</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer">
                                                <div class="access-tabs text-center" @click="goToParticularPage('employees')">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/employees_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Employees</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center" @click="goToParticularPage('business_unit')">
                                            <div class="access-tabs-border pointer">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/business_unit_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Business Units</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center" @click="goToParticularPage('department_unit')">
                                            <div class="access-tabs-border pointer">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/department_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Departments</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" :class="{'access-tabs-border-active':$route.path.startsWith('/product/view/cmpid/')}" @click="goToParticularPage('product_access')">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/product_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Product</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('bankdetails')">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/bank_details_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Bank Details</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('outlets')">
                                                <div class="text-center">
                                                    <img style="width: 100%;" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/outlet_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Outlets</span>
                                        </div>
                                        <div v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('company_settings')">
                                                <div class="access-tabs text-center">
                                                    <img width="30" src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">Companies Setting</span>
                                        </div>
                                        <div class="col-lg-4 col-sm-4 col-md-4 col-4 text-center">
                                            <div class="access-tabs-border pointer" @click="goToParticularPage('consultant')">
                                                <div class="access-tabs text-center">
                                                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/consultant_new.svg" />
                                                </div>
                                            </div>
                                            <span class="fs-10 text-white text-capitalize">consultant</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="w-100" v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'">
                        <div class="center-section w-100 ml-3"
                            style="background-color:transparent !important;border:none !important;">
                            <div class="w-100 d-flex align-items-center">
                                <div class="d-flex align-items-center mt-2">
                                    <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-0'
                                        :class="{'activeTab':toShow == 'products','new-blue':toShow == 'products'}" 
                                        @click="showTabs('products')" style="background-color: #fff;border-bottom: 2px solid #acaeb1;">
                                        Products
                                    </div>
                                </div>
                                <div class="d-flex align-items-center mt-2">
                                    <div class='slant-tabs d-flex align-items-center justify-content-center text-gray ml-0'
                                        :class="{'activeTab':toShow == 'product_requests','new-blue':toShow == 'product_requests'}"
                                        @click="showTabs('product_requests')" style="background-color: #fff;border-bottom: 2px solid #acaeb1;">
                                        <span class="d-flex" style="width: max-content;">Product Requests</span>
                                    </div>
                                </div>
                                <div v-if="toShow == 'products'" class="d-flex align-items-center w-100 ml-30 mt-4 mr-6" style="position: relative;border: 0.5px solid rgba(0, 0, 0, 0.16);border-radius: 10px;">
                                    <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                                    <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                                    <input v-model="search_key" @input="searchProds" type="text" class="search-input-field" placeholder="Search by Product name" />
                                </div>
                            </div>
                            <div class="center-content">
                                <div class="preview-elements">
                                    <div v-if="toShow == 'products'" class="tab-content profile-tab-content scroll-media overflow-auto-y" id="myTabContent">
                                        <div class="" id="basictab" aria-labelledby="basic-tab" v-if="producstsinfo">
                                            <template v-if="get_comp_api_inprogress">
                                                <div class="d-flex align-items-center justify-content-center w-100">
                                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                                </div>
                                            </template>
                                            <product-access-view v-else :comp_data="comp_data"></product-access-view>
                                        </div>
                                    </div>
                                    <div v-else-if="toShow == 'product_requests'" class="tab-content profile-tab-content scroll-media overflow-auto-y" style="min-height: 50em;" id="myTabContent">
                                        <div class="" id="basictab" aria-labelledby="basic-tab" v-if="productrequestsinfo">
                                            <template v-if="get_comp_api_inprogress">
                                                <div class="d-flex align-items-center justify-content-center w-100">
                                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                                </div>
                                            </template>
                                            <product-requests v-else :comp_data="comp_data"></product-requests>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="bg-white w-100" style="border-radius:15px;">
                        <template v-if="get_comp_api_inprogress">
                            <div class="d-flex align-items-center justify-content-center w-100">
                                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                            </div>
                        </template>
                        <product-access-view v-else :comp_data="comp_data"></product-access-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from '../eventBus/nav-bar-event';
    import companies from '../mixins/companies';
    import ProductAccessView from './ProductAccessView.vue';
    import ProductRequests from './ProductRequests.vue';
    import {HalfCircleSpinner} from 'epic-spinners';
    export default {
        mixins: [companies],
        components:{
            ProductAccessView,
            ProductRequests,
            HalfCircleSpinner
        },
        data()
            {
            return {
                comp_data: {},
                search_key: '',
                toShow: "products",
                producstsinfo: true,
                productrequestsinfo: false,
                get_comp_api_inprogress:false
            };
        },
        methods: {
            clearSearch(){
                this.search_key = ""
                this.searchProds();
            },
            searchProds() {
                EventBus.$emit('searchProds', this.search_key)
            },
            showTabs(type) {
                if(type == 'products') {
                    this.toShow = 'products';
                    this.producstsinfo = true;
                    this.productrequestsinfo = false;
                } else if(type == 'product_requests') {
                    this.toShow = 'product_requests';
                    this.producstsinfo = false;
                    this.productrequestsinfo = true;
                }
            },
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            goToParticularPage(type) {
                jQuery('.bigcontainer').animate({scrollTop:0},0);
                if(type == 'company_settings') {
                    this.$router.push('/company/settings/cmpid/' + this.$route.params.company_id);
                } else if(type == 'outlets') {
                    this.toShow = 'outlets';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {outlet_view: 'view'} })
                    this.outletsinfo = true;
                } else if (type == 'bankdetails') {
                    this.toShow = 'bankdetails';
                    this.$router.push({ path: "/company/view/cmpid/"+this.$route.params.company_id, query: {bankdetails_view: 'view'} })
                    this.bankdetailsinfo = true;
                } else if (type == 'company_info') {
                    this.toShow = 'company_info';
                    if(this.$route.params.company_id) {
                        this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
                    } else {
                        this.$router.push('/company/view/cmpid/' + this.loggedInUser.company_id);
                    }
                    this.companyinfo = true;
                } else if (type == 'business_unit') {
                    if(this.$route.params.company_id) {
                        this.$router.push('/businessunit/listview/cmpid/' + this.$route.params.company_id);
                    } else {
                        this.$router.push('/businessunit/listview/cmpid/' + this.loggedInUser.company_id);
                    }
                } else if (type == 'department_unit') {
                    if(this.$route.params.company_id) {
                        this.$router.push('/departmentunit/listview/cmpid/' + this.$route.params.company_id);
                    } else {
                        this.$router.push('/departmentunit/listview/cmpid/' + this.loggedInUser.company_id);
                    }
                } else if (type == 'employees') {
                    if(this.$route.params.company_id) {
                        this.$router.push('/employee/listemployee/cmpid/' + this.$route.params.company_id);
                    } else {
                        this.$router.push('/employee/listemployee/cmpid/' + this.loggedInUser.company_id);
                    }
                } else if (type == 'product_access') {
                    if(this.$route.params.company_id) {
                        this.$router.push('/product/view/cmpid/' + this.$route.params.company_id);
                    } else {
                        this.$router.push('/product/view/cmpid/' + this.loggedInUser.company_id);
                    }
                }
                else if (type == 'consultant'){
                    this.$router.push('/consultants/listview/cmpid/'+this.$route.params.company_id)
                }
            },
            async getCompData(id) {
                try {
                    this.get_comp_api_inprogress = true
                    let response = await this.getCompanyById(id);
                    if (response.status_id == 1) {
                        this.comp_data = response.response;
                    }
                    this.get_comp_api_inprogress = false
                }
                catch(err) {
                    // console.log(err)
                    this.get_comp_api_inprogress = false
                }
            },
        },
        mounted() {
            if(this.$route.params.company_id) {
                this.getCompData(this.$route.params.company_id);
            } else {
                this.getCompData(this.loggedInUser.company_id);
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            }
        },
    };
</script>

<style scoped>
    .cover-pic {
        height: 230px;
        background-size: cover;
    }

    .cover-pic img {
        width: 100%;
        border-radius: 15px;
        vertical-align: middle;
        border-style: none;
    }

    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }

    .btn-new-follow{
        color: #000 !important;
		background-color: #ffcb73;
		box-shadow: none !important;
		padding-left: 50px !important;
		padding-right: 50px !important;
		font-weight: 500;
		border-radius: 5px;
		border-color: 2px solid #ffcb73;
    }

    .btn-new-connected{
        color: #fff !important;
		background-color: #2cc36a;
		box-shadow: none !important;
		font-weight: 500;
		border-radius: 5px;
		border-color: 2px solid #2cc36a;
		padding-left: 50px !important;
		padding-right: 50px !important;
    }
    .activeTab{
        border-bottom: 2px solid #00448b !important;
    }
</style>