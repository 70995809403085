<template>
    <!-- User Profile Personal Basic Info Tab Content View From Account Profile -->
    <div>
        <div class="tabs-content-secondblock">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ml-2 mr-1 mb-3">
                        <!-- <div class="row" v-if="ajax_call_in_progress">
                            <div class="col-lg-12 py-5 px-3 mt-3">
                                <div class="flex-container">
                                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                </div>
                            </div>
                        </div> -->
                        <!-- <div v-else class="row"> -->
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="infoHeader px-3 pb-4 mt-6">
                                    <div class="create-company-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white">Headquarters Information</span>
                                        <span class="pointer" @click="expandTabs('headquarters_info')">
                                            <i class="text-white" :class="{'icon-chevron-double-down':headquarters_info_expand, 'icon-chevron-double-up':!headquarters_info_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="headquarters_info_expand">
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Headquarters Name</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_headquarters_name" >
                                                        <div class="pointer" @click="resetValue('headquarters_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('headquarters_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='headquarters_name' class="input-field-underline" @focus="editUser('headquarters_name')" style="padding-top: 6px !important;" :readonly="!edit_headquarters_name" maxlength="20" v-model="edit_user_data.headquarters_name">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Headquarters Code</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_headquarters_code" >
                                                        <div class="pointer" @click="resetValue('headquarters_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('headquarters_code')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='headquarters_code' maxlength="20" class="input-field-underline" @focus="editUser('headquarters_code')" style="padding-top: 6px !important;" :readonly="!edit_headquarters_code" v-model="edit_user_data.headquarters_code">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">
                                                    Date of Incorporation
                                                </div>
                                                <div class="w-100" >
                                                    <div style="position: relative; width: 230px;">
                                                        <div class="d-flex justify-content-end align-items-center new-action-btns" style="z-index: 2;right: -40px;bottom: 10px;" v-if="edit_date_of_incorportion" >
                                                            <div class="pointer" @click="resetValue('date_of_incorportion')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                            </div>
                                                            <div class="ml-2 pointer" @click="updateInfo('date_of_incorportion')">
                                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                            </div>
                                                        </div>
                                                        <el-tooltip class="item" effect="dark"
                                                                content="Click to edit"
                                                                placement="top">
                                                        <date-picker :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee'" class="profile-date-picker calendar-edit-per input-field-underline pt-0 pb-0 pr-0" @focus="editUser('date_of_incorportion')" v-model="edit_user_data.date_of_incorportion" value-type="format" lang="en" :popup-style="{ top: '100%', left: 0}" :append-to-body="false" 
                                                        :clearable="false"
                                                        :editable="false"
                                                        :not-after="new Date()" type="date" format="DD/MM/YYYY"></date-picker>
                                                        </el-tooltip>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Number of Employees</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_no_of_employees" >
                                                        <div class="pointer" @click="resetValue('no_of_employees')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('no_of_employees')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <div v-if="edit_no_of_employees" class="mr-12">
                                                        <multiselect class="diginew-multiselect diginew-multiselect-underline" name="no_of_employees" :searchable="true" :options="no_of_employees_options" v-model="edit_user_data.no_of_employees" placeholder="Select">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <el-tooltip v-else class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                        <div class="input-field-underline" @click="editUser('no_of_employees')">
                                                            {{edit_user_data.no_of_employees}}
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-company-header d-flex align-items-center justify-content-between mt-4">
                                        <span class="fs-14 text-white">Communication Information</span>
                                        <span class="pointer" @click="expandTabs('communication_info')">
                                            <i class="text-white" :class="{'icon-chevron-double-down':communication_info_expand, 'icon-chevron-double-up':!communication_info_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="communication_info_expand">
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Headquarters E-mail</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_headquarters_email" >
                                                        <div class="pointer" @click="resetValue('headquarters_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('headquarters_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='headquarters_email' v-validate="'email'" class="input-field-underline" @focus="editUser('headquarters_email')" style="padding-top: 6px !important;" :readonly="!edit_headquarters_email" maxlength="20" v-model="edit_user_data.headquarters_email">
                                                    </el-tooltip>
                                                    <div class="d-flex justify-content-between align-items-center" >
                                                        <span class="validation" v-show="errors.has('headquarters_email')">
                                                            Enter valid emaill address
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Headquarters Number</div>
                                                <div v-if="edit_headquarters_number" style="width: 128px !important;">
                                                    <multiselect v-model="edit_user_data.headquarters_number_type"
                                                        class="diginew-multiselect diginew-multiselect-underline pointer" style="width: 128px !important;" id="ajax"
                                                        :options="['Home','Work','Mobile','Fax','Others','Whatsapp','Messenger','Telegram','Line']"
                                                        :multiple="false" :searchable="true" :internal-search="true"
                                                        :disabled="false"
                                                        :show-labels="false"
                                                        :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                        :max-height="200" :show-no-results="true" :hide-selected="false">
                                                        <template slot="option" slot-scope="{ option }">
                                                            <div class="d-flex flex-row">
                                                                <span class="ml-2">
                                                                    <span style="font-size: 12px">{{ option }}</span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_headquarters_number" >
                                                        <div class="pointer" @click="resetValue('headquarters_number')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('headquarters_number')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <div v-if="edit_headquarters_number" >
                                                        <vue-tel-input
                                                            name="headquarters_number"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                            placeholder="Enter your alternate Phone Number"
                                                            data-vv-as="Alternate Phone"
                                                            v-validate="{min:10}"
                                                            v-model="edit_user_data.headquarters_number">
                                                        </vue-tel-input>
                                                    </div>
                                                    <el-tooltip v-else class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                        <div class="input-field-underline" @click="editUser('headquarters_number')">
                                                            {{edit_user_data.headquarters_number}}
                                                        </div>
                                                    </el-tooltip>
                                                    <div class="d-flex justify-content-between align-items-center" >
                                                        <span class="validation" v-show="errors.has('headquarters_number')">
                                                            Enter valid phone number
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Alternate Phone Number</div>
                                                <div v-if="edit_alternate_phone" style="width: 128px !important;">
                                                    <multiselect v-model="edit_user_data.alternate_phone_number_type"
                                                        class="diginew-multiselect diginew-multiselect-underline pointer" style="width: 128px !important;" id="ajax"
                                                        :options="['Home','Work','Mobile','Fax','Others','Whatsapp','Messenger','Telegram','Line']"
                                                        :multiple="false" :searchable="true" :internal-search="true"
                                                        :disabled="false"
                                                        :show-labels="false"
                                                        :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                                        :max-height="200" :show-no-results="true" :hide-selected="false">
                                                        <template slot="option" slot-scope="{ option }">
                                                            <div class="d-flex flex-row">
                                                                <span class="ml-2">
                                                                    <span style="font-size: 12px">{{ option }}</span>
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </multiselect>
                                                </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_alternate_phone" >
                                                        <div class="pointer" @click="resetValue('alternate_phone')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('alternate_phone')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <div v-if="edit_alternate_phone" >
                                                        <vue-tel-input
                                                            name="headquarters_number"
                                                            :enabledCountryCode="false"
                                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                            class="tele-contact-border-radius input-field-underline w-100" ref="telInput"
                                                            placeholder="Enter your alternate Phone Number"
                                                            data-vv-as="Alternate Phone"
                                                            v-validate="{min:10}"
                                                            v-model="edit_user_data.alternate_phone">
                                                        </vue-tel-input>
                                                    </div>
                                                    <el-tooltip v-else class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                        <div class="input-field-underline" @click="editUser('alternate_phone')">
                                                            {{edit_user_data.alternate_phone}}
                                                        </div>
                                                    </el-tooltip>
                                                    <div class="d-flex justify-content-between align-items-center" >
                                                        <span class="validation" v-show="errors.has('alternate_phone')">
                                                            Enter valid phone number
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Website</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_website" >
                                                        <div class="pointer" @click="resetValue('website')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('website')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='website' v-validate="'url'" class="input-field-underline" @focus="editUser('website')" style="padding-top: 6px !important;" :readonly="!edit_website" maxlength="20" v-model="edit_user_data.website">
                                                    </el-tooltip>
                                                    <div class="d-flex justify-content-between align-items-center" >
                                                        <span class="validation" v-show="errors.has('website')">
                                                            Enter valid url
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="create-company-header d-flex align-items-center justify-content-between mt-4">
                                        <span class="fs-14 text-white">Headquarter Address</span>
                                        <span class="pointer" @click="expandTabs('headquarters_address')">
                                            <i class="text-white" :class="{'icon-chevron-double-down':headquarters_address_expand, 'icon-chevron-double-up':!headquarters_address_expand}" style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="headquarters_address_expand" class="mt-8">
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Location </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-between align-items-center mt-1 px-3">
                                                        <div class="gMap" style="position:relative;width:100%;">
                                                            <gmap-autocomplete
                                                                class="inputFieldNoBorder mt-0"
                                                                id="autocompleteInput"
                                                                name="get_location"
                                                                v-validate="'required'"
                                                                placeholder="Search Location"
                                                                :disabled="false"
                                                                @place_changed="setPlace($event)"
                                                                :value="address.location_vmodel"
                                                                v-model="address.location_vmodel"
                                                                style="padding: 8px 32px 8px 18px !important; height: 40px;"
                                                            ></gmap-autocomplete>
                                                            <img
                                                                src="/static/images/marker_pin_new.svg"
                                                                style="position: absolute;right: 12px;bottom: 10px;"
                                                            />
                                                        </div>
                                                        <div class="gMap ml-4" style="cursor: pointer;" @click="getLocation()">
                                                        <img src="/static/images/gps_new.svg" style="height: 40px;" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Building No.</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_building_no" >
                                                        <div class="pointer" @click="resetValue('building_no')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('building_no')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='building_no' maxlength="20" class="input-field-underline" @focus="editUser('building_no')" style="padding-top: 6px !important;" :readonly="!edit_building_no" v-model="address.building_no">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Street 1</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_street1" >
                                                        <div class="pointer" @click="resetValue('street1')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('street1')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='street1' maxlength="20" class="input-field-underline" @focus="editUser('street1')" style="padding-top: 6px !important;" :readonly="!edit_street1" v-model="address.street1">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Street 2</div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_street1" >
                                                        <div class="pointer" @click="resetValue('street1')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('street1')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='street1' maxlength="20" class="input-field-underline" @focus="editUser('street1')" style="padding-top: 6px !important;" :readonly="!edit_street1" v-model="address.street1">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">City </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" v-if="edit_city" >
                                                        <div class="pointer" @click="resetValue('city')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('city')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='city' maxlength="20" class="input-field-underline" @focus="editUser('city')" style="padding-top: 6px !important;" :readonly="!edit_city" v-model="address.city">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Town </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_town" >
                                                        <div class="pointer" @click="resetValue('town')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('town')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='town' maxlength="20" class="input-field-underline" @focus="editUser('town')" style="padding-top: 6px !important;" :readonly="!edit_town" v-model="address.town">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">State </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_state" >
                                                        <div class="pointer" @click="resetValue('state')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('state')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <div v-if="edit_state" style="margin-right: 50px !important;">
                                                        <multiselect
                                                            :max-height="200"
                                                            name="state"
                                                            class="diginew-multiselect diginew-multiselect-underline mt-1"
                                                            v-model="address.state"
                                                            @open="updateState(address.country)"
                                                            :options="states"
                                                            :disabled="false"
                                                            @close="stateClose(address.state)"
                                                            :searchable="true"
                                                            :close-on-select="true"
                                                            :show-labels="false"
                                                            openDirection="top"
                                                            :loading="isState"
                                                            data-vv-scope="userData"
                                                            :placeholder="address.country ? 'Select State' : 'Select Country to view States'"
                                                        >
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <el-tooltip v-else class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                        <div @click="editUser('state')" class="input-field-underline">
                                                            {{address.state}}
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Country </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_country" >
                                                        <div class="pointer" @click="resetValue('country')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('country')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <div v-if="edit_country" style="margin-right: 50px !important;">
                                                        <multiselect
                                                            :max-height="200"
                                                            name="country"
                                                            class="diginew-multiselect diginew-multiselect-underline mt-1"
                                                            v-model="address.country"
                                                            @input="address.state = '', address.city = '',address.street1 = '',address.town = '',address.zipcode = '',address.location_vmodel = ''"
                                                            :options="countries"
                                                            @close="countryClose(address.country)"
                                                            :disabled="false"
                                                            openDirection="top"
                                                            :searchable="true"
                                                            :close-on-select="true"
                                                            v-validate="'required'"
                                                            :show-labels="false"
                                                            data-vv-scope="Country"
                                                            placeholder="Select Country"
                                                        >
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <el-tooltip v-else class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                        <div @click="editUser('country')" class="input-field-underline">
                                                            {{address.country}}
                                                        </div>
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text-new">Zip Code </div>
                                                <div class="w-100" style="position: relative;">
                                                    <div class="d-flex justify-content-end align-items-center new-action-btns" style="bottom: 10px;" v-if="edit_zipcode" >
                                                        <div class="pointer" @click="resetValue('zipcode')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('zipcode')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top">
                                                    <input name='zipcode' maxlength="20" class="input-field-underline" @focus="editUser('zipcode')" style="padding-top: 6px !important;" :readonly="!edit_zipcode" v-model="address.zipcode">
                                                    </el-tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    // import { ValidationProvider } from 'vee-validate';
    // import {
    //     HalfCircleSpinner
    // } from "epic-spinners";
    import DatePicker from 'vue2-datepicker'
    import countries from "../json/countries.json";
    import Element from 'element-ui'
    import axios from 'axios';
    import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
import globals from '../globals';
    export default {
        props: [],
        components:{
            DatePicker,
            Element,
            // VeeValidate
            // ValidationProvider,
            // HalfCircleSpinner
        },
        data() {
            return {
                maxlengthcustomname:15,
                edit_user_data: {
                    headquarters_name: 'Charlie',
                    industry_type: 'Brain',
                    no_of_employees: '2300',
                    headquarters_code: 'Davison',
                    date_of_incorportion: '22/10/1990',
                    about: 'Creative new age designer here to redesign the world. Love to play with the touchpad. Suited up to create the next trending design.',
                    fiscal_year_name: 'FY 2020 - 2021',
                    job_description: 'Customer Relations Manager, ensures that representatives who handle customers are well-trained, takes customer complaints to management and resolves customer complaints.',
                    headquarters_email: 'johandeo@digicollect.com',
                    alternate_email: 'johandeo@gmail.com',
                    headquarters_number: '9876543210',
                    alternate_phone: '9876541230',
                    website: 'johandeo@facebook.com',
                    headquarters_number_type: 'Work',
                    alternate_phone_number_type: 'Work'
                },
                countries: countries,
                address: {
                    building_no: '',
                    street1: 'INR ( ₹ )',
                    city: 'Bangalore',
                    town: 'Bangalore',
                    state: 'Karnataka',
                    country: 'India',
                    zipcode: '560027',
                    location_vmodel: ""
                },
                isState: false,
                states: [],
                fiscal_start_date: '2021',
                fiscal_end_date: '2022',
                edit_headquarters_name: false,
                edit_industry_type: false,
                edit_headquarters_code: false,
                edit_date_of_incorportion: false,
                edit_building_no: false,
                edit_no_of_employees: false,
                edit_street1: false,
                edit_city: false,
                edit_town: false,
                edit_state: false,
                edit_country: false,
                edit_zipcode: false,
                edit_fiscal_year_name: false,
                edit_city: false,
                edit_headquarters_email: false,
                edit_headquarters_number: false,
                edit_alternate_phone: false,
                edit_website: false,
                // valueqr: "https://example.com",
                // size: 90,
                user_data: {},
                ajax_call_in_progress: false,
                industry_type_options: [],
                no_of_employees_options: [],
                hide_el_dropdown: false,
                years_to_set: [],
                noFiscalYear: [],
                showFiscalError: false,
                yearSelected: "",
                headquarters_info_expand: true,
                headquarters_address_expand: true,
                communication_info_expand: true,
            };
        },
        mixins: [],
        methods: {
            moment(date) {
                return moment(date);
            },
            expandTabs(type) {
                if(type == 'headquarters_info') {
                    this.headquarters_info_expand = !this.headquarters_info_expand;
                } else if(type == 'communication_info') {
                    this.communication_info_expand = !this.communication_info_expand;
                } else if(type == 'headquarters_address') {
                    this.headquarters_address_expand = !this.headquarters_address_expand;
                }
            },
            editUser(obj){
                if(this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') {
                    console.log(obj);
                    if(obj == 'headquarters_name'){
                        this.edit_headquarters_name = true;
                        this.edit_no_of_employees = false;
                        this.edit_headquarters_code = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'industry_type'){
                        this.edit_headquarters_name = false;
                        this.edit_industry_type = true;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'headquarters_code'){
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = true;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'no_of_employees'){
                        this.edit_headquarters_name = false;
                        this.edit_no_of_employees = true;
                        this.edit_headquarters_code = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'date_of_incorportion'){
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = true;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if (obj == 'building_no') {
                        this.edit_building_no = true;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if (obj == 'street1') {
                        this.edit_street1 = true;
                        this.edit_building_no = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'city') {
                        this.edit_city = true;
                        this.edit_street1 = false;
                        this.edit_building_no = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if (obj == 'fiscal_year_name') {
                        this.edit_fiscal_year_name = true;
                        this.edit_city = false;
                        this.edit_street1 = false;
                        this.edit_building_no = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'about'){
                        this.edit_headquarters_name = false;
                        this.edit_city = false;
                        this.edit_headquarters_code = false;
                        this.edit_building_no = false;
                        this.edit_no_of_employees = false;
                        this.edit_street1 = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_headquarters_email = false;
                        this.edit_about = true;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'headquarters_email'){
                        this.edit_headquarters_email = true;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_website = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'alternate_email'){
                        this.edit_alternate_email = true;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_website = false;
                        this.edit_about = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_number = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'headquarters_number'){
                        this.edit_headquarters_number = true;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_website = false;
                        this.edit_about = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'alternate_phone'){
                        this.edit_headquarters_number = false;
                        this.edit_alternate_phone = true;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_website = false;
                        this.edit_about = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'website'){
                        this.edit_website = true;
                        this.edit_headquarters_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_about = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'state'){
                        this.edit_website = false;
                        this.edit_headquarters_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_about = false;
                        this.edit_state = true;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'town'){
                        this.edit_website = false;
                        this.edit_headquarters_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_about = false;
                        this.edit_state = false;
                        this.edit_town = true;
                        this.edit_country = false;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'country'){
                        this.edit_website = false;
                        this.edit_headquarters_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_about = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = true;
                        this.edit_zipcode = false;
                    }
                    else if(obj == 'zipcode'){
                        this.edit_website = false;
                        this.edit_headquarters_number = false;
                        this.edit_alternate_phone = false;
                        this.edit_headquarters_email = false;
                        this.edit_headquarters_name = false;
                        this.edit_headquarters_code = false;
                        this.edit_no_of_employees = false;
                        this.edit_building_no = false;
                        this.edit_street1 = false;
                        this.edit_city = false;
                        this.edit_date_of_incorportion = false;
                        this.edit_about = false;
                        this.edit_state = false;
                        this.edit_town = false;
                        this.edit_country = false;
                        this.edit_zipcode = true;
                    }
                }
            },
            resetValue(obj){
                console.log(obj)
                if(obj == 'headquarters_name'){
                    this.edit_headquarters_name = false;
                    this.edit_user_data.headquarters_name = this.user_data.headquarters_name
                }
                else if(obj == 'headquarters_code'){
                    this.edit_headquarters_code = false;
                    this.edit_user_data.headquarters_code = this.user_data.headquarters_code
                }
                else if(obj == 'date_of_incorportion'){
                    this.edit_date_of_incorportion = false;
                    this.edit_user_data.date_of_incorportion = this.user_data.date_of_incorportion.split('-')[2]+'/'+this.user_data.date_of_incorportion.split('-')[1]+'/'+this.user_data.date_of_incorportion.split('-')[0];
                }
                else if (obj == 'no_of_employees') {
                    this.edit_no_of_employees = false;
                    this.edit_user_data.no_of_employees = this.user_data.no_of_employees.split('-')[2]+'/'+this.user_data.no_of_employees.split('-')[1]+'/'+this.user_data.no_of_employees.split('-')[0];
                }
                else if (obj == 'building_no') {
                    this.edit_building_no = false;
                    this.edit_user_data.building_no = this.user_data.building_no.split('-')[2]+'/'+this.user_data.building_no.split('-')[1]+'/'+this.user_data.building_no.split('-')[0];
                }
                else if(obj == 'street1') {
                    this.edit_street1 = false;
                    this.edit_user_data.street1 = this.user_data.street1.split('-')[2]+'/'+this.user_data.street1.split('-')[1]+'/'+this.user_data.street1.split('-')[0];
                }
                else if (obj == 'city') {
                    this.edit_city = false;
                }
                else if(obj == 'headquarters_email'){
                    this.edit_headquarters_email = false;
                }
                else if(obj == 'headquarters_number'){
                    this.edit_headquarters_number = false;
                }
                else if(obj == 'alternate_phone'){
                    this.edit_alternate_phone = false;
                }
                else if(obj == 'website'){
                    this.edit_website = false;
                }
                else if(obj == 'state'){
                    this.edit_state = false;
                }
                else if(obj == 'town'){
                    this.edit_town = false;
                }
                else if(obj == 'country'){
                    this.edit_country = false;
                }
                else if(obj == 'zipcode'){
                    this.edit_zipcode = false;
                }
            },
            async personalInfoData() {
                // this.ajax_call_in_progress = true;
                // try {
                //     let param = this.$route.params.id ? this.$route.params.id : this.loggedInUser.user_id;
                //     let response = await this.getPersonalInfo(param);
                //     if (response.status_id == 1) {
                //         this.edit_headquarters_name = false;
                //         this.edit_headquarters_code = false;
                //         this.edit_gender = false;
                //         this.edit_date_of_incorportion = false;
                //         this.edit_about = false;
                //         this.edit_city = false;
                //         this.custom_gender_active = false;
                //         this.user_data = response.response;
                //         this.edit_user_data.headquarters_name = this.user_data.headquarters_name;
                //         this.edit_user_data.industry_type = this.user_data.industry_type;
                //         this.edit_user_data.headquarters_code = this.user_data.headquarters_code;
                //         this.edit_user_data.date_of_incorportion = this.user_data.date_of_incorportion.split('-')[2]+'/'+this.user_data.date_of_incorportion.split('-')[1]+'/'+this.user_data.date_of_incorportion.split('-')[0];
                //         this.edit_user_data.about = this.user_data.about;
                //         this.edit_user_data.job_description = this.user_data.job_description;
                //         this.edit_user_data.gender = this.user_data.gender;
                //         this.edit_user_data.custom_gender = this.user_data.custom_gender;
                //         this.edit_user_data.user_access = this.user_data.user_access;
                //         this.edit_user_data.city = this.user_data.tax_details;
                //         if(this.edit_user_data.gender == 'custom' || this.edit_user_data.gender == 'Custom') {
                //             // this.custom_gender_active = true;
                //             this.edit_user_data.gender == 'Custom'
                //         }
                //         this.ajax_call_in_progress = false;
                //     }
                // }
                // catch(err) {
                //     // console.log(err);
                // }
            },
            async updateInfo(type, index) {
                // try {
                //     let params = {
                //     };
                //     if(type == 'headquarters_name') {
                //         params.headquarters_name = this.edit_user_data.headquarters_name;
                //     }
                //     else if(type == 'industry_type'){
                //         params.industry_type = this.edit_user_data.industry_type;
                //     }
                //     else if(type == 'headquarters_code'){
                //         params.headquarters_code = this.edit_user_data.headquarters_code;
                //     }
                //     else if(type == 'gender'){
                //         if(this.edit_user_data.custom_gender.length > 0) {
                //             params.gender = 'custom';
                //             params.custom_gender = this.edit_user_data.custom_gender;
                //         } else {
                //             params.gender = this.edit_user_data.gender;
                //             params.custom_gender = '';
                //         }
                //     }
                //     else if(type == 'date_of_incorportion'){
                //         params.date_of_incorportion = this.edit_user_data.date_of_incorportion.split('/')[2]+'-'+this.edit_user_data.date_of_incorportion.split('/')[1]+'-'+this.edit_user_data.date_of_incorportion.split('/')[0];
                //     }
                //     else if(type == 'about'){
                //         params.about = this.edit_user_data.about;
                //     }
                //     else if(type == 'job_description'){
                //         params.job_description = this.edit_user_data.job_description;
                //     }
                //     let response = await this.updatePersonalInfo(params, this.loggedInUser.user_id);
                //     if(response.status_id == 1) {
                //         this.personalInfoData();
                //         EventBus.$emit('personal_info_data_update')
                //     }
                // }
                // catch(err) {
                //     // console.log(err);
                // }
            },
            countryClose(data) {
                this.address.country = data;
                this.$forceUpdate();
            },
            stateClose(data) {
                this.address.state = data;
                this.$forceUpdate();
            },
            updateState(country, index) {
                this.isState = true;
                this.states = [];
                if (country) {
                    let params = {
                        country_required: false,
                        state_required: true,
                        country_name: country,
                    };
                    axios.post(globals.CRM_API +"/location/get_location", params).then(res => {
                    if (res.data.status_id == 1 && "all_states" in res.data.response) {
                        res.data.response.all_states.forEach(x => {
                        this.states.push(x);
                        });
                        this.isState = false
                    }
                    });
                }
            },
            setPlace(place) {
                let currentPlace = place;
                // console.log(place);
                let address = place.formatted_address.split(", ");
                this.address.street1 =
                    address[address.length - 3] || "";
                this.address.town =
                    address[address.length - 4] || "";
                this.address.city = currentPlace.vicinity;
                let statezip = address[address.length - 2]
                    ? address[address.length - 2].split(" ")
                    : ["", ""];
                if (statezip.length == 1) {
                    if (statezip[0].match(/^[0-9]+$/)) {
                    this.address.zipcode = statezip[0];
                    } else {
                    this.address.state = statezip[0];
                    }
                } else {
                    this.address.zipcode = statezip[0].match(/^[0-9]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[0-9]+$/)
                    ? statezip[1]
                    : "";
                    this.address.state = statezip[0].match(/^[a-zA-Z]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[a-zA-Z]+$/)
                    ? statezip[1]
                    : "";
                }
                if (address[address.length - 1].includes("-")) {
                    let countrySelected = address[address.length - 1].split(" - ");
                    countrySelected.forEach(x => {
                    if (this.countries.includes(x)) {
                        this.address.country = x;
                    } else {
                        this.address.country = "";
                    }
                    });
                } else {
                    this.address.country = address[address.length - 1];
                }
                // this.address[index].address.latitude = currentPlace.geometry.location.lat() || "";
                // this.address[index].address.longitude = currentPlace.geometry.location.lng() || "";
                this.address.location_vmodel = place.formatted_address;
                this.$forceUpdate();
            },
            getLocation() {
                if (navigator.geolocation) {
                    // timeout at 60000 milliseconds (60 seconds)
                    var options = {
                    enableHighAccuracy: false,
                    timeout: 30000, // milliseconds (30 seconds)
                    maximumAge: 600000
                    }; // milliseconds (10 minutes)
                    navigator.geolocation.getCurrentPosition(
                    this.showLocation,
                    this.errorHandler,
                    options
                    );
                } else {
                    alert("Sorry, browser does not support geolocation!");
                }
            },
            showLocation(position) {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                let latlongvalue = position.coords.latitude + "," + position.coords.longitude;
                let calllatlong = axios.create();
                delete calllatlong.defaults.headers.common['Authorization'];
                calllatlong.post("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + latlongvalue + "&key=AIzaSyCQSEwELtCrUrLSfsqU0wX7QAHbLVQzUrY")
                .then(response => {
                    let address = response.data.results[0].formatted_address.split(", ");
                    // console.log(address);
                    this.address.street1 =
                    address[address.length - 6] || "";
                    this.address.town =
                    address[address.length - 4] || "";
                    this.address.city = address[address.length - 3] || "";
                    let statezip = address[address.length - 2]
                    ? address[address.length - 2].split(" ")
                    : ["", ""];
                    this.address.zipcode = statezip[0].match(/^[0-9]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[0-9]+$/)
                    ? statezip[1]
                    : "";
                    this.address.state = statezip[0].match(/^[a-zA-Z]+$/)
                    ? statezip[0]
                    : statezip[1].match(/^[a-zA-Z]+$/)
                    ? statezip[1]
                    : "";
                    if (address[address.length - 1].includes("-")) {
                    let countrySelected = address[address.length - 1].split(" - ");
                    countrySelected.forEach(x => {
                        if (this.countries.includes(x)) {
                        this.address.country = x;
                        } else {
                        this.address.country = "";
                        }
                    });
                    } else {
                    this.address.country = address[address.length - 1];
                    }
                    this.address.location_vmodel = response.data.results[0].formatted_address;
                    this.$forceUpdate();
                })
                .catch(e => {
                    // this.errors.push(e)
                });
            },
            errorHandler(err) {
                if (err.code == 1) {
                    // alert("Error: Access is denied!");
                } else if (err.code == 2) {
                    // alert("Error: Position is unavailable!");
                }
            },

        },
        props: [],
        mounted() {
            // this.personalInfoData();
        },
        created() {
            for (let i = 0; i < 6; i++) {
            let year = new Date().getFullYear();
            this.years_to_set.push(year + i);
            };
            this.fiscalYear = this.years_to_set[0] + "-" + this.years_to_set[1];
        },
        watch: {
            // "edit_user_data.gender": function(newVal,oldVal) {
            //     if (newVal == 'Custom') {
            //         this.custom_gender_active = true;
            //     } else {
            //         this.custom_gender_active = false;
            //     }
            // },
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            // account() {
            //     return this.$store.getters.account;
            // },
            // selectedCompany() {
            //     return this.$store.getters.selectedCompany;
            // },
        },
    };
</script>
<style scoped>
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }
</style>