<template>
	<div class="background" style="height: auto !important;">
		<div class="mat-navbar">
			<nav id="toolbar" class="mt-0 d-flex w-100">
				<div class="row no-gutters align-items-center flex-nowrap w-100">
					<div class="col">
						<div class="row no-gutters align-items-center flex-nowrap">
							<div class="shortcuts-wrapper row no-gutters align-items-center px-0 px-sm-0"
								style="z-index: 1; padding-left: 0px !important;">
								<div class="shortcuts row no-gutters align-items-center">
									<a class="shortcut-button mx-1 app-title text-white menu-btn-hide" style="cursor: auto;"
										href="javascript:;">
										<img src="/static/images/admin_icon_white.svg" alt="DigiAdmin" style="width: 35px; height: 30px;">
									</a>
								</div>
							</div>
						</div>
					</div>
					<div class="center-logo navbar-header">
						<router-link class="navbar-branddigi" :to="'/'">
							<span class="dark-logo hidden-sm-down">
								DigiAdmin
							</span>
						</router-link>
					</div>

					<div class="col-auto">
						<div class="row no-gutters align-items-center justify-content-end">
							<div class="user-menu-button">
								<el-dropdown class="pl-3 pr-2" trigger="click">
									<span class="el-dropdown-link">
										<div class="d-flex align-items-center">
											<div class="avatar-wrapper">
												<img class="avatar"
													src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/default.png" />
											</div>
											<span class="username mx-3 d-none d-md-block text-white text-truncate"
												style="font-size: 14px;padding-top:2px;max-width:100px;"
												title="User">
												
											</span>
											<i class="icon icon-chevron-down text-white"></i>
										</div>
									</span>
									<el-dropdown-menu slot="dropdown">
										<div style="width: 400px;">
											<footer class="profilefooter position-relative pt-2" style="background-color: transparent !important;">
												<div class="fw-600 d-inline-block pointer searchTitle pull-right mr-3">
													<button class="btn btn-new-primary btn-logout" @click="logout()">
														Logout
													</button>
												</div>
											</footer>
										</div>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</div>
</template>
<script>
	import globals from "./globals"
	export default {
		mixins: [],
		data() {
			return {
			};
		},
		components: {
		},
		methods: {

			// Logout and Delete Token
			async logout() {
                this.$cookie.delete("Token");
                this.$cookie.delete('Token', {
                    domain: 'digicollect.com'
                });
                window.location.href = globals.ADMIN_URL;
			},
		},
		computed: {
			
		},
		created() {
			
		}
	};
</script>

<style>
    .background{
        background-color: #00448b;
        width: 100%;
        padding: 5px 15px;
        user-select: none;
    }
	.logut-dropdown-new {
		max-height: 250px;
		overflow: auto;
	}

	.show-message-time {
		white-space: pre-line;
		width: 67px;
		text-align: center;
		margin-top: -10px;
	}

	.nav-tabs.nav-topbar-tab .nav-link.active:not(.dropdown-toggle):after,
	.nav-tabs.nav-topbar-tab .nav-item.show .nav-link:not(.dropdown-toggle):after {
		content: '';
		position: absolute;
		width: 100%;
		left: 0;
		right: 0;
		bottom: 0;
		height: 2px;
		background-color: #00448b;
	}

	/* #toolbar {
  height: 58px !important;
  min-height: 58px !important;
} */
	#toolbar {
		height: 58px !important;
		min-height: 58px !important;
		margin-top: 10.5px;
	}

	#toolbar {
		-webkit-box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
			0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0),
			0px 1px 1px 0px rgba(0, 0, 0, 0), 0px 1px 3px 0px rgba(0, 0, 0, 0);
	}

	#toolbar .toggle-aside-button,
	#toolbar .search-button,
	#toolbar .quick-panel-button {
		height: 50px !important;
		width: 56px !important;
		padding-right: 0px !important;
	}

	#toolbar .user-menu-button>#dropdownUserMenu {
		height: -webkit-fill-available !important;
		padding-right: 24px !important;
	}

	.app-title {
		padding-left: 0px !important;
		margin-left: 0px !important;
	}

	i.material-icons.md-36 {
		font-size: 36px !important;
		vertical-align: -36px;
		line-height: 36px;
		height: 36px;
		width: 36px;
		padding-bottom: 15px;
		color:#00448b !important;
	}

	.app-title.menu-btn-hide {
		padding-left: 15px !important;
	}

	.bg-secondary-light {
		background-image: linear-gradient(to right,
				#0d84f2,
				#0d84f2 51%,
				#69b3fc,
				#9fcbff) !important;
	}

	.center-logo.navbar-header {
		position: absolute;
		left: 0;
		right: 0;
		margin: 0 auto;
		line-height: 40px;
		text-align: center !important;
		margin-top: 1px;
		height: 58px;
		margin-left: 10px;
		margin-right: 10px;
	}

	.center-logo.navbar-header .navbar-branddigi {
		margin-right: 0px;
		padding-bottom: 0px;
		padding-top: 0px;
		color: rgba(255, 255, 255, 1);
		text-decoration: none !important;
	}

	@media screen and (min-width: 400px) {
		.center-logo.navbar-header .navbar-branddigi span.navlogo {
			padding: 0.8rem 0.5rem 0rem 0.3rem !important;
		}
	}

	@media screen and (min-width: 648px) {
		.center-logo.navbar-header .navbar-branddigi span.navlogo {
			padding: 0.8rem 0.5rem 0rem 0.3rem !important;
		}
	}

	@media screen and (max-width: 1025px) {
		.center-logo.navbar-header .navbar-branddigi span.navlogo {
			padding: 0.8rem 0.5rem 0rem 0.3rem;
		}
	}

	@media (max-width: 1060px) {
		.language-button {
			display: none !important;
		}
	}

	.center-logo.navbar-header .navbar-branddigi span.navlogo {
		font-weight: 600;
		padding: 0rem 0.5rem 0rem 0.3rem;
		text-transform: none;
		border-color: transparent;
		display: inline-block;
		text-align: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		box-sizing: border-box;
		-webkit-appearance: none;
		outline: 0;
		color: rgba(255, 255, 255, 1);
		font-size: 18px;
	}

	.center-logo.navbar-header .navbar-branddigi span.dark-logo {
		font-weight: 600;
		padding: 0.8rem 0.4rem 0.8rem 0rem;
		text-transform: none;
		border-color: transparent;
		display: inline-block;
		text-align: center;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		box-sizing: border-box;
		-webkit-appearance: none;
		outline: 0;
		color: #fff !important;
		font-size: 18px;
	}

	@media (min-width: 768px) {
		.navbar-header .navbar-branddigi {
			padding-top: 0px;
		}
	}

	.navbar-header .navbar-branddigi i {
		display: inline-block;
		color: rgba(255, 255, 255, 1);
		font-size: 20px;
	}

	.timezone-button .dropdown-toggle::after {
		color: #fff;
	}

	.flag {
		height: 32px;
		width: 32px;
		line-height: 20px;
		margin-right: 10px;
	}

	#contenttime {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		transition: 0.5s;
		text-align: center;
	}

	#contenttime .wrappertime {
		margin-right: 10px;
	}

	#contenttime span {
		transition: 0.2s;
		font-size: 16px;
		margin: 0;
		font-weight: 600;
	}

	#contenttime p {
		transition: 0.2s;
	}

	.faded {
		opacity: 0;
		transition: 0.2s;
	}

	.timezone-button {
		line-height: 100%;
		margin-left: 8px;
	}

	.clock {
		font-size: 24px;
		padding-right: 20px;
		color: #fff;
	}

	.uk-margin-top {
		height: 80px;
	}

	.dropdown-toggle.products-button::after {
		content: none !important;
	}

	@media (min-width: 576px) {
		.px-sm-32 {
			padding-left: 3.2rem !important;
		}
	}

	.app-title {
		font-size: 20px;
		font-weight: 600;
		/* padding: 0rem 3.4rem 0.8rem 0.3rem; */
		text-transform: none;
		min-width: initial;
		height: auto;
		line-height: normal;
		cursor: pointer;
		border-radius: 100% !important;
		background-color: transparent;
		border-color: transparent;
		text-decoration: none !important;
		-webkit-box-shadow: none;
		box-shadow: none;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		border: 0px solid transparent;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-appearance: none;
		letter-spacing: 0.04em;
		-webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
			0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
		box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0),
			0px 2px 2px 0px rgba(0, 0, 0, 0), 0px 1px 5px 0px rgba(0, 0, 0, 0);
		-webkit-transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		transition: -webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		-o-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
			-webkit-box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
		will-change: box-shadow;
		position: relative;
		outline: none;
		color: #fff !important;
	}

	div.content-app {
		padding-left: 15px;
		padding-right: 15px;
	}

	.base-box.boxed,
	.base-box.boxed-inverse,
	.boxed-inverse,
	[class*="col-md-"].boxed,
	[class*="col-md-"].boxed-inverse,
	.grid-box>.grid-item.boxed-inverse,
	.row.vertical-row>div.boxed-inverse,
	.row.vertical-row>div.boxed {
		border-style: none !important;
	}

	.shadow-1,
	.adv-img-down-text.shadow-1 .img-box,
	.two-blocks-container.shadow-1>.row,
	.section-bg-color .advs-box.boxed-inverse,
	.section-bg-color .adv-img.boxed-inverse {
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.13) !important;
	}

	.black {
		color: #212121 !important;
	}

	.col-center {
		float: none !important;
		margin-left: auto !important;
		margin-right: auto !important;
		clear: both;
		padding-top: 20px;
	}

	.section-bg-image .advs-box.boxed-inverse,
	.black .flex-control-paging li a:hover,
	.adv-img-down-text.boxed-inverse:hover,
	.advs-box-multiple div.circle,
	.list-blog input,
	.list-blog select,
	.section-bg-image [class*="col-md-"].boxed-inverse,
	[class*="shadow-"].boxed-inverse,
	.sidebar-nav ul ul,
	.base-box.boxed,
	.base-box.boxed-inverse,
	.advs-box.boxed-inverse,
	.section-bg-color .form-box .form-control,
	.boxed-inverse:not(.shadow-1) .form-box,
	.boxed-inverse:not(.shadow-1) .form-box .form-control,
	.section-two-blocks .form-box .form-control,
	.section-bg-color .advs-box.boxed-inverse {
		background-color: #fff;
	}

	hr.space {
		border: none !important;
		clear: both;
		height: 70px;
		margin: 0;
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
	}

	.text-l {
		line-height: 38px;
	}

	.text-l {
		font-size: 27px !important;
		line-height: 31px;
	}

	.grid-list {
		position: relative;
	}

	.grid-box.row {
		margin: -20px;
		width: calc(100% + 40px);
	}

	.grid-box>.grid-item {
		padding: 20px;
	}

	.app-box.app-box-top-bottom {
		display: block;
	}

	.grid-item>div {
		padding-left: 0;
		padding-right: 0;
	}

	.app-box-top-bottom .app-box-cell {
		display: block;
		text-align: center;
	}

	.app-box-cell {
		vertical-align: middle;
	}

	.app-box.app-box-top-bottom .app-box-cell>img {
		margin: 0px 0 15px 0;
	}

	.app-box.app-box-top-bottom .app-box-cell>img {
		float: none;
		margin: 7px 0 7px 0;
		display: inline-block;
	}

	.app-box-cell img.text-xl {
		width: 55px;
	}

	.app-box.app-box-top-bottom .app-box-cell>i {
		float: none;
		margin: 7px 0 7px 0;
		display: inline-block;
	}

	.app-box.app-box-top-bottom .app-box-cell>i {
		margin: 0px 0 15px 0;
		color: #5584ff;
	}

	.app-box-cell i.text-xl {
		width: 55px;
		font-size: 55px;
		height: 55px;
		line-height: 55px;
	}

	.app-box-top-bottom .app-box-cell label {
		margin-bottom: 10px;
	}

	.app-box-top-bottom .app-box-cell label {
		padding: 0;
	}

	.app-box label {
		font-weight: 600;
	}

	.app-box-top-bottom .app-box-cell p {
		padding: 0;
		margin-bottom: 0;
		margin: 0;
	}

	.grid-item p {
		height: auto;
	}

	.text-s {
		line-height: 20px;
		font-size: 13px !important;
	}

	body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper,
	body.fuse-aside-folded:not(.fuse-aside-expanded) #aside .aside-content-wrapper>.aside-content {
		width: 0rem !important;
		min-width: 0rem !important;
	}

	body.fuse-aside-folded:not(.fuse-aside-expanded) #aside {
		width: 0rem;
	}

	.layout.layout-vertical.fuse-aside-folded.layout-left-navigation .wrapper {
		padding-left: 0rem;
	}

	/* .side-menu {
		  width: 281px;
		  position: fixed;
		  top: 0;
		  left: 0;
		  background: #fff;
		  height: 100%;
		  border-right: solid 1px #c5d6de;
		  overflow: auto;
		  z-index: 60;
		  padding: 90px 0 110px;
		  z-index: 1000;
		  -webkit-transition-property: width, min-width, max-width, -webkit-transform;
		  transition-property: width, min-width, max-width, -webkit-transform;
		  transition-property: transform, width, min-width, max-width;
		  transition-property: transform, width, min-width, max-width, -webkit-transform;
		  -webkit-transition-duration: 150ms;
		  transition-duration: 150ms;
		  -webkit-transition-timing-function: ease-in-out;
		  transition-timing-function: ease-in-out;
		  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
		  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.35);
		} */
	/* body.sidebar-hidden .side-menu {
		  display: none;
		} */
	.layout>.aside>.aside-content-wrapper>.aside-content {
		width: 280px;
	}

	.layout>.aside>.aside-content-wrapper {
		width: 280px;
		min-width: 280px;
	}

	.nav-link {
		text-decoration: none;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 48px;
		padding: 0 24px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		font-size: 14px;
	}

	.layout>.aside>.aside-content-wrapper>.aside-content>#sidenav .nav-link {
		color: #212121;
	}

	.nav-link i,
	.nav-link [class^="icon-"],
	.nav-link [class*=" icon-"] {
		font-size: 24px !important;
		margin-right: 1.9rem;
	}

	.dropdown-toggle.dropdownhideafter::after {
		content: none !important;
	}

	.dropdown-toggle.text-white::after {
		color: #fff !important;
	}

	.dropdown-apps {
		width: 320px;
		min-height: 100px;
		max-height: 400px;
		overflow-y: auto;
		top: 16px !important;
		left: -15px !important;
	}

	.dropdown-menu {
		-webkit-border-radius: 8px !important;
		border-radius: 8px !important;
		border: 1px solid #cdd2da !important;
		-webkit-box-shadow: 3px 2px 20px rgba(0, 0, 0, .2) !important;
		box-shadow: 3px 2px 20px rgba(0, 0, 0, .2) !important;
	}

	.dropdown-time {
		top: 11px !important;
	}

	.dropdown-time.show {
		left: -35px !important;
	}

	.dropdown-search {
		width: 420px;
		min-height: 100px;
		max-height: 390px;
		margin: 0.25rem 0 0 !important;
		overflow-y: auto;
		top: 10px !important;
		border-radius: 11px !important;
		padding: 0rem 0 !important;
		position: absolute;
		right: 1px;
		left: -9px !important;
		z-index: 99;
		cursor: pointer;
		box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
	}

	.dropdown-requests {
		width: 420px;
		min-height: 100px;
		max-height: 390px;
		margin: 0.25rem 0 0 !important;
		overflow-y: auto;
		top: 10px !important;
		border-radius: 11px !important;
		padding: 0rem 0 !important;
		position: absolute;
		right: 1px;
		left: -9px !important;
		z-index: 99;
		cursor: pointer;
		box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
	}

	.dropdown-profiles {
		width: 400px !important;
		min-height: 100px;
		max-height: 490px !important;
		margin: 0.25rem 0 0 !important;
		overflow-y: auto;
		top: 16px !important;
		border-radius: 11px !important;
		padding: 0rem 0 !important;
		position: absolute;
		right: 1px;
		left: -10px !important;
		z-index: 2;
		cursor: pointer;
		box-shadow: -5px 5px 7px 0 rgba(0, 0, 0, 0.16);
		background-color: #ffffff;
	}

	@media screen and (max-width: 429px) {
		.dropdown-profiles {
			width: 350px !important;
			margin: 0.25rem 13rem 0 !important;
		}
	}

	.uk-margin-bottom {
		margin-bottom: 16px !important;
	}

	.uk-grid:after,
	.uk-grid:before {
		content: "";
		display: block;
		overflow: hidden;
	}

	[class*="uk-grid-width"]>* {
		box-sizing: border-box;
		width: 100%;
	}

	.uk-grid-width-medium-1-3>* {
		width: 33.333%;
	}

	.uk-margin-top {
		margin-top: 15px !important;
	}

	.uk-grid.topgrid>* {
		padding-left: 20px;
	}

	@media (min-width: 1220px) {
		.uk-grid>* {
			padding-left: 0px;
		}
	}

	.uk-grid>* {
		-ms-flex: none;
		-webkit-flex: none;
		flex: none;
		margin: 0;
		float: left;
		text-align: center;
		text-decoration: none;
	}

	.uk-grid>*:hover {
		text-decoration: none;
	}

	.uk-grid.topgrid>* {
		padding-left: 20px;
	}

	i.dg-icons.md-36 {
		font-size: 36px !important;
		vertical-align: -36px;
		line-height: 36px;
		height: 36px;
		width: 36px;
		padding-bottom: 15px;
		color: #00448b !important;
	}

	.uk-grid>*> :last-child {
		margin-bottom: 0;
	}

	.uk-text-muted {
		color: #212121 !important;
	}

	.uk-display-block {
		display: block !important;
	}

	.logoblock .uk-display-block {
		margin-top: 8px;
		font-size: 13px;
	}

	.products-button {
		height: 3.4rem !important;
		line-height: 3.4rem !important;
		border-radius: 100px !important;
		border: 1px solid rgb(184, 223, 255) !important;
		padding: 0px 17.3px !important;
		text-transform: capitalize !important;
		/* width: 135px; */
	}

	.products-button div span {
		font-size: 14px;
		font-weight: 800;
	}

	.md-theme-default a:not(.md-button):hover {
		color: inherit;
	}

	.md-theme-default a:not(.md-button) {
		color: inherit;
	}

	/* @media only screen and (max-width: 897px) {
  .app-title.menu-btn-hide {
    padding-left: 0px !important;
  }
} */
	@media (min-width: 899px) {
		.hidden-md-upp {
			display: none !important;
		}
	}

	.uk-grid.topgrid>* {
		padding-left: 20px;
	}

	.searchContent {
		background-color: #0d5be6;
		color: white;
		border-radius: 50%;
	}

	.searchFilter {
		width: 7.3px;
		height: 10.7px;
	}

	.searchProducts {
		width: 375px;
		height: 34px;
		border-radius: 100px !important;
		border: solid 1px #d5d5d5 !important;
		padding-left: 35px;
		padding-right: 35px;
		background-image: linear-gradient(to bottom,
				#efefef,
				#ffffff 25%,
				#ffffff 48%,
				#ffffff 78%,
				#efefef);
	}

	.searchIcon {
		color: #cfcfcf !important;
		position: absolute;
		pointer-events: cursor;
		top: 22px;
		font-size: 19px;
		left: 15px;
		display: inline-block;
	}

	.closeIcon {
		color: #cdd2da !important;
		position: absolute;
		pointer-events: cursor;
		top: 22px;
		font-size: 19px;
		right: 10px;
		display: inline-block;
	}

	.searchTitle {
		top: 0px;
		position: relative;
	}

	.productSearch {
		overflow-y: auto;
		min-height: 100px;
		max-height: 300px;
		overflow-x: hidden;
	}

	.notifySearch {
		overflow-y: auto;
		min-height: 190px;
		overflow-x: hidden;
		max-height: 238px;
	}

	.profileSearch {
		overflow-y: auto;
		min-height: 100px;
		overflow-x: hidden;
		max-height: 240px;
	}

	.notifySearch .contact,
	.productSearch .contact {
		min-height: 60px;
	}

	.notifySearch .contact.unread {
		background-color: rgba(232, 232, 232, 0.76);
	}

	.notifySearch .contact:last-child {
		margin-bottom: 30px;
	}

	.productSearch .contact:last-child {
		margin-bottom: 40px;
	}

	/* .profileSearch.activatedsearch {
  overflow-y: auto;
  height: 210px;
  overflow-x: hidden;
} */
	.searchProductContent {
		height: 21px;
		width: 14px;
	}

	.avatar.avatar-grey {
		background-color: #cdd2da;
		font-size: 16px !important;
		font-weight: 600 !important;
		letter-spacing: 0.28px;
		color: #ffffff;
		width: 40px !important;
		min-width: 40px !important;
		height: 40px !important;
		line-height: 40px !important;
	}

	.input-search-rounded-box {
		position: relative;
	}

	.input-search-rounded-box .input-search-close,
	.input-search-rounded-box .input-search-icon {
		position: absolute;
		top: 50%;
		z-index: 1;
		width: 2.573rem;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.input-search-rounded-box .input-search-icon {
		left: 8px;
		font-size: 16px;
		color: #9e9e9e;
		text-align: center;
		pointer-events: none;
	}

	.input-search-rounded-box .input-search-icon+.form-control {
		padding-left: 38px !important;
	}

	.input-search-rounded-box .form-control {
		border-radius: 200px !important;
	}

	.input-search-close.icon {
		font-size: inherit !important;
		line-height: inherit !important;
	}

	button.input-search-close {
		-webkit-appearance: none !important;
		padding: 0 !important;
		cursor: pointer !important;
		background: 0 0 !important;
		border: 0 !important;
	}

	.input-search-rounded-box .input-search-close {
		right: 8px !important;
		color: #cdd2da !important;
		font-size: 19px !important;
		width: 18px !important;
		height: 18px !important;
		line-height: 18px !important;
	}

	.input-search-rounded-box .form-control {
		height: 3.573rem !important;
		-webkit-appearance: none !important;
		border-color: #e0e0e0 !important;
		-webkit-box-shadow: none !important;
		box-shadow: none !important;
		-webkit-transition: -webkit-box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear !important;
		-o-transition: box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear !important;
		transition: box-shadow .25s linear, border .25s linear, color .25s linear, background-color .25s linear !important;
		-moz-appearance: none !important;
	}

	.input-search-rounded-box .form-control {
		display: block !important;
		width: 100% !important;
		padding: .429rem 1.072rem !important;
		font-size: 14px !important;
		line-height: 1.571429 !important;
		color: #4e5764 !important;
		background-color: #fff !important;
		-webkit-background-clip: padding-box !important;
		background-clip: padding-box;
		border: solid 1px #d5d5d5 !important;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#efefef), color-stop(25%, #ffffff), color-stop(48%, #ffffff), color-stop(78%, #ffffff), to(#efefef)) !important;
		background-image: linear-gradient(to bottom, #efefef, #ffffff 25%, #ffffff 48%, #ffffff 78%, #efefef) !important;
	}

	.searchfooter {
		height: 40px;
		background-color: #f1f8fc;
		line-height: 40px;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0.51px;
		color: #fff;
		display: block;
		position: absolute;
		bottom: 0px;
		width: 100%;
	}

	.searchfooter:hover {
		color: #fff !important;
	}

	.notifyheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		min-height: 40px;
		height: 100%;
		position: relative;
		background: #f1f8fc;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.notifyheader>.descriptionheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 16px;
		border-bottom: 1px solid #cdd2da;
	}

	.notifyheader>.descriptionheader .description-text-header-left h5 {
		font-size: 14px;
		font-weight: 600;
		margin: 0;
		color: #808495;
	}

	.notifyheader>.descriptionheader .description-text-header-right {
		font-size: 13px;
		margin-left: auto;
		padding-left: 12px;
		color: #00448b;
		font-weight: 500;
	}

	.notifyheader>.descriptionheader .description-text-header-right {
		display: block;
	}

	.profileheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		min-height: 40px;
		position: relative;
		background: #FFFFFF;
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.profileheader>.descriptionheader {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 6px 10px;
		border-bottom: 1px solid transparent;
	}

	.profileheader>.descriptionheader .description-text-header-left h5 {
		font-size: 13px;
		font-weight: 600;
		margin: 0;
	}

	.profileheader>.descriptionheader .description-text-header-right {
		font-size: 13px;
		margin-left: auto;
		padding-left: 12px;
		color: #00448b;
		font-weight: 500;
	}

	.profileheader>.descriptionheader .description-text-header-right {
		display: block;
	}

	/* .profileheader>.descriptionheader .description-text-header-right span {
    width: 26px !important;
    height: 26px;
    line-height: 28px;
    background: #f5f6f9;
    border-radius: 50%;
    text-align: center;
    display: block;
	cursor: pointer;
} */
	.profileheader>.descriptionheader .description-text-header-right span {
		background: transparent;
		border-radius: none;
		text-align: center;
		display: block;
		cursor: pointer;
	}

	.profileheader>.descriptionheader .description-text-header-right span i {
		font-size: 17px;
		width: 17px !important;
		height: 17px;
		line-height: 17px;
		color: #00448b;
	}

	.dropdown-menu-header {
		position: relative;
		padding: 20px 20px;
		background-color: #fff;
		border-bottom: 1px solid #e0e0e0;
	}

	.dropdown-menu-header>h5 {
		margin: 0;
	}

	.dropdown-menu-header .badge {
		position: absolute;
		top: 50%;
		right: 20px;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.countnotification {
		position: absolute;
		bottom: 3.3rem;
		right: 0.4rem;
		color: #fff !important;
		background: #ff9a06;
		width: 16px;
		height: 16px;
		line-height: 16px;
		border-radius: 50%;
		font-size: 10px;
		font-weight: 600;
	}

	.list-group.profile-group .list-group-item.three-line {
		min-height: 6.8rem;
		border-bottom: 1px solid #cdd2da;
	}

	.list-group.profile-group .list-group-item.three-line:last-child {
		border-bottom: 1px solid transparent;
		margin-bottom: 25px;
	}

	.list-group.profile-group .list-group-item:hover,
	.list-group.profile-group .list-group-item:focus {
		z-index: 0;
		text-decoration: none;
	}

	.list-group.profile-group .list-group-item h3 {

		font-size: 14px !important;
		font-weight: 600 !important;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #303031 !important;
		margin: 0;
	}

	.list-group.profile-group .list-group-item h4 {
		font-size: 14px;
		font-weight: 500;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #4e5764 !important;
	}

	.list-group.profile-group .list-group-item p.compname {
		font-size: 13px;
		font-weight: 500;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #00448b !important;
	}

	.profile-selected.no-accounts {
		position: relative;
		margin-bottom: 44px;
	}

	.profile-selected .list-group .list-group-item h3 {
		font-size: 12px !important;
		font-weight: 600 !important;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #303031 !important;
		margin: 0;
	}

	.profile-selected .list-group .list-group-item p {
		font-size: 12px;
		font-weight: 500;
		line-height: 1.21;
		letter-spacing: 0.6px;
		color: #4e5764 !important;
		padding-top: 7px;
	}

	.btn-personal {
		height: 28px !important;
		padding: 0 12px !important;
		border-radius: none !important;
		font-size: 13px !important;
		line-height: 28px !important;
		text-transform: none !important;
		font-weight: 600 !important;
		text-decoration: none !important;
	}

	.btn-logout {
		height: 28px !important;
		padding: 0 18px !important;
		border-radius: 18px !important;
		font-size: 12px !important;
		line-height: 28px !important;
		text-transform: none !important;
		font-weight: 600 !important;
	}

	.profilefooter {
		height: 45px;
		box-shadow: none;
		background-color: #f1f8fc;
		line-height: 45px;
		font-size: 12px;
		font-weight: bold;
		letter-spacing: 0.51px;
		color: #4e5764;
		display: block;
		position: absolute;
		bottom: 0px;
		width: 100%;
	}

	.profilefooter:hover {
		color: #00448b;
	}

	.grey-border-box {
		border: dashed 1px #cdd2da;
		padding: 11px 20px;
		font-size: 13px;
		font-weight: 600;
		line-height: 1.92;
		letter-spacing: 0.56px;
		text-align: center;
		color: #9e9e9e;
		width: 265px;
		margin: 0 auto;
		border-radius: 12px;
	}

	.search-user-company-blocks {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		width: 100%;
	}

	.productSearch .contact .search-user-company-blocks .mailtext,
	.productSearch .contact .search-user-company-blocks .user-company-name {
		width: 190px;
	}

	.nav-tabs .nav-link.subnav-tabs {
		color: #303031;
		background-color: transparent;
		border-bottom: 1.5px solid #cdd2da;
	}

	.nav-tabs .nav-link.subnav-tabs.active {
		color: #00448b;
		background-color: transparent;
		border-bottom: 1.5px solid #00448b;
	}

	@media only screen and (max-width: 874px) {
		.dropdown-submenu .dropdown-menu {
			top: 6px !important;
			left: 34% !important;
		}
	}

	@media only screen and (max-width: 939px) {
		.dropdown-submenu .dropdown-menu {
			left: 95px !important;
		}
	}
</style>